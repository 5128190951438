import React from 'react';
import {
  withStyles, WithStyles, Theme, createStyles,  //Styles
  Button, Dialog, DialogActions, DialogTitle, 
  Box, Grid, Typography, Divider, List, ListItem, ListItemIcon, 
  ListItemText, DialogContent, DialogContentText, CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { withRouter, match } from 'react-router-dom';
import { HelpOutline, PinDrop, MarkunreadMailbox  } from '@material-ui/icons';
import { colors } from '../../Config/Global';


import { ICategoryMap } from '../../App';
import Images from './Images';
import { IAuction } from '../../Interfaces/Database/Auction';
import { IMaxBid } from '../../Interfaces/Database/MaxBid';
import BidDetail from '../BidDetails';

// The Store!
import {
  IRootStore,
  RootContext,
  withAppContext,
} from '../../Store/RootStore';
import { IPurchase } from '../../Interfaces/Database/Purchase';
import { IItem } from '../../Interfaces/Database/Item';
import SubmitBids from '../LiveAuction/SubmitBids';
import { bidsViewable } from '../../Helpers/bidsViewable';

const styles = (theme: Theme) => createStyles({
  grid: {
    padding: '20px',
  },
  infoBar: {
    width: '100%',
    //height: '60px',
    //backgroundColor: blue,
    borderRadius: '3px',
  },
  button: {
    float: 'right',
    border: '1px solid black',
    borderRadius: '2px',
    padding: '5px',
    margin: '10px',
    backgroundColor: colors.navBarSecondary5up,
  },
  divider: {
    marginBottom: '20px',
    marginTop: '20px',
    '.MuiDivider-root':{
      height: '2px',
    },
  },
  heading: {
    padding: '10px', 
    marginBottom: '15px', 
    borderRadius: '5px', 
    backgroundColor: colors.navBarSecondary6up,
  },
  typoHeading: {
    margin: 'auto',
  },
  typoSub: {
    fontWeight: 'bold',
    topMargim: '10px',
  },
  typoMain: {
    margin: '5px',
  },
  error: {
    color: colors.errorRed,
    textAlign: 'right'
  },
});

type serverErrorType = 'No Error' | 'Item Already Sold' | 'Verify Email' | 'Payment Method' | 'General Error';

// App's Interfaces
interface IProps extends WithStyles<typeof styles> {
  itemID:        string;
  maxBid:        IMaxBid | null,
  categories:    ICategoryMap,
  isAdmin:       boolean,
  auction:       IAuction,
  onClose:      () => void,
  onDelete:     () => void, //item: IItem
  //onPlaceBid:   (item: IItem, newBid: Partial<IBid>, history: History) => Promise<any>, 
  //onPlaceBuyNow:(item: IItem) => Promise<any>,
  onLogin:      () => void,
  onEdit:       () => void,
  onUpdateMax:  (maxbid: IMaxBid) => void,
  //onDeletedMax: (maxbid: IMaxBid) => void,
  context:      IRootStore,
  history:      History,
  location:     Location,
  match:        match,
}

interface IState {
  needConfirm:          boolean,
  isPlaceBid:           boolean,
  isPlaceBuyNow:        boolean,
  isViewEditItem:       boolean,
  isViewBiddingDetails: boolean,
  infoOpen:             boolean,
  buyNowLoading:        boolean,
  onServerError:        serverErrorType, // 0: No Error, 1: Item Already Sold, 2: Verify Email, 3: Payment Method, 4: General Server Error
  errorMessage:         string,
}

class ItemDetail extends React.Component<IProps, IState>{

  constructor(props: IProps) {
    super(props);

    this.state = {
      needConfirm:          false,
      isPlaceBid:           false,
      isPlaceBuyNow:        false,
      isViewEditItem:       false,
      isViewBiddingDetails: false,
      infoOpen:             false,
      buyNowLoading:        false,
      onServerError:        'No Error',
      errorMessage:         '',
    };

    // Bind Methods
    this.checkValidUser = this.checkValidUser.bind(this);
    this.handleBuyNow = this.handleBuyNow.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.submitBuyNow = this.submitBuyNow.bind(this);
  }

  private checkValidUser(): boolean {
    if(!this.props.context.user){
      this.props.onLogin();
      return false;
    }
    else{
      return true;
    }
  }

  private handleBuyNow(){
    const { user, actions, itemStore } = this.props.context;
    const { item } = itemStore[this.props.itemID];

    this.setState({ 
      isPlaceBuyNow: false,
      buyNowLoading: true, 
    }); 

    // Early Return
    if (!item || !user) return;

    if(item.isBuyNow){
      actions.addPurchase({
        userID: user._id,
        itemID: item._id,
        itemCost: item.buyNowPrice,
        auctionID: item.auctionID,
      } as Partial<IPurchase>)
        .then(() => this.setState({ buyNowLoading: false, onServerError: 'No Error' }) )
        .catch((err: any) => {
          this.setState({ buyNowLoading: false });
          console.log(err);
          // No Payment Method on File
          if ((err.message as string).includes('Payment Method')) {
            this.setState({ onServerError: 'Payment Method' });
          } 
          // Item Just Previously Sold!
          else if((err.message as string).includes('Item Already Sold')){
            this.setState({ onServerError: 'Item Already Sold' });
          }
          // Need to Verify Email
          else if((err.message as string).includes('verify email')){
            this.setState({ onServerError: 'Verify Email' });
          }
          else {
            this.setState({ onServerError: 'General Error' });
          }
        });
    } else {
      console.log('Item does not have the Buy It Now option');
    }
  }

  private handleDeleteItem(){
    const { auction, itemID, onClose, onDelete } = this.props;
    const { user, actions } = this.props.context;

    // Early Return
    if (!itemID || !user) return;

    onClose();
    // Delete Item
    actions.remItem(auction._id, itemID)
      .then(() => {
        onDelete();
      })
      .catch(err => console.log(err));
  }

  private submitBuyNow(item: IItem) {
    // Call Prop & Handle Error
    //const { onPlaceBuyNow } = this.props;
    const { actions, user } = this.props.context;


    this.setState({ errorMessage: '' }, () => {
      //onPlaceBuyNow(item)
      actions.addPurchase({
        auctionID: item.auctionID,
        itemID: item._id,
        itemCost: item.buyNowPrice,
        userID: user?._id,
        deliveryType: 0,
        paidPackageID: 'pending',
      })
        .catch(err => this.setState({ errorMessage: err.message }));
    });
  }


  private formatPickupInformation(){
    const components = this.props.auction.pickupInformation;
    if(components){
      return components.split('\n').map((str, index) => <p key={index} style={{ margin: 0 }}>{str}</p>);
    }else {
      return <p style={{ margin: 0 }}>6103 M 80, Kinross, MI 49752</p>;
    }
  }

  
  render() {
    const { context, itemID, classes, categories, isAdmin, auction, onClose, onEdit, onLogin } = this.props;
    const { needConfirm, infoOpen, isPlaceBuyNow, isViewBiddingDetails, buyNowLoading } = this.state;
    
    const { highestBid, item } = context.itemStore[itemID];
    
    return (
      <>
        {item && <>

          {isViewBiddingDetails &&
            <BidDetail
              itemID={item._id}
              isOpen={isViewBiddingDetails}
              onClose={() => this.setState({ isViewBiddingDetails: false })}
            />
          }

          {/* UserType: Admin, Future Auction - Confirm Item Deletion */}
          <Dialog open={needConfirm} >
            <DialogTitle id="alert-dialog-title">Please confirm to delete the item.</DialogTitle>
            <DialogActions>
              <Button onClick={() => this.setState({needConfirm: false})} color="primary">Cancel</Button>
              <Button variant='contained' onClick={() => { 
                this.setState({needConfirm: false}); 
                this.handleDeleteItem();
              }} color="primary">Confirm</Button>
            </DialogActions>
          </Dialog>


          {/* Confirm Buy It Now */}
          <Dialog open={isPlaceBuyNow} >
            {/* <DialogTitle id="alert-dialog-title"> Buy It Now: Item # {item.itemNumber}</DialogTitle> */}
            <DialogContentText style={{margin: 20}}>
              If payment information has already been provided for this auction, you will be charged ${item.buyNowPrice} upon auction closure.
              Otherwise, you will be directed to the payment input page. After input, you must navigate back to the item and re-select Buy It Now
              to complete the purchase.
            </DialogContentText>
            
            <DialogActions>
              <Button onClick={() => { this.setState({ isPlaceBuyNow: false }); this.submitBuyNow(item); }} color="primary">Confirm</Button>
              <Button onClick={() => this.setState({ isPlaceBuyNow: false })} color="primary">Cancel</Button>
            </DialogActions>
          </Dialog>

          {/* Help Menu */}
          <Dialog open={infoOpen} onClose={() => this.setState({infoOpen: false})}>
            <DialogTitle style={{textAlign: 'center'}}>
              <CloseIcon style={{float: 'right'}} onClick={() => this.setState({infoOpen: false})} />
              Bidding Guidelines
            </DialogTitle>
            <DialogContent>
    
              {/* Before Getting Started */}
              <Typography className={classes.typoSub}>Before Starting</Typography>
              <Typography className={classes.typoMain}>
                You must be a registered user of the Royale Treasures Auction website.
              </Typography>
              <Typography className={classes.typoMain}>
                Payment information is required prior to bidding on auction items. At the end of the auction, 
                all items that you have the current bid on will be added to your pending purchases. After auction 
                closure, you must resolve the delivery method (shipping or pickup) input. 
              </Typography>
              {/* Place Bid*/}
              <Typography className={classes.typoSub}>Place Bid</Typography>
              <Typography className={classes.typoMain}>
                All bids must be an increase of the previous bid by a multiple of ${item.bidIncrement}. 
              </Typography>
              <Typography className={classes.typoMain}>
                If two bids equal the same amount, the first bidder receives the highest bid. 
              </Typography>
              <Typography className={classes.typoMain}>
                Only the bid amount, your bidder number, and time of bid are publicly viewable. 
              </Typography>
              {/* Set Maximum Bid*/}
              <Typography className={classes.typoSub}>Set Maximum Bid</Typography>
              <Typography className={classes.typoMain}>
                Users have the option to set a maximum amount they are willing to pay for the item. If the user is 
                subsequently outbid, Royale Treasures Auction will continue to create new bids until their maximum 
                amount is reached. You will be notified by email for automatic bid placements and the occurrence of being outbid.  
              </Typography>
              <Typography className={classes.typoMain}>
                This amount may be increased after setting the initial maximum bid amount. 
              </Typography>
              {/* Buy It Now */}
              <Typography className={classes.typoSub}>But It Now</Typography>
              <Typography className={classes.typoMain}>
                If there is a Buy It Now option to the item, you can either purchase the item right away at the set price listed or place a 
                bid.
              </Typography>
              <Typography className={classes.typoMain} style={{marginBottom: 10}}>
                If the bid price exceeds the Buy Now price, the Buy Now option is removed. 
              </Typography>

            </DialogContent>
          </Dialog>

          {/* Bid History / Admin: Edit / Delete */}
          <Box className={classes.infoBar}>
            <CloseIcon style={{float: 'right', margin: '8px'}} onClick={() => onClose()} /> 
            {bidsViewable(auction) &&
              <Button className={classes.button} onClick={() => this.setState({ isViewBiddingDetails: true })}>Bid History</Button>
            }
            {isAdmin &&
              <>
                <Button className={classes.button} onClick={() => onEdit()}> Edit </Button> 
                <Button className={classes.button} onClick={() => this.setState({ needConfirm: true })}> Delete </Button> 
              </>
            }
          </Box>

          <Grid container>

            <Grid item xs={12} lg={6} className={classes.grid}>
              <Images 
                itemImages={item.pictures} 
                altName={item.title} 
                isUpdate={false}
                onChange={() => {}}
                sendImages={(images: string[]) => {}}
              />
            </Grid>

            <Grid item xs={12} lg={6} className={classes.grid}>

              <div className={classes.heading}>
                <Typography variant="h5" component="h3" style={{marginBottom: '5px'}}>
                  {item.title}
                </Typography>

                <Typography style={{paddingLeft: '20px'}} variant="subtitle1" color="textSecondary">
                  {item.itemNumber ? '#' + item.itemNumber : ''} &emsp; Category: &ensp; {(categories)[item.categoryID]}
                </Typography>

                {item.isSold &&
                  <Typography style={{paddingLeft: '20px', fontWeight: 'bold'}} variant="h6" component="h3" color="error">
                    SOLD
                  </Typography>
                }

              </div>

              {/*  Bid / Buy Now Container  */}
              <Grid container spacing={1}>
                {highestBid && bidsViewable(auction)
                  ? <>
                      <Grid item xs={3}>
                        <Typography style={{paddingBottom: 0}} variant="subtitle1"> Current Bid:</Typography>
                        <Typography style={{paddingTop: 0}} variant="subtitle1" color="textSecondary"> Bidder #{highestBid.number} </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{paddingLeft: '10px'}} variant="h6"> ${highestBid.bid.price}</Typography>
                        {this.props.context.user?.bidderNumber === highestBid.number &&
                          <Typography variant="subtitle1" style={{ color: '#0088cc' }}>Your Bid</Typography>
                        }
                      </Grid>                 
                    </>
                  : 
                    item.isSold 
                      ?
                        <>
                          <Grid item xs={3}>
                            <Typography style={{paddingBottom: 0}} variant="subtitle1">Price:</Typography>
                            <Typography style={{paddingTop: 0}} variant="subtitle1" color="textSecondary"> Bidder #{item.purchaseBidder} </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography style={{paddingLeft: '10px'}} variant="h6"> ${item.purchasePrice}</Typography>
                            {this.props.context.user?.bidderNumber === item.purchaseBidder &&
                              <Typography variant="subtitle1" style={{ color: '#0088cc' }}>Your Purchase</Typography>
                            }
                          </Grid>             
                        </>
                      :
                        <>
                          <Grid item xs={3}>
                            <Typography variant="subtitle1"> Starting Bid:</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography style={{paddingLeft: '10px'}} variant="h6"> ${item.startingBid}</Typography>
                          </Grid>
                        </>
                }

                <Grid item xs={3} >
                  {auction.state === 2 && 
                    <div onClick={() => this.setState({infoOpen: true})}>
                      <Typography display='inline' variant="subtitle1" color="textSecondary" > Help </Typography>
                      <ListItemIcon> <HelpOutline style={{minWidth: 0}}/> </ListItemIcon>
                    </div>
                  }
                </Grid>

                  
                {((auction.type !== 'liveAndOnline' && auction.state === 2 && !item.isSold) || auction.preBiddingOpen) &&
                  <SubmitBids item={item} auction={auction} onLogin={onLogin}/>
                }
                
                {/* Buy Now Option if Item is not sold AND highestBid Does not Exceed Buy Now Price */}
                {auction.type !== 'liveAndOnline' && item.isBuyNow && !item.isSold && ((highestBid && item.buyNowPrice && highestBid.bid.price < item.buyNowPrice) || !highestBid ) &&
                  <>
                    {/* ---------------------------------  Buy It Now  ------------------------------------ */}
                    <Grid item xs={3}>
                      <Typography variant="subtitle1"> {auction.state === 2 ? 'Price:' : 'Buy It Now:'}</Typography>
                    </Grid>

                    <Grid item xs={5}>
                      <Typography style={{paddingLeft: '10px'}} variant="h6"> ${item.buyNowPrice}</Typography>
                    </Grid>
              
                    <Divider className={classes.divider}/>

                    {/* Can only buy it now if the auction is open */}
                    {auction.state === 2 &&
                      <Grid item xs={4}>
                        <Button 
                          className={classes.button} 
                          style={{width: '90%', margin: 0}} 
                          disabled={item.isSold} 
                          onClick={() => { if(this.checkValidUser()) this.setState({isPlaceBuyNow: true});}}
                        >
                          {buyNowLoading 
                          ? <div style={{ width: '100%', textAlign: 'center'}}>
                              <CircularProgress size='1rem' style={{ margin: 'auto' }} />
                            </div>
                          : <Typography>Buy It Now</Typography>
                          }
                        </Button>
                      </Grid>
                    }
                  </>
                }
              </Grid>

              <Divider className={classes.divider}/>
              <Typography  variant="body2" component="p">Delivery Options:</Typography>

              <List style={{ marginLeft: '10px' }}>
                <ListItem>
                  <ListItemIcon><PinDrop/></ListItemIcon>
                  <ListItemText 
                    primary={item.shippingCost === -1 ? 'Pickup Only' : 'Pickup'} 
                    secondary={<div>{this.formatPickupInformation()}</div>}
                  />
                </ListItem>
                {item.shippingCost > -1 &&
                  <ListItem>
                    <ListItemIcon><MarkunreadMailbox fontSize='small'/></ListItemIcon>
                    <ListItemText primary='Shipping' secondary={
                      item.shippingCost === 0
                        ? 'Free'
                        : `$${item.shippingCost} ${item.shippingType}`}
                  />
                  </ListItem>
                }
              </List>

              <Divider className={classes.divider}/>

              <Typography style={{marginBottom: '10px'}} variant="body2" component="p">
                Description:
              </Typography>

              <Typography style={{ marginLeft: '20px' }} variant="body2" color="textSecondary" component="p">
                {item.description}
              </Typography>

            </Grid>
          </Grid>
        </>}

      </>
    );
  }
}

// Wrap Hooks with HOC
export default withRouter(withAppContext(
  RootContext, withStyles(styles)(ItemDetail)),
);