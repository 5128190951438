import React, { useContext } from 'react';
import { IAuction } from '../../Interfaces/Database/Auction';
import { colors } from '../../Config/Global';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import {
  Typography, makeStyles, Button, Divider, Grid,Dialog
} from '@material-ui/core';
import { ICategoryMap } from '../../App';
import { RootContext } from '../../Store/RootStore';
import { IItem } from '../../Interfaces/Database/Item';
import ItemDetail from '../Item/ItemDetail';
import BidsList from './BidsList';
import SubmitBids from './SubmitBids';

// Local Styles
const useStyles = makeStyles(theme => ({
  root: {
  },
  infoBar: {
    width: '100%',
  },
  main: {
    backgroundColor: colors.offWhite,
    minHeight: '500px',
    paddingTop: '30px',
  },
  text: {
    padding: 5,
    textAlign: 'center',
    color: colors.darkBlueFont,
  },
  charityText: {
    margin: 'auto',
    padding: '4px',
    textAlign: 'center',
    color: colors.darkBlueFont,
    backgroundColor: '#ffe699', 
    borderRadius: 5, 
  },
  tab: {
    border: '1px solid black',
    borderBottom: 0,
    borderRadius: '2px',
    margin: '0px 5px -2px',
    padding: '7px',
    paddingBottom: '7px',
  },
  viewing: {
    border: '2px solid black',
    borderBottom: 0,
    borderRadius: '2px',
    margin: '0px 5px -2px',
    padding: '7px',
    paddingBottom: '7px',
    backgroundColor: colors.offWhite,
  },
  hide: {
    display: 'none',
  },
  dialogPaper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      height: '80%',
    },
  },
  gridItem: {
    border: `10px solid ${colors.offWhite}`,
    borderRadius: '20px',
    padding: '10px',
    backgroundColor: 'white'
  }
}));

interface ICurrentItemMap {
  [itemID: string]: IItem
}

interface IProps {
  auction: IAuction,
  categories: ICategoryMap,
  onLogin: () => void
}

export default function LiveStream({ auction, categories, onLogin }: IProps) {
  const styles = useStyles();
  const store = useContext(RootContext);
  const [currentItemMap, setCurrentItemMap] = React.useState<ICurrentItemMap>({}); 
  const [viewItemDetails, setViewItemDetails] = React.useState<boolean>(false);

  const embedUrl = React.useMemo(() => {
    if(auction.liveStreamLink){
      if(auction.liveStreamLink.includes('facebook')){
        const startIndex = auction.liveStreamLink.indexOf('&href=') + 6;
        const url = auction.liveStreamLink.substring(startIndex, auction.liveStreamLink.indexOf('&', startIndex));
        return `https://www.facebook.com/v2.3/plugins/video.php?&href=${url}`;
      }
      const urlSearch = new URL(auction.liveStreamLink).search;
      const id = urlSearch.substring(3, urlSearch.length);
      return `https://www.youtube.com/embed/${id}`
    } else {
      return null;
    }
  }, [ auction ]);

  const displayInfo = React.useMemo(() => {
    if(auction.state > 2){
      return { color: 'green', header: 'Auction Closed', subtext: `Closed at _` };
    } else if(auction.currentLiveItem){
      return { color: 'red', header: 'Current Live Item', subtext: ''};
    } else if(auction.pausedUntil){
      return { color: 'black', header: 'Auction Paused', subtext: `Resuming at ${new Date(auction.pausedUntil).toLocaleString()}` };
    } else {
      return { color: 'yellow', header: 'Future Auction', subtext: `Opening at ${new Date(auction.startDate).toLocaleString()}` };
    }
  }, [ auction ]);

  React.useEffect(() => {
    if(store.socket){
      store.socket
      .on('items patched', (itemPatched: IItem) => {
        //TODO: update store?
        setCurrentItemMap({...currentItemMap, [itemPatched._id]: itemPatched});
      })
    }
    return () => {
      if(store.socket){
        store.socket.off('items patched');
      }
    }
  }, [ store.socket ])

  React.useEffect(() => {
    if(auction.currentLiveItem){
      store.actions.retrieveItem(auction.currentLiveItem)
        .then((item: IItem) => {
          setCurrentItemMap({...currentItemMap, [item._id]: item})
        });
    } else {
      setCurrentItemMap({});
    }
  }, [ auction ])

  return (
    <div>
      <Dialog
        maxWidth='xl'
        classes={{ paper: styles.dialogPaper}}
        open={viewItemDetails}
      >
        <ItemDetail
          itemID={auction.currentLiveItem}
          maxBid={null}
          categories={categories}
          isAdmin={false}
          auction={auction}
          onClose={() => setViewItemDetails(false) }
          onDelete={() => {}} 
          onUpdateMax={() => {}}
          onLogin={() => {}}
          onEdit={() => {}}
        />
      </Dialog>

      <div>
        <div className={styles.gridItem}>
          <RssFeedIcon fontSize='large' style={{ display: 'inline', color: displayInfo.color, verticalAlign: 'middle'}}/>
          <Typography display="inline" variant='h6' style={{ margin: '10px 10px', verticalAlign: 'middle' }}>{displayInfo.header}</Typography>
        </div>
        <Grid container xs={12}>
          <Grid item xs={12} md={embedUrl ? 6 : 12}>
            <Grid container>
              {currentItemMap[auction.currentLiveItem]  
                ? <>
                  <Grid item xs={12} md={6} className={styles.gridItem}>
                    <Typography variant='h6'><strong># {currentItemMap[auction.currentLiveItem].itemNumber}</strong></Typography>
                    <Typography><em>Category:</em> {currentItemMap[auction.currentLiveItem].category}</Typography>
                    {currentItemMap[auction.currentLiveItem].isSold &&
                      <Typography 
                        style={{ fontSize: '18px', margin: '10px 0', padding: '2px', border: '2px solid red', borderRadius: '5px', width: 'fit-content' }} 
                        color={'error'}
                      ><strong>SOLD:</strong>&nbsp;&nbsp;
                      Bidder: #{currentItemMap[auction.currentLiveItem].purchaseBidder}&nbsp;&nbsp;
                      Price: ${currentItemMap[auction.currentLiveItem].purchasePrice}
                      </Typography>
                    }
                    <Typography style={{ margin: '10px 0' }}><em>Title:</em>  {currentItemMap[auction.currentLiveItem].title}</Typography>
                    <Button variant='contained' style={{ backgroundColor: colors.headerMain }} onClick={() => setViewItemDetails(true)}>See details</Button>
                    <Divider style={{ margin: '10px 0' }}/>
                    <Grid container spacing={1}>
                      <SubmitBids item={currentItemMap[auction.currentLiveItem]} auction={auction} onLogin={onLogin}/>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ height: '70vh' }} className={styles.gridItem}>
                    <BidsList
                      currentItem={currentItemMap[auction.currentLiveItem]}
                      itemID={auction.currentLiveItem}
                    />
                  </Grid>
                </>
                : <Grid item xs={12} className={styles.gridItem}><Typography>{displayInfo.subtext}</Typography></Grid>
              }
            </Grid>
              
          </Grid>

          {embedUrl &&
            <Grid item xs={12} md={6} className={styles.gridItem}>
                <iframe
                  src={embedUrl}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  title="video"
                  allowFullScreen
                  style={{ width: '100%', height: '70vh' }}
                />
            </Grid>
          }
        </Grid>
      </div>
    </div>
  );
}