import React from 'react';
import clsx from 'clsx';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { 
  withStyles, makeStyles,
  Button,
  Box,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarRateIcon from '@material-ui/icons/StarRate';
import Typography from '@material-ui/core/Typography';

import { IAuction } from '../../Interfaces/Database/Auction';
import { colors } from '../../Config/Global';

const styles = makeStyles({
  home: {
    width: '100%',
    color: '#fff',
    margin: 'auto',
    backgroundColor: colors.navBarMain2up,
    '&:hover': {
      backgroundColor: colors.navBarMain3up,
    },
  },
  button: {
    color: '#fff',
    padding: '6px',
    textAlign: 'center',
    width: '100%',
    backgroundColor: colors.navBarMain, 
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.navBarMain1up,
    },
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  text: {
    color: '#fff',
    margin: 'auto',
  },
  viewing: {
    backgroundColor: colors.navBarSecondary,
    '&:hover': {
      backgroundColor: colors.navBarSecondary2up,
    },
  },
  a: {
    width: '100%',
    textDecoration: 'none',
    display: 'block',
    color: '#fff',
  },
  //TODO: icon is not disappearing on home tab
  noIcon: {
    width: 0, 
    height: 0,  
    // '.MuiIcon-root': {
    //   padding: 0, 
    //   margin: 0
    // },
    '$&.MuiIconButton-edgeEnd':{
      padding: 0, 
      margin: 0,
    },
  },
});

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    width: '100%',
    backgroundColor: colors.navBarMain2up,
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    '&:hover': {
      backgroundColor: colors.navBarMain3up,
    },
  },
  content: {
    width: '100%',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    display: 'block',
    padding: 0,
    backgroundColor: colors.navBarMain,
    '&:hover': {
      backgroundColor: colors.navBarMain1up,
    },
  },
}))(MuiAccordionDetails);

interface IProps{
  label: string,
  auctionData: IAuction[],
  adminView: boolean,
  type: string,
  onClick: () => void,
  // Callbacks
  onCreate?: () => void, 
}

export default function AccordionTabs({label, auctionData, adminView, type, onClick, onCreate }: IProps) {

  const classes = styles();
  const location = useLocation();
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const startDate = React.useMemo(() => 
    auctionData ? auctionData.map(auction => new Date(auction.startDate).toLocaleDateString()) : []
  , [ auctionData ]);


  const childSelected = (): boolean => {
    //Do not allow accordion to close if a child is the current path
    for(const el of auctionData){
      if(`/auctions/${el._id}` == location.pathname){
        return true;
      }
    }
    return false;
  };

  const noExpandPath = 
    (label === 'Home' && '/' === location.pathname) || 
    (label === 'FAQ' && '/faq' === location.pathname) ||
    (label === 'Charity' && '/charity' === location.pathname) ||
    (label === 'Users' && '/users' === location.pathname);

  return (
    <>
      <Accordion 
        square 
        expanded={childSelected() ? true : expanded} 
        onChange={() => setExpanded(!expanded)}>
        <AccordionSummary 
          style={ noExpandPath
            ? { padding: 0, backgroundColor: colors.navBarSecondary } 
            : {}}
          className={ clsx({ [classes.viewing]: noExpandPath} )}
          // No expand icon if a child is selected (cannot close if child is being viewed) or the accordion is the home tab (no tabs to view)
          expandIcon={ 
            childSelected() 
              ? <></> 
              : type === 'no-expand'
                ? <ExpandMoreIcon className={classes.noIcon} /> 
                : <ExpandMoreIcon style={{color: colors.navBarSecondary }} />}
            aria-controls="panel1d-content" id="panel1d-header">
          {type === 'no-expand'
            ? 
              <RouterLink 
                className={classes.a} 
                to={label === 'Home' 
                  ? '/' 
                  : label === 'FAQ' ? '/faq' : label === 'Charity' ? '/charity' : '/users'}
              >
                <Typography 
                  onClick={onClick}
                  style={{display: 'block', width: '100%', height: '100%', textAlign: 'center'}}
                >
                {label}</Typography>
              </RouterLink>

            : <Typography className={classes.text}>{label}</Typography>
          }
          
        </AccordionSummary>
        <AccordionDetails>
          {adminView && label === 'Future Auctions' && 
            <Box className={classes.button} onClick={onCreate}>
              <Typography variant='subtitle1'>Create Auction</Typography> 
            </Box>
          }
          {(auctionData.length === 0 && type === 'expand') &&
            <Box className={classes.button}>
              <Typography variant='subtitle1'>None Listed</Typography> 
            </Box> 
          }
          {auctionData.map((auction, index) => (
            <RouterLink key={index} className={classes.a} to={`/auctions/${auction._id}`}>
              <Box 
                onClick={onClick}
                className={clsx(
                  classes.button, 
                  {  [classes.viewing]: `/auctions/${auction._id}` == location.pathname  }, //&& classes.viewing,
                )}
              >
                {auction.charityName && <StarRateIcon style={{ marginLeft: '-30px', display: 'inline', color: '#FCBD00' }}/> }
                {auction.type === 'liveAndOnline' &&  <Typography display='inline' variant='subtitle1' color='error'>(LIVE) </Typography> }
                <Typography display='inline' variant='subtitle1'>{auction.name}</Typography>
              
                <Typography 
                  display='block'
                  variant='caption' 
                  style={{color: `/auctions/${auction._id}` == location.pathname ? '#000' : colors.navBarSecondary2up}}
                >
                  {startDate[index]}
                </Typography>
              </Box>
            </RouterLink>
          ))}

        </AccordionDetails>
      </Accordion>
    </>
  );
}

