import React, { useContext } from 'react';
import { colors } from '../../Config/Global';
import clsx from 'clsx';


import {
  Box, Container, Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import Customers from './Customers';
import Staff from './Staff';


// Local Styles
const useStyles = makeStyles({
  root: {
    marginTop: '40px'
  },
  infoBar: {
    width: '100%',
  },
  main: {
    backgroundColor: colors.offWhite,
    minHeight: '500px',
    paddingTop: '30px',
  },
  text: {
    padding: 5,
    textAlign: 'center',
    color: colors.darkBlueFont,
  },
  charityText: {
    margin: 'auto',
    padding: '4px',
    textAlign: 'center',
    color: colors.darkBlueFont,
    backgroundColor: '#ffe699', 
    borderRadius: 5, 
  },
  tab: {
    border: '1px solid black',
    borderBottom: 0,
    borderRadius: '2px',
    margin: '0px 5px -2px',
    padding: '7px',
    paddingBottom: '7px',
  },
  viewing: {
    border: '2px solid black',
    borderBottom: 0,
    borderRadius: '2px',
    margin: '0px 5px -2px',
    padding: '7px',
    paddingBottom: '7px',
    backgroundColor: colors.offWhite,
  },
  hide: {
    display: 'none',
  },
});


export default function UserTabs() {
  const styles = useStyles();
  const [viewing, setViewing] = React.useState<string>('customers'); // customers, staff

  return (
    <Container className={styles.root}>

      <Box className={styles.infoBar}>
        <Button className={clsx(styles.tab, {[styles.viewing]: viewing === 'customers'})}  onClick={() => setViewing('customers')}>Customers</Button>
        <Button className={clsx(styles.tab, {[styles.viewing]: viewing === 'staff'})} onClick={() => setViewing('staff')}>Staff</Button>
      </Box>

      <Box className={styles.main}>
        {viewing === 'customers' &&
          <Customers/>
        }

        {viewing === 'staff' &&
          <Staff/>
        }
      </Box>

    </Container>
  );
}