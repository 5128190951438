import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { IAuctionBasicInfo, IAuctionCharityInfo } from './CreateAuctionStepper';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, Link, Select, TextField, Typography } from '@material-ui/core';
import { colors } from '../../Config/Global';

import DateFnsUtils from '@date-io/moment';
import 'date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { NumberFormatCustom } from '../../Helpers/NumberFormatCustom';
import { addAsterickOnError } from '../../Helpers/Accessibility';
import { AuctionTypes } from '../../Interfaces/Database/Auction';
import { InfoRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: '20px',
    },
    gridElement:{
      margin: 'auto',
      marginBottom: '20px',
      width: '95%',
    },
    imageInput: {
      margin: 'auto',
      marginBottom: '20px',
      width: '95%',
      border: '1px solid #bfbfbf',
      borderRadius: '5px',
    },
    textInput: {
      width: '100%',
    },
    imageContainer:{
      width: '90%',
      border: '1px black solid',
      margin: 'auto',
      backgroundColor: colors.navBarSecondary6up,
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  }),
);

interface IProps {
  auctionOpen: boolean,
  auctionType: AuctionTypes,
  basicInfo: IAuctionBasicInfo,
  verifyInput: boolean,
  readOnly: boolean,
  onVerify: () => void,
  onUpdate: (info: IAuctionBasicInfo) => void,
  onError: (error: string) => void
}

export default function BasicInfoInput({ auctionOpen, auctionType, basicInfo, verifyInput, readOnly, onVerify, onError, onUpdate }: IProps) {
  const classes = useStyles();

  const nameRef = React.createRef<HTMLInputElement>();
  const pickupInfoRef = React.createRef<HTMLInputElement>();
  const locationRef = React.createRef<HTMLInputElement>();
  const liveStreamRef = React.createRef<HTMLInputElement>();
  const additionalInfoRef = React.createRef<HTMLInputElement>();

  const start = new Date();
  const end = new Date();

  const [startDate, setStartDate] = React.useState<Date>(basicInfo.startDate ? new Date(basicInfo.startDate) : new Date(start.setDate(start.getDate() + 7)));
  const [endDate, setEndDate] = React.useState<Date>(basicInfo.endDate ? new Date(basicInfo.endDate) :  new Date(end.setDate(end.getDate() + 14)));

  const [nameError, setNameError] = React.useState<boolean>(false);
  const [startDateError, setStartDateError] = React.useState<boolean>(false);
  const [endDateError, setEndDateError] = React.useState<boolean>(false);
  const [pickupInfoError, setPickupInfoError] = React.useState<boolean>(false);
  const [locationError, setLocationError] = React.useState<boolean>(false);
  const [liveStreamInfoDialog, setLiveStreamInfoDialog] = React.useState<boolean>(false);
  

  React.useEffect(() => {
    if(verifyInput){
      if(!readOnly){
        const { value: name } = nameRef.current as HTMLInputElement;
        const { value: pickupInfo } = pickupInfoRef.current as HTMLInputElement;
        const location = locationRef.current ? locationRef.current.value : undefined;
        const additionalInformation = additionalInfoRef.current ? additionalInfoRef.current.value : undefined;
        const liveStreamLink = liveStreamRef.current ? liveStreamRef.current.value : undefined;
  
        let missingFields = false;
  
        setNameError(false);
        setPickupInfoError(false);
        setLocationError(false);
        setEndDateError(false);
        setStartDateError(false);
  
        if (!name) {
          setNameError(true);
          missingFields = true;
        }
  
        if (!pickupInfo) {
          setPickupInfoError(true);
          missingFields = true;
        }
  
        if(auctionType === 'liveAndOnline' && !location) {
          setLocationError(true);
          missingFields = true;
        }
  
        if (auctionType !== 'liveAndOnline' && startDate.getTime() - endDate.getTime() > 0) {
          setEndDateError(true);
          return onError('Invalid date');
        }
  
        if (!auctionOpen && startDate.getTime() < Date.now()) {
          setStartDateError(true);
          return onError('Invalid date');
        }
  
        if(missingFields){
          return onError('Missing fields');
        }
  
  
        let newAuctionData: IAuctionBasicInfo = {
          name,
          pickupInformation: pickupInfo,
          startDate,
          additionalInformation
        };
  
        if (auctionType !== 'liveAndOnline') {
          newAuctionData = { ...newAuctionData, endDate: endDate };
        }
  
        if (auctionType === 'liveAndOnline') {
          newAuctionData = { ...newAuctionData, liveLocation: location, liveStreamLink: liveStreamLink };
        }
  
        setNameError(false);
        setPickupInfoError(false);
        setLocationError(false);
        setEndDateError(false);
        setStartDateError(false);
  
        onUpdate(newAuctionData);
      }
      onVerify();
    }

  }, [ verifyInput ]);



  const updateState = () => {
    onUpdate({
      name: nameRef.current ? nameRef.current.value : '',
      startDate,
      endDate,
      pickupInformation: pickupInfoRef.current ? pickupInfoRef.current.value : '',
    });
  }

  return (
    <>
      <Dialog open={liveStreamInfoDialog} >
        <DialogTitle>How to Embed a Live Video</DialogTitle>
        <DialogContent>
          <Typography>Facebook</Typography>
          <Link href='https://www.facebook.com/help/518336858836703'>Scheduling a live video:</Link>
          <ol>
            <li>Click &quot;What&apos;s on your mind?&quot; at the top of your Feed.</li>
            <li>Click (...) then click Live Video.</li>
            <li>Click Schedule Live Video Event in the left column.</li>
            <li>Make sure to set the audience to Public.</li>
          </ol>
          <Link href='https://www.facebook.com/business/help/170028364865295'>Embedding the video:</Link>
          <ol>
            <li>Find the Live video you want to embed.</li>
            <li>Click the video to open it in fullscreen mode.</li>
            <li>Click (...) in the upper right corner to see more options, then choose Embed.</li>
            <li>Select the &quot;Copy code&quot; button</li>
            <li>Paste the code here</li>
          </ol>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLiveStreamInfoDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Grid container>
          <Grid item xs={12} lg={6} className={classes.grid}>
            <Grid container direction='column'>

              <Grid item className={classes.gridElement}>
                <TextField
                  label={addAsterickOnError(nameError, 'Auction Name')}
                  variant='outlined'
                  error={nameError}
                  id="nameInput"
                  className={classes.textInput}
                  inputRef={nameRef}
                  defaultValue={basicInfo.name}
                  disabled={ readOnly || (auctionOpen ? true : false) }
                  onChange={updateState}
                />
              </Grid>

              <Grid item className={classes.gridElement}>
                <KeyboardDateTimePicker
                  label={addAsterickOnError(startDateError, 'Start Date')}
                  disableToolbar
                  className={classes.textInput}
                  variant="inline"
                  format="MM/DD/yyyy @ hh:mm A"
                  margin="normal"
                  id="date-picker-start"
                  value={startDate}
                  error={startDateError}
                  onChange={(date: MaterialUiPickersDate) => {
                    setStartDate(new Date(date as any));
                    updateState();
                  }}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  disabled={ readOnly || (auctionOpen ? true : false) }
                />
              </Grid>
              
              {auctionType !== 'liveAndOnline' 
                ?
                  <Grid item className={classes.gridElement}>
                    <KeyboardDateTimePicker
                      label={addAsterickOnError(endDateError, 'End Date')}
                      disableToolbar
                      className={classes.textInput}
                      variant="inline"
                      format="MM/DD/yyyy @ hh:mm A"
                      margin="normal"
                      id="date-picker-end"
                      error={endDateError}
                      value={endDate}
                      onChange={(date: MaterialUiPickersDate) => {
                        setEndDate( new Date(date as any));
                        updateState();
                      }}
                      disabled={readOnly}
                      KeyboardButtonProps={{ 'aria-label': 'change date' }}
                    />
                  </Grid>
                : 
                  <>
                    <Grid item className={classes.gridElement}>
                      <TextField
                        label={addAsterickOnError(locationError, 'Location')}
                        variant='outlined'
                        id="location"
                        multiline rows={2}
                        error={locationError}
                        className={classes.textInput}
                        inputRef={locationRef}
                        defaultValue={basicInfo.liveLocation}
                        disabled={readOnly}
                      />
                    </Grid>
                    <Grid item className={classes.gridElement}>
                      <TextField
                        label={'Live Stream Link'}
                        variant='outlined'
                        id="live-stream-link"
                        multiline rows={4}
                        className={classes.textInput}
                        inputRef={liveStreamRef}
                        defaultValue={basicInfo.liveStreamLink}
                        disabled={readOnly}
                        InputProps={{
                          startAdornment: (
                            <InfoRounded 
                              color='primary'
                              style={{ marginRight: '10px', cursor: 'pointer' }}
                              onClick={() => setLiveStreamInfoDialog(true)}
                            />
                          ),
                        }}
                      />
                    </Grid>
                  </>
              }

            </Grid>
          </Grid>

          <Grid item xs={12} lg={6} className={classes.grid}>
            <Grid container direction='column'>
            
              <Grid item className={classes.gridElement}>
                <TextField
                  label={addAsterickOnError(pickupInfoError, 'Pickup Information')}
                  variant='outlined'
                  id="pickupInformation"
                  multiline rows={4}
                  error={pickupInfoError}
                  className={classes.textInput}
                  inputRef={pickupInfoRef}
                  defaultValue={basicInfo.pickupInformation}
                  disabled={readOnly}
                />
              </Grid>

              <Grid item className={classes.gridElement}>
                <TextField
                  label='Additional Information (Optional)'
                  variant='outlined'
                  id="additionalInformation"
                  multiline rows={4}
                  className={classes.textInput}
                  inputRef={additionalInfoRef}
                  defaultValue={basicInfo.additionalInformation}
                  disabled={readOnly}
                />
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>
    </>
  );
}