import React from 'react';
import { RootContext } from '../../Store/RootStore';
import {
  Dialog, DialogActions, DialogTitle, DialogContent,
  TextField, Button, DialogContentText,
} from '@material-ui/core';
import { IAuthManagement } from '../../Interfaces/Database/User';
import { Service } from '@feathersjs/feathers';


interface Props {
  locationSearch: any,
}

interface State {
  open: boolean,
  passError: boolean,
  passErrMsg: string,
  error: string,      // General Error
  success: boolean,
}

export default function PassReset(props: Props): JSX.Element {
  // Store & States
  const store = React.useContext(RootContext);
  const [state, setState] = React.useState<State>({
    open: true,
    passError: false,
    passErrMsg: '',
    error: '',
    success: false,
  });
  const pass1Ref = React.createRef<HTMLInputElement>();
  const pass2Ref = React.createRef<HTMLInputElement>();
  
  // Obtain Token from URL
  const token = React.useMemo(() => props.locationSearch
    .split('?token=')
    .pop(), [ props.locationSearch ]);

  
  // Method: Submit new Password
  const submitNewPassword = () => {
    // Assume all is Good
    setState({
      ...state,
      passError: false,
      passErrMsg: '',
    });
    

    // Validate Inputs Given
    if (!pass1Ref.current?.value || !pass2Ref.current?.value) {
      return setState({
        ...state,
        passError: true,
        passErrMsg: 'No Password Input!',
      });
    }

    // Validate both inputs Equal
    else if (pass1Ref.current.value !== pass2Ref.current.value) {
      return setState({
        ...state,
        passError: true,
      });
    }

    // Submit new Password!
    const { client } = store;
    const authManagement: Service<IAuthManagement> = client?.service('authManagement');
    authManagement.create({
      action: 'resetPwdLong',
      value: {
        token,
        password: pass1Ref.current.value,
      }
    })
      .then(() => setState({ ...state, success: true }))
      .catch(err => setState({
        ...state,
        passError: false,
        passErrMsg: '',
        error: err.message,
      }));
  };

  
  return (
    <Dialog open={state.open} onClose={() => setState({ ...state, open: false })}>
      <DialogTitle>Password Reset</DialogTitle>

      {!state.success 
        ? // Input New Password
          <DialogContent>
            <TextField 
              autoFocus
              margin='dense'
              id='pass1'
              label='New Password'
              type='password'
              fullWidth
              error={state.passError}
              helperText={state.passError ? state.passErrMsg || 'Mismatched Passwords' : ''}
              inputRef={pass1Ref}
            />
            <TextField 
              margin='dense'
              id='pass2'
              label='Repeat New Password'
              type='password'
              fullWidth
              error={state.passError}
              helperText={state.passError ? state.passErrMsg || 'Mismatched Passwords' : ''}
              inputRef={pass2Ref}
            />

            <DialogContentText variant='caption' style={{ color: '#E74C3C' }}>
              {state.error}
            </DialogContentText>
          

            <DialogActions>
              <Button onClick={submitNewPassword}>
                Submit
              </Button>
            </DialogActions>

          </DialogContent>

        : // Successful Password Reset
          <DialogContent>

            <DialogContentText variant='caption' style={{ color: '#E74C3C' }}>
              Your password has been successful reset! You may log in with your new password.
            </DialogContentText>

            <DialogActions>
              <Button onClick={() => setState({ ...state, open: false, success: false })}>
                Close
              </Button>
            </DialogActions>

          </DialogContent>
       
      }

    </Dialog>
  );
};