import React, { useContext } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
  TableCell,
} from '@material-ui/core';
import { PurchaseItemPkg } from '../../Interfaces/Database/Invoice';
import { RootContext } from '../../Store/RootStore';


interface IProps {
  purchaseItems: PurchaseItemPkg[]
}

export default function PaymentStatus({ purchaseItems }: IProps) {
  const store = useContext(RootContext);
  const { client, authToken, user } = store;
  const [failedDialogOpen, setFailedDialogOpen] = React.useState<boolean>(false);
  const [actionRequiredDialogOpen, setActionRequiredDialogOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const purchaseItem = React.useMemo(() => purchaseItems[0], [purchaseItems])

  const initiatePayment = () => {
    setIsLoading(true);
    return client?.service('purchases').patch(purchaseItem._id, {
      paidPackageID: 'pending'
    }, {
      headers: {
        Authorization: authToken,
      },
      user: user || undefined,
    })
      .finally(() => {
        setFailedDialogOpen(false);
        setIsLoading(false);
      });
  };


  const setItemsAsPickup = () => {
    setIsLoading(true);
    const promises: any = [];
    purchaseItems.filter(el => el.deliveryType === 0).map(el => 
      promises.push(client?.service('purchases').patch(el._id, {
        deliveryType: 1
      }, {
        headers: {
          Authorization: authToken,
        },
        user: user || undefined,
      }).then((res: any) => res))
    );

    Promise.all(promises)
      .finally(() => {
        setActionRequiredDialogOpen(false);
        setIsLoading(false);
      });
  }

  return (
    <React.Fragment>
      <TableCell style={{ color: 
        purchaseItem.paidPackageID !== 'pending' && purchaseItem.paidPackageID !== 'failure' ? 'green' 
        : purchaseItem.deliveryType === 0 || purchaseItem.paidPackageID === 'failure' ? 'red' : 'orange', 
      }}>
        {purchaseItem.paidPackageID !== 'pending' && purchaseItem.paidPackageID !== 'failure'
          ? 'Complete'                 //Payment Complete
          : purchaseItem.paidPackageID === 'failure'
            ? 
            <Button
                onClick={() => setFailedDialogOpen(true)}
                size='small'
                variant='outlined'
                color='secondary'
              >Failed</Button>
             : purchaseItem.deliveryType === 0
               ?  <Button // Need to confirm delivery type
                    onClick={() => setActionRequiredDialogOpen(true)}
                    size='small'
                    variant='outlined'
                    color='secondary'
                  >Action Required</Button>        
               : 'Pending'                // Waiting for charge 
        }
      </TableCell>
      <Dialog open={failedDialogOpen}>
        <DialogTitle>Attempt online payment again?</DialogTitle>
        <DialogContentText style={{margin: 20}}>
          Note: Once the payment attempt finishes, refresh the page to get the new payment status.
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => setFailedDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={initiatePayment} color="secondary" variant='outlined'>
            {isLoading
              ? <CircularProgress size='1.5rem' color='secondary' style={{ margin: 'auto' }} />
              : 'Confirm'
            }
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={actionRequiredDialogOpen}>
        <DialogTitle>Bidder has not confirmed their purchases and delivery options.
          Do you want to set all items as pickup on behalf of the bidder?
        </DialogTitle>
        <DialogContentText style={{margin: 20}}>
          Note: Once complete, refresh the page and navigate to the pickup tab to view the payment status.
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => setActionRequiredDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={setItemsAsPickup} color="secondary" variant='outlined'>
            {isLoading
              ? <CircularProgress size='1.5rem' color='secondary' style={{ margin: 'auto' }} />
              : 'Confirm'
            }
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}