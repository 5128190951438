import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { IAuctionCharityInfo } from './CreateAuctionStepper';
import { Grid, TextField, Typography } from '@material-ui/core';
import { colors } from '../../Config/Global';

//@ts-ignore
import Compress from 'client-compress';
import { addAsterickOnError } from '../../Helpers/Accessibility';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: '20px',
    },
    gridElement:{
      margin: 'auto',
      marginBottom: '20px',
      width: '95%',
    },
    imageInput: {
      margin: 'auto',
      marginBottom: '20px',
      width: '95%',
      border: '1px solid #bfbfbf',
      borderRadius: '5px',
    },
    textInput: {
      width: '100%',
    },
    imageContainer:{
      width: '90%',
      border: '1px black solid',
      margin: 'auto',
      backgroundColor: colors.navBarSecondary6up,
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  }),
);

interface IProps {
  charityInfo: IAuctionCharityInfo | null,
  verifyInput: boolean,
  readOnly: boolean,
  onVerify: () => void,
  onUpdate: (info: IAuctionCharityInfo) => void,
  onError: (error: string) => void
}

export default function CharityInfoInput({ charityInfo, verifyInput, readOnly, onVerify, onError, onUpdate }: IProps) {
  const classes = useStyles();
  const [image, setImage] = React.useState<string | null>(charityInfo?.charityImage ? charityInfo.charityImage : null);
  const [name, setName] = React.useState<string | undefined>(charityInfo?.charityName);
  const [description, setDescription] = React.useState<string | undefined>(charityInfo?.charityDescription);
  const [nameError, setNameError] = React.useState<boolean>(false);


  React.useEffect(() => {
    if(verifyInput){
      if (!name && (description || image)) {
        setNameError(true);
        return onError('Charity name is required');
      }
      setNameError(false);
      onVerify();
    }
  }, [ verifyInput ]);


  const handleImageChange = (e: any) => {
    e.preventDefault();

    const compressOptions = {
      targetSize: 0.2,
      quality: 0.75,
      maxWidth: 800,
      maxHeight: 600,
    };

    const compress = new Compress(compressOptions);
    compress.compress([ ...e.target.files ])
      .then((conversions: any) => {
        Compress.blobToBase64(conversions[0].photo.data)
          .then((base64: string) => {
            const newImage = base64.split('base64,')[1];
            setImage(newImage);
            onUpdate({
              charityName: name,
              charityDescription: description,
              charityImage: newImage
            })
          });
      })
      .catch((err: any) => {
        console.log('err: ', err);
      });
  };

  return (
    <Grid container direction='column'>

      <Grid item className={classes.gridElement}>
        <TextField
          label={addAsterickOnError(nameError, 'Charity Name (Optional)')}
          variant='outlined'
          id="charityInput"
          className={classes.textInput}
          value={name}
          error={nameError}
          onChange={(event) => {
            const charityName = event.target.value;
            setName(charityName);
            onUpdate({
              charityName,
              charityDescription: description,
              charityImage: image ? image : undefined
            })
          }}
          disabled={readOnly}
        />
      </Grid>

      <Grid item className={classes.gridElement}>
        <TextField
          label='Charity Description (Optional)'
          variant='outlined'
          id="charityDescriptionInput"
          multiline rows={6}
          className={classes.textInput}
          value={description}
          onChange={(event) => {
            const charityDescription = event.target.value;
            setDescription(charityDescription);
            onUpdate({
              charityName: name,
              charityDescription,
              charityImage: image ? image : undefined
            })
          }}
          disabled={readOnly}
        />
      </Grid>

      <Grid item className={classes.imageInput}>
        
        <Typography style={{ margin: '10px', color: '#a6a6a6' }}>Charity Logo (Optional)</Typography>
        <input type="file"
          accept="image/png, image/jpeg"
          onChange={handleImageChange}
          style={{ margin: '10px' }}
          disabled={readOnly}
        />

        {image &&
          <div className={classes.imageContainer}>
            <img className={classes.image} src={`data:image/png;base64,${image}`} alt={'charityImage'}/>
          </div>
        }

      </Grid>

      
    </Grid>
  );
}