import React, { useContext } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  makeStyles,
  Typography, 
  IconButton,
  Menu, MenuItem,
  Button,
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

// Store & Interfaces
import { RootContext } from '../../Store/RootStore';

// Helper Imports
import { removeCookie } from '../../Helpers';
import { colors } from '../../Config/Global';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
    marginRight: '20px',
  },
  // Profile Section Menu Options
  menuOption: {
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  
});

interface IProps {
  openAccount: () => void,    // Profile Clicked
  onLogin: () => void,
}

export default function RenderProfileSection({ openAccount, onLogin }: IProps): JSX.Element {
  // Hooks
  const styles = useStyles();
  const store = useContext(RootContext);
  const history = useHistory();     // Routes History
  
  // Define States
  const [anchorElt, setAnchorElt] = React.useState<null | HTMLInputElement>(null);


  // Opens the Menu Clicked on
  const handleMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorElt(event.currentTarget as HTMLInputElement);
  };

  // Closes Profile Anchor
  const handleClose = () => {
    setAnchorElt(null);
  };

  // Signs User out and Removes Cookies
  const signOut = () => {
    // Clear out Cookies
    removeCookie('accessToken');
    removeCookie('userId');

    // Clear out Client Data
    store.actions.setUser(null);

    // Refresh Page
    setTimeout(() => {
      history.push('/');
      // NOTE: I kept both because if its just push / , when I try to login with a different user, 
      //       it says invalid login unless I refresh
      history.go(0);
    }, 1000);
  };

  return (
    <span className={styles.root}>

      {store.user 
        ?<> {/*---------------- User is Logged in ---------------- */}
          <Typography variant='h6' style={{color: colors.darkBlueFont}}>
            {store.user.firstName}
          </Typography>

          <IconButton
            aria-label='user profile account'
            aria-controls='menu-appbar'
            aria-haspopup={true}
            onClick={handleMenu}
            color='inherit'
          >
            <AccountCircle />
          </IconButton>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElt}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElt)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {
              openAccount(); handleClose();
            }}>Account Details</MenuItem>

            <RouterLink to='/payment' className={styles.menuOption}>
              <MenuItem onClick={handleClose}>Payment Information</MenuItem>
            </RouterLink>

            <RouterLink to='/profile/purchases' className={styles.menuOption}>
              <MenuItem onClick={handleClose}>Purchase History</MenuItem>
            </RouterLink>
            
            <MenuItem onClick={signOut}>Sign Out</MenuItem>
          </Menu></>

        :  // ----------------User is NOT Logged in ----------------
          <Button 
            size='large' 
            variant='outlined' 
            onClick={() => onLogin() }
            style={{border: 'none'}}
          >
            Login
          </Button>

      }
      
    </span>
  );
}
