
export type DELIVERY_TYPE = 'pending' | 'shipping' | 'pickup';
export const deliveryType = ['pending', 'shipping',  'pickup'];

export interface IPurchase {
  _id?:              string,     // Auto-Generated Mongoose

  // References
  userID:            string,
  itemID:            string,
  auctionID:         string,

  // Data
  itemCost:          number,
  deliveryType:      number,    // Pending: 0, Pick-up: 1, Shipping: 2
  shippingAddress?:  string,
  paidPackageID:     string,  // Upon Payment Transaction, the lumped items get packageID (userID & TimeStamp)

  // MongoDB Attachments
  createdAt?:       string, // Date but in UTC String
  updatedAt?:       string, // Date but in UTC String
}