import React from 'react';
import {
  Checkbox, FormControlLabel, Typography,
} from '@material-ui/core';
import { termsOfService, biddersTermsOfService, privacySecurity } from './PolicyText';


interface Props {
  isAgreed: boolean,
  onChange: (checked: boolean) => void
}


export default function ReviewTermsAndConditions({ isAgreed, onChange }: Props): JSX.Element {
  return (
    <div>
      <Typography style={{ fontWeight: 'bold', textAlign: 'center', margin: '30px 0 5px' }}>Review the Terms and Conditions</Typography>       
      <Typography variant='h6' style={{ fontStyle: 'italic', marginTop: '10px' }}>Terms of Service</Typography>
      {termsOfService(false)}
      <Typography variant='h6' style={{ fontStyle: 'italic', marginTop: '10px' }}>Bidders Terms of Service</Typography>
      {biddersTermsOfService()}
      <Typography variant='h6' style={{ fontStyle: 'italic', marginTop: '10px' }}>Privacy Policy</Typography>
      {privacySecurity()}

      <FormControlLabel
        control={
          <Checkbox
            checked={isAgreed}
            onChange={(event) => onChange(event.target.checked)}
            name="publicCheck"
            color="primary"
          />  
        }
        label="I agree to the Terms and Conditions"
        style={{ marginTop: '30px' }}
      />
    </div>
  );
};