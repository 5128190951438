import React, { useContext } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableContainer,
  TableHead,
  CircularProgress,
  TableRow, TableCell, TableBody, IconButton, Dialog, DialogTitle, DialogContent, Grid, Typography, TextField, DialogActions, Button, Link
} from '@material-ui/core';
import { IUser } from '../../Interfaces/Database/User';
import { RootContext } from '../../Store/RootStore';
import { Service } from '@feathersjs/feathers';
import { QueryResult } from '../../Interfaces/Service';
import Paginate from '../../Components/Paginate';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { addAsterickOnError } from '../../Helpers/Accessibility';
import config from '../../Config/Global';
import CopyEmails from './CopyEmails';


// Local Styles
const useStyles = makeStyles({
  textInput: {
    width: '100%',
    textAlign: 'center',
    margin: '10px 20px',

  },
});


export default function Staff(){
  const classes = useStyles();
  const store = useContext(RootContext);
  const usersPerPage = 15;

  const {client, user, authToken} = store;

  const [totalUsers, setTotalUsers] = React.useState<number>(50); 
  const [users, setUsers] = React.useState<IUser[]>([]); 
  const [page, setPage] = React.useState(0);

  const [addStaffDialogOpen, setAddStaffDialogOpen] = React.useState<boolean>(false);
  const [addStaffLoading, setAddStaffLoading] = React.useState<boolean>(false);
  const [firstNameInput, setFirstNameInput] = React.useState<string>('');
  const [lastNameInput, setLastNameInput] = React.useState<string>('');
  const [emailInput, setEmailInput] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  
  const [viewPendingDetails, setViewPendingDetails] = React.useState<boolean>(false);
  const [pendingDetailsUserViewing, setPendingDetailsUserViewing] = React.useState<IUser>();

  const [firstNameError, setFirstNameError] = React.useState<boolean>(false);
  const [lastNameError, setLastNameError] = React.useState<boolean>(false);
  const [emailError, setEmailError] = React.useState<boolean>(false);

  const userService: Service<IUser> = client?.service('users');

  React.useEffect(() => {
    if(!user) return;

    // Find All Users
    userService.find({
      Authorization: authToken,
      user: user || undefined,
      query: {
        isStaff: true,
        $limit: 0
      }
    })
      .then((res: any) => {
        setTotalUsers((res as QueryResult<IUser>).total);
        getNextPage(0);
      });

  }, [ user ]);

  const getNextPage = (newPage: number) => {
    userService.find({
      Authorization: authToken,
      user: user || undefined,
      query: {
        isStaff: true,
        $limit: usersPerPage,
        $skip: newPage * usersPerPage
      }
    })
    .then((res: any) => res.data)
    .then((data: IUser[]) => {
      setUsers(data.map(staff => {
        return {
          ...staff,
          email: staff.email.substring(0, staff.email.length - 6)
        }
      }));
    });
  };

  const changePage = (data: any) => {
    const newPage = data.selected;
    setPage(newPage);
    getNextPage(newPage);
  };

  const resetAddDialog = () => {
    setErrorMessage('');
    setEmailError(false);
    setFirstNameError(false);
    setLastNameError(false);
    setFirstNameInput('');
    setLastNameInput('');
    setEmailInput('');
    setAddStaffLoading(false);
    setAddStaffDialogOpen(false);
  }

  const handleAdd = () => {
    setAddStaffLoading(true);

    if(!firstNameInput){
      setFirstNameError(true);
    }
    if(!lastNameInput){
      setLastNameError(true);
    }
    if(!emailInput){
      setEmailError(true);
    }

    if(firstNameInput && lastNameInput && emailInput){
      const newStaff: Partial<IUser> = {
        firstName: firstNameInput,
        lastName: lastNameInput,
        email: emailInput.toLocaleLowerCase(),
        isStaff: true,
        staffStatus: 'pending'
      }

      userService.create(newStaff, {
        Authorization: authToken,
        user: user || undefined,
      })
        .then(() => {
          resetAddDialog();
          getNextPage(0);
        })
        .catch((err: any) => {
          setAddStaffLoading(false);
          console.log(err);
          if ((err.message as string).includes('User Email Already Exists')) {
            setErrorMessage('User Email Already Exists');
          } else {
            setErrorMessage('An error has occurred');
          }
        })
    }
  }



  return (
    <Paper>

      <Dialog open={addStaffDialogOpen}>
        <DialogTitle>Add a staff user</DialogTitle>
        <DialogContent>
          <Grid container>
            <Typography>
              An email will get sent to the user containing information on how to finish setting up their staff account. 
              Upon creation, you may add this user as a specific role on a per auction basis.
            </Typography>
            <TextField
              size={'small'}
              label={addAsterickOnError(firstNameError, 'First Name')}
              variant='outlined'
              className={classes.textInput}
              value={firstNameInput}
              onChange={(e: any) => setFirstNameInput(e.target.value)}
              error={firstNameError}
            />
            <TextField
              size={'small'}
              label={addAsterickOnError(lastNameError, 'Last Name')}
              variant='outlined'
              className={classes.textInput}
              value={lastNameInput}
              onChange={(e: any) => setLastNameInput(e.target.value)}
              error={lastNameError}
            />
            <TextField
              size={'small'}
              label={addAsterickOnError(emailError, 'Email')}
              variant='outlined'
              className={classes.textInput}
              value={emailInput}
              onChange={(e: any) => setEmailInput(e.target.value)}
              error={emailError}
            />
          </Grid>
        </DialogContent>
       
        <DialogActions>
          <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
          <Button onClick={resetAddDialog} color="primary">Cancel</Button>
          <Button variant="contained" color="primary" onClick={handleAdd}>
              {addStaffLoading 
                ?
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    <CircularProgress size='1rem' style={{ margin: 'auto', color: 'white' }} />
                  </div>
                : 'Add'
              }
            </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={viewPendingDetails}>
        <DialogTitle>Pending Staff: {pendingDetailsUserViewing?.firstName} {pendingDetailsUserViewing?.lastName}</DialogTitle>
        <DialogContent>
          <>
            <Typography style={{ marginBottom: '5px' }}>{pendingDetailsUserViewing?.firstName}&apos;s next step is to check their email and follow the link provided
              to complete their account setup. If they did not receive the email, they can use the following link:
            </Typography>
            {(pendingDetailsUserViewing as any)?.resetToken
              ? <Typography>
                  <Link>{config.HOST_STAFF}/setup?token={(pendingDetailsUserViewing as any)?.resetToken}</Link>
                </Typography>
              : <Typography color='error' style={{ fontStyle: 'italic' }}>
                  Could not load setup link. Refresh the page and try again.
                </Typography>
            }
            
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setViewPendingDetails(false)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>


      <IconButton onClick={() => setAddStaffDialogOpen(true)}>
        <AddCircleIcon />
      </IconButton>
      <CopyEmails isStaff={true} totalUsers={totalUsers}/>
      <TableContainer >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell><strong>Customer</strong></TableCell>
              <TableCell><strong>Contact</strong></TableCell>
              <TableCell><strong>Created</strong></TableCell>
              <TableCell><strong>Status</strong></TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {users
              .map(user => (
                <TableRow key={user._id}>
                  <TableCell scope='row' component='th'>{user.firstName} {user.lastName}</TableCell>
                  <TableCell>{user.email}<br/>{user.phoneNumber}</TableCell>
                  <TableCell>{new Date((user as any).createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <Button 
                      onClick={() => {
                        setPendingDetailsUserViewing(user);
                        setViewPendingDetails(true);
                      }}
                      size='small' 
                      color={user.staffStatus === 'pending' ? 'secondary' : 'default'}
                      variant='outlined'
                      disabled={user.staffStatus === 'verified'}
                      >{user.staffStatus}
                      </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>

        <Paginate
          totalElements={totalUsers}
          elementsPerPage={usersPerPage}
          currentPage={page}
          onPageChange={changePage}
        />

      </TableContainer>
    </Paper>
  );
}