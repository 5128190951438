import { IBid } from '../../Interfaces/Database/Bid';
import { PurchaseItemPkg } from '../../Interfaces/Database/Invoice';
import { IItem } from '../../Interfaces/Database/Item';
import { DELIVERY_TYPE, IPurchase } from '../../Interfaces/Database/Purchase';
import { IUser } from '../../Interfaces/Database/User';
import { IRootStore } from '../RootStore';

export interface BidWithContact {
  bid: IBid | null,
  user: IUser,
  purchase: IPurchase | null,
}

export interface NextBidderStore {
  [itemID: string]: BidWithContact[], // Starts off Empty unless Queried (Only Admin)
}

export interface IByAddressUser {
  address: string | undefined,
  user: IUser,
  items: PurchaseItemPkg[],
}

export interface AuctionReportStore {
  [auctionID: string]: {              // Starts off Empty unless Queried (Only Admin)
    pickupData: IByAddressUser[],
    shippingData: IByAddressUser[],
    noMethodData: IByAddressUser[],
    notSoldData: IItem[],
  }
}

export interface deliveryTypePayload {
  auctionID: string,
  data: IByAddressUser[],
}

export interface notSoldPayload {
  auctionID: string,
  data: IItem[],
}

export interface updatePayload {
  auctionID: string,
  itemID: string, // To Remove Next Bidder Data For Item
  pendingData: IByAddressUser[],
  notSoldData: IItem[],
}

export interface ReportAction {
  type: DELIVERY_TYPE | 'not_sold' | 'update',
  payload: deliveryTypePayload | notSoldPayload | updatePayload, 
}

export const reportReducer = (state: IRootStore, action: ReportAction): IRootStore => {
  switch(action.type) {
    case 'pickup': 
      return {
        ...state,
        auctionReportStore: {
          ...state.auctionReportStore,
          [(action.payload as deliveryTypePayload).auctionID]: {
            ...state.auctionReportStore[(action.payload as deliveryTypePayload).auctionID],
            pickupData: (action.payload as deliveryTypePayload).data,
          },
        },
      };
    case 'shipping': 
      return{
        ...state,
        auctionReportStore: {
          ...state.auctionReportStore,
          [(action.payload as deliveryTypePayload).auctionID]: {
            ...state.auctionReportStore[(action.payload as deliveryTypePayload).auctionID],
            shippingData: (action.payload as deliveryTypePayload).data,
          },
        },
      };
    case 'pending': 
      return {
        ...state,
        auctionReportStore: {
          ...state.auctionReportStore,
          [(action.payload as deliveryTypePayload).auctionID]: {
            ...state.auctionReportStore[(action.payload as deliveryTypePayload).auctionID],
            noMethodData: (action.payload as deliveryTypePayload).data,
          },
        },
      };
    case 'not_sold': 
      return {
        ...state,
        auctionReportStore: {
          ...state.auctionReportStore,
          [(action.payload as notSoldPayload).auctionID]: {
            ...state.auctionReportStore[(action.payload as notSoldPayload).auctionID],
            notSoldData: (action.payload as notSoldPayload).data,
          },
        },
      };
    case 'update': 
      return {
        ...state,
        // Update No Method & Not Sold Data
        auctionReportStore: {
          ...state.auctionReportStore,
          [(action.payload as updatePayload).auctionID]: {
            ...state.auctionReportStore[(action.payload as updatePayload).auctionID],
            noMethodData: (action.payload as updatePayload).pendingData,
            notSoldData: (action.payload as updatePayload).notSoldData,
          },
        },

        // Remove Next Bidder Store For Updated Item
        nextBidderStore: Object.keys(state.nextBidderStore)
        .reduce((acc, itemID) => (
          itemID !== (action.payload as updatePayload).itemID
            ? { ...acc, ...state.nextBidderStore[itemID] }
            : acc
        ), {}),
      };
  }
};