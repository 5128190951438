import { Service } from '@feathersjs/feathers';
import { IRootStore } from '../RootStore';
import { auctionReducer, BidStore, ItemStore } from '../Reducers/AuctionReducer';
import { IBid } from '../../Interfaces/Database/Bid';
import { IBidWithUser } from '../../Interfaces/Database/bidHistory';
import { StateSetter } from './Interfaces';
import { IMaxBid } from '../../Interfaces/Database/MaxBid';


export const addBid = async (bid: IBid, state: IRootStore, setState: StateSetter): Promise<IBid> => {
  // Add Bid to Server
  const { client, user, authToken } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;

  if (!client) return Promise.reject(null);

  const number = user ? user.bidderNumber : null;
  const bidService: Service<IBid> = client?.service('bids');
  
  return bidService.create(bid, {
    headers,
    user: user || undefined,
  })
    .then(newBid => {
      // Attach Bid to Item to Global State
      // setState(
      //   auctionReducer(state, {
      //     type: 'ADD_BID',
      //     payload: {
      //       bid: newBid,
      //       number,
      //   } as BidStore},
      // ));

      // Return bid for .then Chain
      return newBid;
    });
};


/**
 * Queries the Bid History for the given itemID
 *  storing it in the Bid History
 * @param itemID The Item's ID
 * @param first boolean true of first call to queryBidHistory
 * @param state - The Current State
 * @param setState - State Setter for the new State
 */
export const queryBidHistory = (itemID: string, first: boolean, state: IRootStore, setState: StateSetter): Promise<BidStore[]> => {
  // Check if in the State
  // If first call to function, full query of all bids 
  // (bids made since login will be added to global without complete query)
  if (!first && state.bidHistoryStore[itemID]) {
    return Promise.resolve(state.bidHistoryStore[itemID] as BidStore[]);
  }

  // Construct Data/Services that will be used
  const { client, user, authToken } = state;
  if (!client) return Promise.reject();
  const bidHistoryService: Service<IBidWithUser> = client.service('bid-history');
  const params = {
    headers: { Authorization: authToken },
    user: user || undefined,
    query: {
      itemID,
      $limit: 50
    },
  };

  // Query for the Data
  return bidHistoryService.find(params)
    .then((res: any) => {
      // Store in global State
      setState({
        ...state,
        bidHistoryStore: {
          ...state.bidHistoryStore,
          //@ts-ignore
          [itemID]:  res.map(bid => ({bid: bid.bid, number: bid.number})),
        },
      } as IRootStore);
      
      return res as BidStore[];
    }); 

};