import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { colors } from '../../Config/Global';
import {
  Box, Typography,
  makeStyles,Divider, Grid
} from '@material-ui/core';
import { IAuction } from '../../Interfaces/Database/Auction';
import { RootContext } from '../../Store/RootStore';

// Logos
import MAA from '../../images/MAA.jpg';
import NAA from '../../images/NAA.jpg';

// Local Styles
const useStyles = makeStyles({
  root: {
    minHeight: '70vh',
  },
  box: {
    maxWidth: '1300px',
    borderRadius: '5px',
    margin: '50px auto',
    contain: 'content',
  },
  heading: {
    color: colors.darkBlueFont,
    fontSize: 22,
    margin: '10px',
    fontWeight: 'bold',
    textAlign: 'center',

  },
  subtitle: {
    textAlign: 'center',
    color: colors.darkBlueFont,
    fontSize: 18,
    margin: '20px',
    fontWeight: 'bold',
  },
  text: {
    textAlign: 'center',
    color: colors.darkBlueFont,
    fontSize: 18,
  },
  subText: {
    color: colors.darkBlueFont,
    fontSize: 16,
    margin: '20px 10px',
  },
  logo: {
    width: '100px',
    height: '100px',
    margin: '10px',
  },
  slideshowContainer: {
    contain: 'content',
    margin: '20px'
  },
  images: {
    width: '90%',
    height: '90%',
    objectFit: 'cover',
    textAlign: 'center'
  },
});


interface IProps {
  auctions: IAuction[],
}

const removeMilli = (date: Date) => {
  const d = new Date(date).toLocaleString();
  return d.slice(0, -6).concat(d.slice(-2));
};

export default function Home({ auctions }: IProps){
  const styles = useStyles();
  const store = useContext(RootContext);

  const [closed, setClosed] = React.useState<IAuction[]>([]);
  const [open, setOpen] = React.useState<IAuction[]>([]);
  const [future, setFuture] = React.useState<IAuction[]>([]);
  const images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];
  
  const closedDate = React.useMemo(() => 
    closed ? closed.map(auction =>  auction.endDate ? removeMilli(auction.endDate) : '') : []
  , [ closed ]);

  const openClosesOn = React.useMemo(() => 
    open ? open.map(auction =>  auction.endDate ? removeMilli(auction.endDate) : '') : []
  , [ open ]);

  const openDate = React.useMemo(() => 
    future ? future?.map(auction =>  removeMilli(auction.startDate)) : []
  , [ future ]);


  React.useEffect(() => {
    setFuture(auctions.filter(data => data.state === 1));
    setOpen(auctions.filter(data => data.state === 2));
    // Only Display Auctions that Closed Within 48 Hours
    setClosed(auctions.filter(data => {
      const date = data.endDate ? data.endDate : data.startDate;
      if(data.state === 3 && new Date(date) >= new Date(new Date().setDate(new Date().getDate() - 2))){
        return true;
      } else {
        return false;
      }
    }));

  }, [auctions, store.user]);

  React.useEffect(() => {

  }, [ store.slideshowItems ]);

  return (
    <Box className={styles.box}>

      <Grid container spacing={5} style={{ padding: '20px' }}>

        {/* Who We Are */}
        <Grid item xs={12} md={6}>
          <Typography className={styles.heading}>Who We Are</Typography>
          <Typography className={styles.text} >
            Royale Treasures Auction assists clients by striving for 
          </Typography> 
          <Typography className={styles.text} >
            maximum values with honesty, integrity, and excellence 
          </Typography>
          <Typography className={styles.text} >
            with every service.
          </Typography> 
        </Grid>

        {/* Associations */}
        <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
          <Typography className={styles.heading}>Associations</Typography>
          <a href='https://msaa.org/'><img src={MAA} className={styles.logo} /></a>
          <a href='https://www.auctioneers.org/'><img src={NAA} className={styles.logo} /></a>
        </Grid>

      </Grid>



      {/* Announcements - Open & Closing Auctions */}
      <Box className={styles.box} style={{ backgroundColor: colors.offWhite }}>

        <Typography className={styles.heading} style={{ textAlign: 'center' }}>Auction Schedule</Typography> 

        {/* Open Auction Annoucements */}
        <Divider/>
        <Typography className={styles.subtitle}>{open.length === 0 ? 'Currently no open Auctions' : 'Open Auctions'}</Typography> 
        {open.map(( auction, index ) =>
          <RouterLink key={index} to={`/auctions/${auction._id}`}>
                {auction.type === 'liveAndOnline' 
                  ? <Typography className={styles.text} style={{ margin: '15px', color: '#FF0000' }}>{auction.name}: Live Now</Typography>
                  : <Typography className={styles.text} style={{ margin: '15px' }}>{auction.name}: Closes on {openClosesOn[index]}</Typography>
                }
          </RouterLink>,
        )}


        {/* Closed Auction Annoucements */}
        {closed.length > 0 && <>
          <Divider/>
          <Typography className={styles.subtitle}>Recently Closed Auctions</Typography> 
          {closed.map(( auction, index ) =>
            <Typography key={index} className={styles.text} style={{ margin: '15px' }}>{auction.name}: Closed on {closedDate[index]}</Typography>, 
          )}
          <Typography className={styles.subText} style={{ margin: '20px 40px' }}>
            Navigate to your account purchase history to view your won items. You must submit your desired delivery method (shipping or pickup)
            within 48 hours after auction closure. The payment transaction will include all bought items along with any shipping costs.
          </Typography> 
        </>}


        {/* Future Auction Annoucements */}
        {future.length > 0 && <>
          <Divider/>
          <Typography className={styles.subtitle}>Future Auctions</Typography> 
          {future.map(( auction, index ) => 
            <div style={{ margin: '15px' }}>
              <RouterLink key={index} to={`/auctions/${auction._id}`}>
                {auction.type === 'liveAndOnline' 
                  ? <Typography className={styles.text} style={{ color: '#FF0000' }}>{auction.name}: Live starts {openDate[index]}</Typography>
                  : <Typography key={index} className={styles.text}>{auction.name}: opens on {openDate[index]}</Typography> 
                }
              </RouterLink>
              {auction.type === 'liveAndOnline' && auction.preBiddingEnabled &&
                <>
                  {auction.preBiddingOpen 
                    ? <Typography className={styles.text} style={{ color: '#0003DC' }}>Pre-Bidding:&emsp;OPEN NOW&emsp;Ends: {new Date(auction.preBiddingEnd as any).toLocaleString()}</Typography>
                    : <Typography className={styles.text} style={{ color: '#0003DC' }}>
                        Pre-Bidding:&emsp;
                        {new Date(auction.preBiddingStart as any) > new Date() ? 'Starts: ' : 'Started: '} {new Date(auction.preBiddingStart as any).toLocaleString()},&emsp;
                        {new Date(auction.preBiddingEnd as any) > new Date() ? 'Ends: ' : 'Ended: ' } {new Date(auction.preBiddingEnd as any).toLocaleString()}
                      </Typography>
                  }
                </>
              }
            </div>,
          )}
        </>}
      
      </Box>

      <Grid container>
        {images.map(num => 
          <Grid key={num} item xs={12} md={6} style={{ textAlignLast: 'center' }}>
            <img src={require(`../../images/collage/${num}.jpg`)} className={styles.images}/>
          </Grid>,
        )}
      </Grid>

    </Box>
  );
}