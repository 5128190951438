
/**
 * Parses the Raw Cookie String
 *  into a Key-Value Pair
 * @param str Raw Cookie String
 */
export function parseCookie(str: string) {
  const strArr = str.split('; ');
  const result = {};
  for (let i = 0; i < strArr.length; i++) {
    const cur = strArr[i].split('=');
    (result as any)[cur[0]] = cur[1];
  }
  return result;
}

/**
 * Removes a Key Entry from Cookies
 * @param key The Cookie Key to Remove
 */
export function removeCookie(key: string) {
  document.cookie = `${key}=;expires=${new Date(0).toUTCString()}; path=/`;
}

/**
 * Stores Cookie
 * @param key The Key Entry to add
 * @param val The Value of the Key Entry
 * @param exp The Expiration Date of the Key
 */
export function addCookie(key: string, val: string, exp: Date) {
  document.cookie = `${key}=${val}; expires=${exp.toUTCString()}; path=/`;
}