import React, { useContext } from 'react';
import {
  makeStyles, Paper, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
  TableFooter,
} from '@material-ui/core';

import { IByAddressUser } from './ItemDeliveryTabs';
import CollapsableSection from './CollapsableSection';
import { colors } from '../../Config/Global';
import { RootContext } from '../../Store/RootStore';
import PaymentStatus from './PaymentStatus';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  submit: {
    float: 'right',
    backgroundColor: colors.navBarSecondary5up,
    margin: '10px',
    padding: '5px',
  }
});


interface IProps{
  auctionID: string
}

export default function ItemDeliveryTable({ auctionID }: IProps) {
  const classes = useStyles();
  const store = useContext(RootContext);
  const { auctionReportStore } = store;
  const data: IByAddressUser[] = auctionReportStore[auctionID].shippingData;

  
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isUnsubmittedChanges, setIsUnsubmittedChanges] = React.useState<boolean>(false);
  const [newPage, setNewPage] = React.useState<number>(0); //If unsaved changes and the user continues, change to new page
  



  const handleChangePage = (event: unknown, newPage: number) => {
    if(isUnsubmittedChanges){
      setNewPage(newPage);
    }
    else{
      setNewPage(newPage);
      setPage(newPage);
    }
  };



  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  

  return (

    <Paper className={classes.root}>
      <TableContainer>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell><strong>Status</strong></TableCell>
              <TableCell><strong>Address</strong></TableCell>
              <TableCell><strong>Customer</strong></TableCell>
              <TableCell><strong>Contact</strong></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (<>
              {/* Collapsable: Item Information */}
              <CollapsableSection key={index} items={row.items} auctionID={auctionID} nextBidderOption={false}>
                {/* User Information */}
                <PaymentStatus purchaseItems={row.items}/>
                <TableCell>{row.items[0].shippingAddress}</TableCell>
                <TableCell>{row.user.firstName} {row.user.lastName}</TableCell>
                <TableCell>{row.user.email}<br/>{row.user.phoneNumber}</TableCell>
              </CollapsableSection>
            </>))}
            
          </TableBody>
        </Table>

        <TableFooter>
          <TableRow>

            <TablePagination
              rowsPerPageOptions={[2, 5, 10]} // rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />

          </TableRow>
        </TableFooter>
      </TableContainer>
    </Paper>
  );
}