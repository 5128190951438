import React from 'react';
import {
  withStyles, WithStyles, Theme, createStyles,  //Styles
  Card, CardActionArea, CardContent, CardMedia, CardActions, IconButton,//Item 
  Typography,
  Grid,
} from '@material-ui/core';
import { Favorite } from '@material-ui/icons';

// The Store!
import {
  IRootStore,
  RootContext,
  withAppContext,
} from '../../Store/RootStore';
import { IAuction } from '../../Interfaces/Database/Auction';
import { bidsViewable } from '../../Helpers/bidsViewable';

const styles = (theme: Theme) => createStyles({
  root: {
    width: '325px',
    height: '400px',
    padding: '10px',
    margin: '10px',
  },
  media: {
    height: 200,
  },
  content: {
    height: '150px',
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
 },
});


// App's Interfaces
interface IProps extends WithStyles<typeof styles> {
  auction:            IAuction,
  itemID:             string,
  onToggleWatchlist:  () => void,    // Callback for Adding/Removing Item from Watchlist
  isWatchlist?:       boolean,
  onClick:            () => void, 
  context:            IRootStore,
}

interface IState {}

class CardItem extends React.Component<IProps, IState>{

  constructor(props: IProps){
    super(props);
  }

  render() {
    const { classes, context, auction, itemID, onToggleWatchlist, isWatchlist, onClick } = this.props;
    const item = context.itemStore[itemID] ? context.itemStore[itemID].item : null;
    const highestBid = context.itemStore[itemID] ? context.itemStore[itemID].highestBid : null;


    return (<>
      
      {item &&
        <Card className={classes.root}>

          <CardActionArea onClick={() => onClick()}> 
            {item.pictures[0] && 
              <CardMedia
                className={classes.media}
                image={`data:image/png;base64,${item.pictures[0]}`}
                title={item.title}
              />
            }
            <CardContent className={classes.content}>

              <Typography style={{WebkitLineClamp: 1}} className={classes.text} gutterBottom variant="h6">
                {item.title}
              </Typography>
              
              <Typography style={{WebkitLineClamp: 3, height: '60px', marginBottom: '10px'}} className={classes.text} variant="body2" color="textSecondary" component="p">
                {item.description}
              </Typography>

              {highestBid && bidsViewable(auction)
                ? <>
                    <Typography style={{float: 'left', fontWeight: 'bold'}} variant="subtitle1" > Highest Bid: ${context.itemStore[itemID].highestBid?.bid.price}</Typography>
                    {this.props.context.user?.bidderNumber === highestBid.number &&
                      <Typography variant="subtitle1" style={{ color: '#0088cc' }}> &ensp; Your Bid</Typography>
                    }
                  </>
                : item.isSold
                  ?  <>
                      <Typography style={{float: 'left', fontWeight: 'bold'}} variant="subtitle1" > Price: ${item.purchasePrice} </Typography>
                      {this.props.context.user?.bidderNumber === item.purchaseBidder &&
                        <Typography variant="subtitle1" style={{ color: '#0088cc', float: 'left' }}> &ensp; Your Purchase</Typography>
                      }
                    </>
                  : <Typography style={{float: 'left', fontWeight: 'bold'}} variant="subtitle1" > Starting Bid: ${item.startingBid} </Typography>
              }

              {item.isSold &&
                <Typography style={{float: 'right', fontWeight: 'bolder'}} variant="subtitle1" color="error">
                  SOLD
                </Typography>
              }
            </CardContent>
          </CardActionArea>

          <CardActions style={{ padding: 0, marginTop: '-4px' }} >
            {/* Users Adds or Removes Item from Watchlist  */}
            <Grid item xs={6}>
              {/* {(auction.state === 1 || auction.state === 2) && // User Can Add to Watchlist on Open and Public Auctions
                <div style={{ display: 'flex', height: 18, cursor: 'pointer' }} onClick={() => onToggleWatchlist()}>
                  <IconButton >
                    <Favorite style={{
                      color: isWatchlist ? '#E74C3C' : undefined,
                      width: 18,
                      
                    }} />
                  </IconButton>
                  <Typography variant='caption' style={{ marginLeft: '-5px' }}>Watch</Typography>
                </div>
              } */}
            </Grid>
            <Grid item xs={6}>
              {item.itemNumber && 
                <p style={{float: 'right'}}>#{item.itemNumber}</p>
              }
            </Grid>
          </CardActions>

      
        </Card>
      }
    </>);
  }
}

// Export with Local Styles
export default withAppContext(RootContext, withStyles(styles)(CardItem));