import React, { useContext } from 'react';
import {
  CircularProgress,
  Container,
  makeStyles,
  Paper,
  Table, TableBody, TableCell, 
  TableContainer, 
  TableFooter, 
  TableHead, TablePagination, TableRow, Typography,
} from '@material-ui/core';


import { RootContext } from '../../Store/RootStore';
import { IAuction } from '../../Interfaces/Database/Auction';
import { UserBundle } from '../../Interfaces/Database/PurchaseReport';
import PaymentReportDetails from '../../Components/PaymentReportDetails';

const useStyles = makeStyles({
  container: {
    width: '100%',
    minHeight: '300px',
  },
});

interface Props {
  auction: IAuction,
}

export default function PaymentsTable ({ auction }: Props): JSX.Element {
  const { client, authToken, user } = useContext(RootContext);
  const classes = useStyles();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [paymentData, setPaymentData] = React.useState<UserBundle[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    if (!client || !user || !authToken) return;
    setLoading(true);

    client.service('purchases-report').find({
      query: { auctionID: auction._id },
      headers: { Authorization: authToken },
      user,
    })
      .then((res: any) =>{
        setPaymentData(res);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log('purchase report error: ', err);
      });

  }, [ auction ]);


  return (
    
    <Container className={classes.container}>

      {loading
        ?             
          <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
            <CircularProgress size='2.5rem' style={{ margin: 'auto' }}/>
          </div>
        : <>
          {paymentData.length
            ? 
              <Paper className={classes.container}>
                <TableContainer >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell align='left'><strong>Customer</strong></TableCell>
                        <TableCell><strong>Contact</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    
                    <TableBody>
                      {paymentData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <PaymentReportDetails key={index} data={row.data}>
                            <TableCell scope='row' component='th'>{row.user.firstName} {row.user.lastName}</TableCell>
                            <TableCell>{row.user.email}<br/>{row.user.phoneNumber}</TableCell>
                          </PaymentReportDetails>
                        ))}
                    </TableBody>
                  </Table>

                  <TableFooter>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component='div'
                      count={paymentData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={(_, newPage) => setPage(newPage)}
                      onChangeRowsPerPage={e => setRowsPerPage(+e.target.value)}
                    />
                  </TableFooter>
                </TableContainer>
              </Paper>
            : <Typography style={{ margin: 'auto'}}>None</Typography>
          }
      </>}
    </Container>
  );
}
