import React from 'react';
import {
  withStyles, WithStyles, Theme, createStyles,                      
  Button, TextField, Box, FormControl, 
  Grid, Select, InputLabel, Dialog, DialogTitle, DialogActions,
  CircularProgress,
} from '@material-ui/core';

import { NumberFormatCustom, NumberFormatCustomDecimal } from '../../Helpers/NumberFormatCustom';

import { IItem } from '../../Interfaces/Database/Item';
import { ICategoryMap } from '../../App';
import Images from './Images';
import { colors } from '../../Config/Global';

// The Store!
import {
  IRootStore,
  RootContext,
  withAppContext,
} from '../../Store/RootStore';
import { SHIPPING_TYPE, ShippingType } from '../../Interfaces/Database/Item';
import { IAuction } from '../../Interfaces/Database/Auction';

const styles = (theme: Theme) => createStyles({
  grid: {
    padding: '20px',
  },
  gridElement:{
    margin: 'auto',
    marginBottom: '20px',
    width: '95%',
  },
  textInput: {
    width: '100%',
  },
  errorMess: {
    float: 'right',
    textAlign: 'center',
    fontSize: '17px',
    color: '#FF0000',
    margin: '10px',
  },
  button: {
    float: 'right',
    border: '1px solid black',
    borderRadius: '2px',
    padding: '5px',
    margin: '10px',
    backgroundColor: colors.navBarSecondary5up,
  },
});


// App's Interfaces
interface IProps extends WithStyles<typeof styles> {
  auction:  IAuction,
  itemID:     string | null,
  categories: ICategoryMap,
  context:    IRootStore,

  // To Open / Close View
  onCreate: (item: IItem) => void,
  onEdit: () => void,
  onCancel:   () => void,
}
interface IState {
  category:               string | undefined,
  shippingType:           ShippingType | undefined,
  shippingCost:           number | undefined,
  startingBid:            number | undefined,
  bidIncrement:           number | undefined,
  buyNowPrice:            any,
  needConfirm:            boolean,
  images:                 string[] | undefined,
  loading:                boolean,
  nullItem:               Partial<IItem>,

  //input validation
  inputChanged:           boolean,
  categoryChanged:        boolean,
  inputErrorTitle:        boolean,
  inputErrorDescription:  boolean,
  inputErrorCategory:     boolean,
  inputErrorStartingBid:  boolean,
  inputErrorBidIncrement: boolean,
  inputErrorShippingCost: boolean,
  saveInputError:         string,
}

class CreateUpdateItem extends React.Component<IProps, IState>{

  private titleRef = React.createRef<HTMLInputElement>();
  private descriptionRef = React.createRef<HTMLInputElement>();
  private startingBidRef = React.createRef<HTMLInputElement>();
  private bidIncrementRef = React.createRef<HTMLInputElement>();
  private buyNowPriceRef = React.createRef<HTMLInputElement>();
  private shippingCostRef = React.createRef<HTMLInputElement>();


  constructor(props: IProps) {
    super(props);

    const { context, itemID, categories } = this.props;

    const nullItem: Partial<IItem> = {
      isBuyNow: false,
      title: '',
      description: '',
      shippingType: 'No Shipping',
      shippingCost: undefined,
    };

    const item = itemID !== null ? context.itemStore[itemID].item : nullItem;


    this.state = {
      category: item.categoryID, 
      shippingType: item.shippingType,
      shippingCost: item.shippingCost,
      needConfirm: false,
      startingBid: item.startingBid,
      bidIncrement: item.bidIncrement,
      buyNowPrice: item.buyNowPrice,
      images: item.pictures,
      loading: false,
      nullItem: nullItem,

      //input validation 
      //if creating item, all input initially empty - error
      inputChanged: itemID ? false : true,
      categoryChanged: false,
      inputErrorTitle: itemID ? false : true,
      inputErrorDescription: itemID ? false : true,
      inputErrorCategory: itemID ? false : true,
      inputErrorStartingBid: itemID ? false : true,
      inputErrorBidIncrement: itemID ? false : true,
      inputErrorShippingCost: false,
      saveInputError: '',
    };

    if(item.categoryID){
      this.setState({
        category: (categories)[item.categoryID],
      });
    }
    
    // Binding members 
    this.cancelCheckIfEdit = this.cancelCheckIfEdit.bind(this);
    this.checkAllFields = this.checkAllFields.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.saveUpdatedItem = this.saveUpdatedItem.bind(this);
  }

  private checkAllFields() {
    let valid = true;

    if (this.state.inputChanged) {
      if (this.state.inputErrorTitle) valid = false;
      else if (this.state.inputErrorDescription) valid = false;
      else if (this.state.inputErrorCategory) valid = false;
      else if (this.state.inputErrorStartingBid) valid = false;
      else if (this.state.inputErrorBidIncrement) valid = false;
      else if (this.state.inputErrorShippingCost) valid = false;
      else if (!this.props.itemID && !this.state.categoryChanged) valid = false;
      else if (!this.state.images || this.state.images.length === 0) valid = false;
    }
    if (!valid) this.setState({ saveInputError: 'All fields must be filled out' });
    else if (valid && this.state.inputChanged) {
      this.setState({ saveInputError: '' });
      this.saveUpdatedItem();
    }
  }

  private saveUpdatedItem() {
    const { context, categories, itemID, auction, onCreate, onEdit } = this.props;
    const { category, images, startingBid, bidIncrement, shippingCost, shippingType } = this.state;
    const item = itemID !== null ? this.props.context.itemStore[itemID].item : this.state.nullItem;
    let buyNowPrice = this.state.buyNowPrice;
    let shipping = this.state.shippingCost;
    let buyNow;

    this.setState({loading: true});

    if(buyNowPrice) buyNow = true;
    else {
      buyNowPrice = undefined;
      buyNow = false;
    }

    if(shippingType === 'No Shipping') shipping = -1;

    const i: IItem = {
      auctionID: auction._id,
      isSold: false,
      categoryID: category,
      category: categories[category as string],
      title: this.titleRef.current ? this.titleRef.current.value : item.title,
      description: this.descriptionRef.current ? this.descriptionRef.current.value : item.description,
      pictures: images,
      startingBid: startingBid,
      bidIncrement: bidIncrement,
      isBuyNow: buyNow,
      buyNowPrice: (buyNowPrice as any),
      shippingType,
      shippingCost: shipping,
      isClosed: false,
    } as any;

    
    if(!itemID) {
      context.actions.addItem(auction._id, i)
        .then((res: IItem) => {
          this.setState({ 
            loading: false,
            saveInputError: '', 
          });
          onCreate(res);
        })
        .catch((err: any) => {
          console.log(err);
          const message = (err.message as string).includes('not already started')
            ? 'Cannot add items of a started auction'
            : 'Server Error';
          this.setState({ 
            loading: false,
            saveInputError: message, 
          });
        });
    } 
    else{
      context.actions.updateItem(auction._id, itemID, i)
        .then(() => { 
          this.setState({ 
            loading: false,
            saveInputError: '', 
          }); 
          onEdit(); 
        })
        .catch((err: any) => {
          console.log(err);
          if ((err.message as string).includes('not already started')) {
            this.setState({ 
              loading: false,
              saveInputError: 'Cannot update items of a started auction', 
            });
          }
        });
    } 
    this.setState({
      saveInputError: '',
    });
  }

  private cancelCheckIfEdit() {
    if (this.state.inputChanged) {
      this.setState({ needConfirm: true });
    }
    else{
      this.props.onCancel();
    } 
  }

  private handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({
      category: event.target.value as string,
    });
    if (!this.state.categoryChanged) this.setState({ categoryChanged: true, inputErrorCategory: false });
    if (!this.state.inputChanged) this.setState({ inputChanged: true });
  };

  
  private inputChange(ref: React.RefObject<HTMLInputElement>, callback: (state: boolean) => void) {

    if (!ref.current?.value) callback(true);
    else { //check if white space
      if (ref.current.value.trim().length === 0) callback(true);
      else callback(false);
    }
    if (!this.state.inputChanged) this.setState({ inputChanged: true });
  }


  render() {
    const { classes, itemID, categories, auction, onCancel } = this.props;
    const { category, needConfirm, startingBid, bidIncrement, buyNowPrice, shippingCost } = this.state;
    const item = itemID !== null ? this.props.context.itemStore[itemID].item : this.state.nullItem;


    return ( 
      <>
        {/* UserType: Admin, Future Auction - Confirm Item Deletion */}
        <Dialog open={needConfirm} >
          <DialogTitle>Are you sure you want to cancel? No changes will be saved.</DialogTitle>
          <DialogActions>
            <Button onClick={() => {this.setState({needConfirm: false, inputChanged: false, startingBid: NaN, buyNowPrice: NaN}); onCancel();}} color="primary">Yes</Button>
            <Button onClick={() => this.setState({needConfirm: false})} color="primary">No, Continue Editing</Button>
          </DialogActions>
        </Dialog>


        <Grid container>
          <Grid item xs={12} >
            <Button className={classes.button} onClick={this.cancelCheckIfEdit}>Cancel</Button> 
            <Button className={classes.button} disabled={!this.state.inputChanged} onClick={this.checkAllFields}> Save</Button> 
            {this.state.loading &&
              <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
                <CircularProgress size='2.5rem' style={{ margin: 'auto' }} />
              </div>
            }
          </Grid>

          <Grid item xs={12}>
            {this.state.saveInputError.length > 0 &&
              <p className={classes.errorMess}>{this.state.saveInputError}</p>
            }
          </Grid>
        </Grid>


        <Grid container>

          <Grid item xs={12} lg={6} className={classes.grid} >
            <Images 
              itemImages={item.pictures} 
              altName={item.title}
              isUpdate={true}
              onChange={() => { if(!this.state.inputChanged) this.setState({inputChanged: true}); }}
              sendImages={(images: string[]) => this.setState({images: images}) }
            />
          </Grid>

          <Grid item xs={12} lg={6} className={classes.grid} >

            <Grid container direction='column'>

              <Grid item className={classes.gridElement}>
                <TextField
                  label="Title"
                  variant='outlined'
                  error={this.state.inputErrorTitle}
                  className={classes.textInput}
                  multiline rows={2}
                  inputRef={this.titleRef}
                  defaultValue={item.title}
                  onChange={() => this.inputChange(this.titleRef, (state: boolean): void => { this.setState({ inputErrorTitle: state }); })}
                />
              </Grid>


              <Grid item className={classes.gridElement}>
                <FormControl variant="outlined" className={classes.textInput} >
                  <InputLabel>Category</InputLabel> 
                  <Select
                    native
                    label='Category'
                    error={this.state.inputErrorCategory}
                    value={category}
                    onChange={this.handleCategoryChange}
                  >
                    {itemID === null && 
                      <option value={undefined} disabled selected> Select an option </option>
                    }
                    {Object.keys(categories).map(index =>
                      <option key={index} style={{ cursor: 'pointer' }} value={index}>{categories[index]}</option>, 
                    )}
                  </Select>
                </FormControl>
              </Grid>


              <Grid item className={classes.gridElement}>
                <TextField
                  label="Description"
                  variant='outlined'
                  error={this.state.inputErrorDescription}
                  className={classes.textInput}
                  inputRef={this.descriptionRef}
                  defaultValue={item.description}
                  multiline rows={6}
                  onChange={() => this.inputChange(this.descriptionRef,  (state: boolean): void => {this.setState({inputErrorDescription: state});})}
              /></Grid>


              <Grid item className={classes.gridElement}>
                <TextField
                  variant='outlined'
                  label="Starting Bid"
                  error={this.state.inputErrorStartingBid}
                  className={classes.textInput}
                  inputRef={this.startingBidRef}
                  value={startingBid}
                  InputProps={{ inputComponent: NumberFormatCustom as any }}
                  onChange={value => { 
                    this.setState({startingBid: parseInt(value.target?.value)}); 
                    this.inputChange(this.startingBidRef,  (state: boolean): void => {this.setState({inputErrorStartingBid: state});}); 
                  }}
              /></Grid>

              <Grid item className={classes.gridElement}>
                <TextField
                  variant='outlined'
                  label="Bid Increment"
                  error={this.state.inputErrorBidIncrement}
                  className={classes.textInput}
                  inputRef={this.bidIncrementRef}
                  value={bidIncrement}
                  InputProps={{ inputComponent: NumberFormatCustom as any }}
                  onChange={value => { 
                    this.setState({bidIncrement: parseInt(value.target?.value)}); 
                    this.inputChange(this.bidIncrementRef,  (state: boolean): void => {this.setState({inputErrorBidIncrement: state});}); 
                  }}
              /></Grid>

              {auction.type !== 'liveAndOnline' &&
                <Grid item className={classes.gridElement}>
                  <TextField
                    variant='outlined'
                    label="Buy Now Price (Optional)"
                    className={classes.textInput}
                    inputRef={this.buyNowPriceRef}
                    value={buyNowPrice}
                    InputProps={{ inputComponent: NumberFormatCustom as any }}
                    onChange={value => { 
                      this.setState({buyNowPrice: parseInt(value.target?.value)}); 
                      this.inputChange(this.buyNowPriceRef,  (state: boolean): void => {});
                    }}
                /></Grid>
              }

              <Grid item className={classes.gridElement}>
                <FormControl variant="outlined" className={classes.textInput} >
                  <InputLabel>Shipping Type</InputLabel> 
                  <Select
                    native
                    label='Shipping Type'
                    value={this.state.shippingType}
                    onChange={event => {
                      this.setState({
                        inputChanged: true,
                        shippingType: event.target.value as ShippingType,
                        inputErrorShippingCost: (event.target.value as ShippingType) !== 'No Shipping' && !shippingCost
                          ? true : false,
                      });

                    }}
                  >
                    {SHIPPING_TYPE.map(type => 
                       <option key={type} style={{ cursor: 'pointer' }} value={type}>{type}</option>, 
                    )}
                  </Select>
                </FormControl>
              </Grid>

              {this.state.shippingType !== 'No Shipping' &&
                <Grid item className={classes.gridElement}>
                  <TextField
                    variant='outlined'
                    label="Shipping Cost"
                    error={this.state.inputErrorShippingCost}
                    className={classes.textInput}
                    inputRef={this.shippingCostRef}
                    value={shippingCost}
                    InputProps={{ inputComponent: NumberFormatCustomDecimal as any }}
                    onChange={value => { 
                      this.setState({shippingCost: parseFloat(value.target?.value)}); 
                      this.inputChange(this.shippingCostRef,  (state: boolean): void => {this.setState({inputErrorShippingCost: state});}); 
                    }}
                /></Grid>
              }

            </Grid>

          </Grid>

        </Grid>
      </>
    );
  }
}

// Export with Local Styles
export default withAppContext(RootContext, withStyles(styles)(CreateUpdateItem));
