import React, { useContext } from 'react';

import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow, TableCell, TableBody, TextField, Button, CircularProgress, Grid, Typography
} from '@material-ui/core';
import { IUser } from '../../Interfaces/Database/User';
import { RootContext } from '../../Store/RootStore';
import { Service } from '@feathersjs/feathers';
import { QueryResult } from '../../Interfaces/Service';
import Paginate from '../../Components/Paginate';
import CopyEmails from './CopyEmails';
import { Star as StarIcon } from '@material-ui/icons';



export default function Customers(){
  const store = useContext(RootContext);
  const usersPerPage = 15;

  const {client, user, authToken} = store;

  const [totalUsers, setTotalUsers] = React.useState<number>(50); 
  const [users, setUsers] = React.useState<IUser[]>([]); 
  const [page, setPage] = React.useState(0);

  const [firstNameSearch, setFirstNameSearch] = React.useState<string>();
  const [lastNameSearch, setLastNameSearch] = React.useState<string>();
  const [bidderNumberSearch, setbidderNumberSearch] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const userService: Service<IUser> = client?.service('users');

  React.useEffect(() => {
    if(!user) return;
    getTotalUsers();
  }, [ user ]);

  const getTotalUsers = () => {
    userService.find({
      Authorization: authToken,
      user: user || undefined,
      query: {
        $limit: 0,
        isStaff: false
      }
    })
      .then((res: any) => {
        setTotalUsers((res as QueryResult<IUser>).total);
        getNextPage(0);
      });
  };

  const getNextPage = (newPage: number) => {
    setIsLoading(true);
    userService.find({
      Authorization: authToken,
      user: user || undefined,
      query: {
        $limit: usersPerPage,
        $skip: newPage * usersPerPage,
        isStaff: false,
        $sort: { lastName: 1 }
      }
    })
    .then((res: any) => res.data)
    .then((data: IUser[]) => {
      setUsers(data);
    })
    .finally(() => setIsLoading(false));
  };

  const changePage = (data: any) => {
    const newPage = data.selected;
    setPage(newPage);
    getNextPage(newPage);
  };

  const clearFilters = () => {
    setFirstNameSearch('');
    setLastNameSearch('');
    setbidderNumberSearch('');
    getTotalUsers();
  };

  const search = () => {
    let query = {
      isStaff: false,
      $limit: 50
    } as any;
    if(firstNameSearch){
      query = {
        ...query,
        firstNameSearch: firstNameSearch.trim().toLocaleLowerCase()
      }
    }
    if(lastNameSearch){
      query = {
        ...query,
        lastNameSearch: lastNameSearch.trim().toLocaleLowerCase()
      }
    }
    if(bidderNumberSearch){
      query = {
        ...query,
        bidderNumber: parseInt(bidderNumberSearch)
      }
    }
    setIsLoading(true);
    client?.service('users').find({
      headers: { Authorization: authToken },
      user: user,
      query
    })
      .then((res: any) => res.data)
      .then((users: IUser[]) => {
        setUsers(users);
        setTotalUsers(users.length);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Paper style={{ minHeight: '500px' }}>
       <Grid container direction={'row'} style={{ padding: '5px' }}>
        <TextField
          value={firstNameSearch}
          style={{ display: 'block', margin: '5px' }}
          id="first-name"  label={'First Name'} variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e: any) => setFirstNameSearch(e.target.value)}
        />
        <TextField
          value={lastNameSearch}
          style={{ display: 'block', margin: '5px' }}
          id="last-name" label={'Last Name'} variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e: any) => setLastNameSearch(e.target.value)}
        />
        <TextField 
          value={bidderNumberSearch}
          style={{ display: 'block', margin: '5px' }}
          id="bidder-number" label={'Bidder Number'} variant="outlined"
          InputLabelProps={{ shrink: true }}
          onChange={(e: any) => setbidderNumberSearch(e.target.value)}
        />
        <Button variant="contained" onClick={search} disabled={isLoading} style={{ margin: '5px', display: 'inline-block' }}>
          Search
        </Button>
        <Button variant='outlined' onClick={clearFilters} style={{ margin: '5px', display: 'inline-block' }}>
          Clear
        </Button>
      </Grid>
      {isLoading
        ? <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
            <CircularProgress size='2.5rem'/>
          </div>
        : users.length > 0 
          ? <>
              <CopyEmails isStaff={false} totalUsers={totalUsers}/>
              <TableContainer >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell><strong>Bidder #</strong></TableCell>
                      <TableCell><strong>Customer</strong></TableCell>
                      <TableCell><strong>Contact</strong></TableCell>
                      <TableCell><strong>Address</strong></TableCell>
                      <TableCell><strong>Created</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  
                  <TableBody>
                    {users
                      .map(user => (
                        <TableRow key={user._id}>
                          <TableCell scope='row' component='th'>{user.bidderNumber}</TableCell>
                          <TableCell scope='row' component='th'>
                            <span  style={{ display: 'flex', alignItems: 'center' }}>
                              {user.permissions && user.permissions.includes('admin') && 
                                <StarIcon color='primary'/>
                              }
                              {user.firstName} {user.lastName}
                            </span>
                          </TableCell>
                          <TableCell>{user.email}<br/>{user.phoneNumber}</TableCell>
                          <TableCell>{user.address}</TableCell>
                          <TableCell>{new Date((user as any).createdAt).toLocaleDateString()}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>

                <Paginate
                  totalElements={totalUsers}
                  elementsPerPage={usersPerPage}
                  currentPage={page}
                  onPageChange={changePage}
                />

              </TableContainer>
            </>
          : <Typography color='error' style={{ margin: '10px' }}>* User does not exist</Typography>
        }
      
    </Paper>
  );
}

