import React, { useContext } from 'react';
import {
  Table, TableBody, TableCell, 
  TableHead, TableRow,
  Typography, Box, Collapse,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import { PurchaseItemPkg } from '../../Interfaces/Database/Invoice';
import { RootContext } from '../../Store/RootStore';
import { ServiceMethods } from '@feathersjs/feathers';
import { IBid } from '../../Interfaces/Database/Bid';
import { IUser } from '../../Interfaces/Database/User';
import { IItem } from '../../Interfaces/Database/Item';
import { IPurchase } from '../../Interfaces/Database/Purchase';
import { BidWithContact } from '../../Store/Reducers/ReportReducer';
import { queryItemBidders } from '../../Store/Actions';
import { updatePurchaseData } from '../../Store/Actions/Report';


interface Props {
  items: PurchaseItemPkg[],
  auctionID: string,
  children?: JSX.Element[],
  nextBidderOption: boolean,
  onUpdate?: () => void,
}

export default function CollapsableSection ({ items: item, auctionID, children, nextBidderOption }: Props): JSX.Element {

  const { actions } = useContext(RootContext);

  // States
  const [expandOpen, setExpandOpen] = React.useState<boolean>(true);
  const [dialog, setDialog] = React.useState<{open: boolean, item: (IItem | null)}>({open: false, item: null});
  const [nextBidders, setNextBidders] = React.useState<BidWithContact[]>([]);
  const [confirm, setConfirm] = React.useState<{open: boolean, type: string, index: number}>({ open: false, type: '', index: -1 });

  
  const retrieveBidders = (item: IItem) => {

    actions.queryItemBidders(item._id)
      .then((res: any) =>{
        setNextBidders(res);
        setDialog({open: true, item: item});
      })
      .catch(err => console.log(err));
  };

  const handleAction = () => {
    if(!dialog.item) return;
    let data: updatePurchaseData;

    // Update Item Purchase
    if(confirm.type === 'next'){
      data = {
        action: 'NEXT_BID',
        payload: {
          auctionID,
          data: nextBidders[confirm.index].bid as IBid,
        }, 
      };
    }

    // Mark Item as "Not Sold"
    else{
      data = {
        action: 'NOT_SOLD',
        payload: {
          auctionID,
          data: dialog.item._id,
        }, 
      };
    }

    actions.updatePurchase(data as any)
      .then(() => {
        setConfirm({open: false, type: '', index: -1});
        setDialog({open: false, item: null});
      })
      .catch(err => console.log(err));
  };

  return (<>

    {nextBidderOption && <>
      {/* Confirm New Bidder Attachment */}
      <Dialog open={confirm.open} onClose={() => setConfirm({open: false, type: '', index: -1})}>
        <DialogTitle id="alert-dialog-title">{confirm.type === 'next' 
        ? 'Upon confirmation, the item will be removed from current winning bidder\'s purchases and added to the selected bidder.'
        : 'Are you sure you want to mark the item as "Not Sold"?'
      }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirm({open: false, type: '', index: -1})} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAction} color="primary" autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>

      {/* View All Item Bidders */}
      <Dialog open={dialog.open} onClose={() => setDialog({open: false, item: null})}>
        <DialogTitle> Bidders for Item #{dialog.item?.itemNumber}: {dialog.item?.title}</DialogTitle>
        <button 
          style={{alignSelf: 'flex-end', width: '100px', marginRight: '30px'}}
          onClick={() => setConfirm({open: true, type: 'notSold', index: -1})}
        >Mark Item As Not Sold</button>
        <DialogContent>
          {nextBidders.length 
            ?
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Customer</strong></TableCell>
                    <TableCell><strong>Contact</strong></TableCell>
                    <TableCell><strong>Bid Price</strong></TableCell>
                    <TableCell><strong>Actions</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {nextBidders.map((elt, index) => (
                    <TableRow key={elt.user._id}>
                      <TableCell>{elt.user.firstName} {elt.user.lastName}</TableCell>
                      <TableCell>{elt.user.email}<br/>{elt.user.phoneNumber}</TableCell>
                      {elt.bid
                        ? <>
                          <TableCell>${elt.bid.price}</TableCell>
                          <TableCell>{elt.purchase
                            ? 'Current Bidder' 
                            : <button onClick={() => setConfirm({ open: true, type: 'next', index})}>Assign New Bidder</button>}
                          </TableCell></>
                        : <>
                          <TableCell>${elt.purchase?.itemCost}</TableCell>
                          <TableCell>Current Purchaser (BIN)</TableCell></>
                      }
                    </TableRow>
                  ))}

                </TableBody>
              </Table>
            : <Typography>None</Typography>
          }
          
        </DialogContent>
      </Dialog>
    </>}


    <TableRow hover style={{backgroundColor: '#f2f2f2'}}>
      <TableCell>
        <IconButton aria-label="expand row" size="small" onClick={() => setExpandOpen(!expandOpen)}>
          {expandOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </TableCell>
      {children?.map(elt => elt)}
    </TableRow>

    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={expandOpen} timeout="auto" unmountOnExit>
          <Box style={{width: '80%', float: 'right'}}>
            <Typography variant="h6" gutterBottom component="div">
              Items
            </Typography>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell style={{width: '100px'}}><strong>Item #</strong></TableCell>
                  <TableCell ><strong>Title</strong></TableCell>
                  <TableCell style={{width: '100px'}}><strong>Price</strong></TableCell>
                  <TableCell style={{width: '200px'}}><strong>Delivery</strong></TableCell>
                  {nextBidderOption &&
                    <TableCell style={{width: '120px'}}><strong>All Bidders</strong></TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {item.map(elt => (
                  <TableRow key={elt._id}>
                    <TableCell style={{width: '100px'}} component='th' scope='row'>{elt.item.itemNumber}</TableCell>
                    <TableCell component='th' scope='row'>{elt.item.title}</TableCell>
                    <TableCell style={{width: '100px'}}>${elt.itemCost}</TableCell>
                    <TableCell style={{width: '200px'}}> 
                      {elt.item.shippingCost > 0
                        ? `$${elt.item.shippingCost.toFixed(2)} ${elt.item.shippingType}`
                        : elt.item.shippingCost == 0 
                          ? 'Free'
                          : 'Pickup Only'
                      }
                    </TableCell>
                    {nextBidderOption &&
                      <TableCell style={{width: '100px'}} onClick={() => retrieveBidders(elt.item)}><button>View</button></TableCell>
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </>);
}