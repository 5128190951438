import React, { useContext } from 'react';
import {
  makeStyles, Grid, Typography,
} from '@material-ui/core';
import { RootContext } from '../../Store/RootStore';
import Chart from 'chart.js/auto';


const color = { // 0: full color, 1: Opacity
  auctionItems: ['rgba(0, 204, 0, 1)', 'rgba(0, 204, 0, 0.2)'], // green
  serviceCharge: ['rgba(255, 206, 86, 1)', 'rgba(255, 206, 86, 0.2)'], // yellow
  shipping:  ['rgba(255, 0, 0, 1)', 'rgba(255, 0, 0, 0.2)'], // red
  bid:  ['rgba(153, 102, 255, 1)', 'rgba(153, 102, 255, 0.2)'], // purple
  buyItNow: ['rgba(75, 192, 192, 1)', 'rgba(75, 192, 192, 0.2)'], // teal
  shipped: ['rgba(0, 153, 255, 1)', 'rgba(0, 153, 255, 0.2)'], // blue
  pickedup: ['rgba(255, 102, 204, 1)', 'rgba(255, 102, 204, 0.2)'], // pink
  pending: ['rgba(255, 153, 0, 1)', 'rgba(255, 153, 0, 0.2)'], // orange
  participants: ['rgba(255, 102, 204, 1)', 'rgba(255, 102, 204, 0.2)'], // pink
  purchasers: ['rgba(255, 153, 0, 1)', 'rgba(255, 153, 0, 0.2)'], // orange
};


const useStyles = makeStyles({
  root: {
    width: '100%',
    maxHeight: 'max-content',
  },
  container: {
    backgroundColor: 'white',
    borderRadius: '20px',
    padding: '20px',
    margin: '20px',
  },
  titleText: {
    fontWeight: 'bold',
    margin: 'auto',
    marginBottom: '10px',
  },
});

interface ITotalsReportRes {
  bidders: number,
  purchasers: number, 
  itemPurchasesTotal: number,
  itemShippingTotal: number,
  serveChargeTotal: number,
  buyItNowTotal: number,
  bidTotal: number,
  buyItNowItemCount: number,
  bidItemCount: number,
  shipItemCount: number,
  pickupItemCount: number,
  pendingItemCount: number,
}

interface IProps{
  auctionID: string,
}


export default function Report({ auctionID }: IProps) {
  const classes = useStyles();
  const { client, authToken, user } = useContext(RootContext);
  const [totals, setTotals] = React.useState<ITotalsReportRes>();

  React.useEffect(() => {
    if(!client) return;
    client.service('totals-report').get(auctionID, {
      headers: { Authorization: authToken },
      user,
    }) 
      .then((res: ITotalsReportRes) => {
        setTotals(res);

        const doughnutCtx: any = document.getElementById('doughnut-chart');
        new Chart(doughnutCtx, {
          type: 'pie',
          data: {
            datasets: [ {
                data: [res.itemPurchasesTotal, res.itemShippingTotal, res.serveChargeTotal],
                backgroundColor: [color.auctionItems[1], color.shipping[1], color.serviceCharge[1]],
                borderColor: [color.auctionItems[0], color.shipping[0], color.serviceCharge[0]],
                borderWidth: 1,
            } ],
          },
        });
    
        const barCtx: any = document.getElementById('bin-bid-dollar-count');
        new Chart(barCtx, {
          type: 'bar',
          data: {
              labels: [ 'bin-bid-dollar-count' ],
              datasets: [
                {
                  data: [ res.buyItNowTotal ],
                  backgroundColor: [ color.buyItNow[1] ],
                  borderColor: [ color.buyItNow[0] ],
                  borderWidth: 1,
                },
                {
                  data: [ res.bidTotal ],
                  backgroundColor: [ color.bid[1] ],
                  borderColor: [ color.bid[0] ],
                  borderWidth: 1,
                },
              ],
          },
          options: { plugins: { legend: { display: false }}},
        });
    
        const barCtxt: any = document.getElementById('bin-bid-item-count');
        new Chart(barCtxt, {
          type: 'bar',
          data: {
              labels: [ 'bin-bid-item-count' ],
              datasets: [
                {
                  data: [ res.buyItNowItemCount ],
                  backgroundColor: [ color.buyItNow[1] ],
                  borderColor: [ color.buyItNow[0] ],
                  borderWidth: 1,
                },
                {
                  data: [ res.bidItemCount ],
                  backgroundColor: [ color.bid[1] ],
                  borderColor: [ color.bid[0] ],
                  borderWidth: 1,
                },
              ],
          },
          options: { plugins: { legend: { display: false }}},
        });
    
        const barCtxtt: any = document.getElementById('ship-pickup-item-count');
        new Chart(barCtxtt, {
          type: 'bar',
          data: {
              labels: [ 'ship-pickup-item-count' ],
              datasets: [
                {
                  data: [ res.shipItemCount ],
                  backgroundColor: [ color.shipped[1] ],
                  borderColor: [ color.shipped[0] ],
                  borderWidth: 1,
                },
                {
                  data: [ res.pickupItemCount ],
                  backgroundColor: [ color.pickedup[1] ],
                  borderColor: [ color.pickedup[0] ],
                  borderWidth: 1,
                },
                {
                  data: [ res.pendingItemCount ],
                  backgroundColor: [ color.pending[1] ],
                  borderColor: [ color.pending[0] ],
                  borderWidth: 1,
                },
              ],
          },
          options: { plugins: { legend: { display: false }}},
        });
      });
  
  }, []);

  const labels = (label: string, value: string, c: string) => {
    return (
      <div style={{ 
        backgroundColor: c, 
        margin: '5px',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px 10px',
      }}>
        <Typography>{label}</Typography>
        <Typography>{value}</Typography>
      </div>
    );
  };
  
  return (
    <>
      {totals && 
        <Grid container className={classes.root} spacing={4}>
          
          <Grid item xs={12} md={4}>
            <div className={classes.container}>
              <Typography className={classes.titleText}>Participants</Typography>
              {labels('Bidders: ', totals.bidders.toString(), color.participants[1])}
              {labels('Purchasers: ', totals.purchasers.toString(), color.purchasers[1])}
            </div>

            <div className={classes.container}>
              <Typography className={classes.titleText}>Totals</Typography>
              {labels('Auction Items: ', `$ ${totals.itemPurchasesTotal.toFixed(2)}`, color.auctionItems[1])}
              {labels('Service Charge: ', `$ ${totals.serveChargeTotal.toFixed(2)}`, color.serviceCharge[1])}
              {labels('Shipping: ', `$${totals.itemShippingTotal.toFixed(2)}`, color.shipping[1])}
              <canvas id='doughnut-chart'></canvas>
            </div>
          </Grid>

          <Grid item xs={12} md={8}>
            <div className={classes.container}>
              <Typography className={classes.titleText}>Purchase Type by Income</Typography>
              {labels('Buy It Now: ', `$ ${totals.buyItNowTotal.toFixed(2)}`, color.buyItNow[1])}
              {labels('Bid: ', `$ ${totals.bidTotal.toFixed(2)}`, color.bid[1])}
              <canvas id='bin-bid-dollar-count'></canvas>
            </div>
            <div className={classes.container}>
              <Typography className={classes.titleText}>Purchase Type by Units</Typography>
              {labels('Buy It Now: ', totals.buyItNowItemCount.toString(), color.buyItNow[1])}
              {labels('Bid: ', totals.bidItemCount.toString(), color.bid[1])}
              <canvas id='bin-bid-item-count'></canvas>
            </div>
            <div className={classes.container}>
              <Typography className={classes.titleText}>Delivery Type by Units</Typography>
              {labels('Shipping: ', totals.shipItemCount.toString(), color.shipped[1])}
              {labels('Pickup: ', totals.pickupItemCount.toString(), color.pickedup[1])}
              {labels('Pending: ', totals.pendingItemCount.toString(), color.pending[1])}
              <canvas id='ship-pickup-item-count'></canvas>
            </div>
          </Grid>

        </Grid>
      }
    </>
  );
}