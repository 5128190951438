import { IRootStore } from '../RootStore';
import { StateSetter } from './Interfaces';
import { IAuction } from '../../Interfaces/Database/Auction';
import { ISlideShowData } from '../Reducers/AuctionReducer';

export const setSlideshowItems = async (state: IRootStore, setState: StateSetter): Promise<any> => {
  // Add Bid to Server
  const { client, user, authToken } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;


  if (!client) return Promise.reject(null);

  if(state.slideshowItems.length !== 0){
    return;
  }
  
  // Grab 10 Items from Last Auction
  return client.service('auctions').find({
    headers,
    user: user || undefined,
    query: {
      $limit: 3,
      $sort: { createdAt: -1 }, 
      state: { $gte: 3 }, //  closed or past auctions
    },
  })
    .then((res: any) => res.data)
    .then(async (auctions: IAuction[]) => 
      Promise.all(auctions.map(async auction => {
        const items = await client.service('items').find({
          headers,
          user: user || undefined,
          query: {
            auctionID: auction._id,
            $limit: 4,
          },
        }).then((res: any) => res.data);
        return {
          auction,
          featuredItems: items,
        };
      })),
    )
    .then((data: ISlideShowData[]) => {
      //Update State
      setState((previousState: IRootStore) => {
        return {
          ...previousState,
          slideshowItems: data,
        };
      });
    });
};

