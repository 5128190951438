import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { AppBar, CircularProgress, Container, Dialog, DialogActions, DialogTitle, IconButton, Toolbar } from '@material-ui/core';
import AuctionTypeSelector from './AuctionTypeSelector';
import { AuctionTypes, IAuction, PreBiddingViewOptionTypes } from '../../Interfaces/Database/Auction';
import CharityInfoInput from './CharityInfoInput';
import BasicInfoInput from './BasicInfoInput';
import AuctionSettingsInput from './AuctionSettingsInput';
import CloseIcon from '@material-ui/icons/Close';
import { colors } from '../../Config/Global';
import { useHistory } from 'react-router-dom';
import { IUser } from '../../Interfaces/Database/User';
export interface IAuctionBasicInfo {
  name: string,
  startDate:              Date,
  endDate?:               Date,
  liveLocation?:          string,
  pickupInformation:      string,
  additionalInformation?: string,
  liveStreamLink?:        string,
}

export interface IAuctionSettings {
  state:                    number,
  preBiddingEnabled:        boolean,
  preBiddingViewingOption?: PreBiddingViewOptionTypes,
  preBiddingStart?:         number,
  preBiddingEnd?:           number
}

export interface IAuctionCharityInfo {
  charityName?:           string, 
  charityDescription?:    string,
  charityImage?:          string,
}


export interface IAuctionStaff {
  admin: string[],
  clerk: string[]
}

export interface IUserMap {
  [email: string]: IUser
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative'
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    root: {
      width: '100%',
    },
    bottomButtons: {
      float: 'right'
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    content: {
      height: '100%',
      overflowY: 'scroll'
    },
    footer: {
      padding: '1rem',
      backgroundColor: colors.navBarSecondary6up,
    },
    errorText: {
      float: 'left', 
      marginTop: '5px', 
      marginRight: '10px', 
      fontStyle: 'italic'
    },
    stepper: {
      "& .MuiStepIcon-active": { 
        boxShadow : `0 0 0.25em 0.25em #b5c0ff`,
        borderRadius: '50%'
      },
      "& .MuiStepLabel-active": { 
        fontWeight: 'bold',
        //fontSize: '17px',
        color: colors.primaryButton
      },
      "& .MuiStepIcon-completed": { 
        color: colors.successGreen,
      },
      "& .MuiStepLabel-completed": { 
        color: colors.successGreen
      },
    }
  }),
);

interface IProps {
  auction: IAuction | null,
  isOpen: boolean
  onClose: () => void,
  onUpdateOrAdd: (id: string | null, auction: Partial<IAuction>, history: History) => Promise<any>,
  onDelete?: (id: string, history: History) => void,
}

export default function CreateAuctionStepper({ auction, isOpen, onClose, onUpdateOrAdd, onDelete }: IProps) {
  const classes = useStyles();
  const history = useHistory();

  const hoursBeforeStartDate = (start: Date, prior: Date | undefined): number | undefined => {
    if(prior){
      const diff = new Date(start).valueOf() - new Date(prior).valueOf();
      return diff/1000/60/60; 
    } else {
      return undefined;
    }
  };

  const readOnly = React.useMemo(() => auction ? auction.state > 2 : false, [ auction ]);

  const initialBasicInfo: IAuctionBasicInfo = React.useMemo(() => {
    const startDate = new Date();
    const endDate = new Date();
    return {
    name: auction ? auction.name : '',
    startDate: auction ? auction.startDate : new Date(startDate.setDate(startDate.getDate() + 7)),
    endDate: auction ? auction.endDate : new Date(endDate.setDate(endDate.getDate() + 14)),
    pickupInformation: auction ? auction.pickupInformation : '',
    liveLocation: auction ? auction.liveLocation : '',
    additionalInformation: auction ? auction.additionalInformation : undefined,
    liveStreamLink: auction ? auction.liveStreamLink : undefined
  }}, [ auction ]);

  const initialCharity: IAuctionCharityInfo = React.useMemo(() => ({
    charityName: auction ? auction.charityName : undefined,
    charityDescription: auction ? auction.charityDescription : undefined,
    charityImage: auction ? auction.charityImage : undefined
  }), [ auction ])

  const initialSettings: IAuctionSettings = React.useMemo(() => ({
    state: auction ? auction.state : 0,
    preBiddingEnabled: auction ? auction.preBiddingEnabled : false,
    preBiddingStart: auction ? hoursBeforeStartDate(auction.startDate, auction.preBiddingStart as any) : undefined,
    preBiddingEnd: auction ? hoursBeforeStartDate(auction.startDate, auction.preBiddingEnd as any) : undefined,
    preBiddingViewingOption: auction ? auction.preBiddingViewingOption : undefined
  }), [ auction ]);


  React.useEffect(() => {
    if(isOpen){
      setBasicInfo(initialBasicInfo);
      setSettings(initialSettings);
      setCharity(initialCharity);
      setActiveStep(0);
    }
  }, [ isOpen ]);


  const determineStepsDisplayed = (): string[] => {
    return generalSteps;
  };
  
  const generalSteps = ['Select auction type', 'Charity (Optional)', 'Basic information', 'Settings'];

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [needConfirmToDelete, setNeedConfirmToDelete] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [auctionType, setAuctionType] = React.useState<AuctionTypes>(auction ? auction.type : 'generalOnline');
  const [isTesting, setIsTesting] = React.useState<boolean>(auction && auction.isTesting ? auction.isTesting : false);

  const [basicInfo, setBasicInfo] = React.useState<IAuctionBasicInfo>(initialBasicInfo);
  const [settings, setSettings] = React.useState<IAuctionSettings>(initialSettings);
  const [charity, setCharity] = React.useState<IAuctionCharityInfo | null>(initialCharity);
  const [steps, setSteps] = React.useState<string[]>(determineStepsDisplayed());
  const [error, setError] = React.useState<string>('');
  const [isVerifyingInput, setIsVerifyingInput] = React.useState<boolean>(false);


  const handleVerify = () => {
    if(activeStep === 0) {
      setSteps(determineStepsDisplayed());
      handleNext();
    } else {
      setIsVerifyingInput(true);
    }
  }

  const createOrUpdateAuction = () => {
    setError('');
    let newAuctionData: Partial<IAuction> = {
      type: auctionType,
      name: basicInfo.name,
      pickupInformation: basicInfo.pickupInformation,
      additionalInformation: basicInfo.additionalInformation ? basicInfo.additionalInformation : undefined,
      state: settings.state,
      charityName: charity?.charityName ? charity.charityName : undefined,
      charityDescription: charity?.charityDescription ? charity.charityDescription : undefined,
      charityImage: charity?.charityImage ? charity.charityImage : undefined,
      startDate: basicInfo.startDate,
      isTesting
    };

    if(auctionType === 'generalOnline') {
      newAuctionData = {
        ...newAuctionData,
        endDate: basicInfo.endDate,
        preBiddingEnabled: false
      }
    } else if(auctionType === 'liveAndOnline') {
      newAuctionData = {
        ...newAuctionData,
        liveLocation: basicInfo.liveLocation,
        liveStreamLink: basicInfo.liveStreamLink ? basicInfo.liveStreamLink : undefined,
        preBiddingEnabled: settings.preBiddingEnabled,
        preBiddingStart: settings.preBiddingStart,
        preBiddingEnd: settings.preBiddingEnd,
        preBiddingViewingOption: settings.preBiddingViewingOption,
        preBiddingOpen: auction?.preBiddingOpen ? auction.preBiddingOpen : false
      }
    }

    onUpdateOrAdd(auction ? auction._id : null, newAuctionData, history as any)
    .then(async (res: IAuction) => {
      setIsLoading(false);
      close();
    })
    .catch(err => {
      console.log(err);
      setIsLoading(false);
      setError(err.message);
    }); 
  }

  const close = () => {
    setBasicInfo(initialBasicInfo);
    setSettings(initialSettings);
    setCharity(initialCharity);
    setActiveStep(0);
    setError('');
    onClose();
  };


  const handleNext = () => {
    setIsVerifyingInput(false);
    if (lastStep()) {
      if(readOnly){
        close();
      } else {
        setIsLoading(true);
        setTimeout(() => createOrUpdateAuction(), 1000);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setError('');
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleValidationError = (err: string) => {
    setIsVerifyingInput(false);
    setError(err);
  }

  const onVerify = () => {
    setError('');
    handleNext();
  }

  const onConfirmDelete = () => {
    setNeedConfirmToDelete(false); 
    if(onDelete && auction) {
      onDelete(auction._id, history as any);
      close();
    } else {
      console.log('error on deletion');
    }
  };

  const lastStep = () => {
    return activeStep === steps.length - 1;
  }

  return (
    <Dialog
      fullScreen
      open={isOpen}
    >
      {/* UserType: Admin, Future Auction - Confirm Item Deletion */}
      <Dialog open={needConfirmToDelete} >
        <DialogTitle id="alert-dialog-title">Please confirm the deletion of this auction. Warning: All items for this auction will also be deleted.</DialogTitle>
        <DialogActions>
          <Button onClick={() => setNeedConfirmToDelete(false)}>Cancel</Button>
          <Button onClick={onConfirmDelete} variant="contained" color="secondary"> Confirm</Button>
        </DialogActions>
      </Dialog>

      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={close}>
            <CloseIcon/>
          </IconButton>
          <Typography variant="h6">
            {auction ? `Update ${auction.name} Auction` : 'Create an Auction'}
          </Typography>
        </Toolbar>
      </AppBar>

      <div className={classes.content}>
        <Container maxWidth='lg' >
          <Stepper activeStep={activeStep} alternativeLabel className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {activeStep === 0 &&
            <AuctionTypeSelector
              readOnly={readOnly} 
              typeSelected={auctionType} 
              optionSelected={isTesting}
              onChangeType={setAuctionType}
              onChangeOption={setIsTesting}
            />
          }
          {activeStep === 1 &&
            <CharityInfoInput
              readOnly={readOnly}
              charityInfo={charity} 
              verifyInput={isVerifyingInput} 
              onError={handleValidationError} 
              onUpdate={setCharity} 
              onVerify={onVerify}
            />
          }
          {activeStep === 2 &&
            <BasicInfoInput
              readOnly={readOnly}
              auctionOpen={auction ? auction.state === 2 : false}
              auctionType={auctionType}
              basicInfo={basicInfo}
              verifyInput={isVerifyingInput} 
              onError={handleValidationError} 
              onUpdate={setBasicInfo} 
              onVerify={onVerify}
            />
          }
          {activeStep === 3 &&
            <AuctionSettingsInput
              readOnly={readOnly}
              auctionOpen={auction ? auction.state === 2 : false}
              auctionType={auctionType}
              settings={settings}
              verifyInput={isVerifyingInput} 
              onError={handleValidationError} 
              onUpdate={setSettings} 
              onVerify={onVerify}
            />
          }
        </Container>
      </div>
      
      <div className={classes.footer}>
          {/* Only Delete Future Auctions */}
          {onDelete && auction && auction.state < 2 &&
            <Button variant="contained" color="secondary" onClick={() => setNeedConfirmToDelete(true)}>Delete</Button>
          }
          <div className={classes.bottomButtons}>
            <Typography className={classes.errorText} color={'error'}>{error ? `(*) ${error}` : ' '}</Typography>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              className={classes.backButton}
            >
              Back
            </Button>
            <Button variant="contained" color="primary" onClick={handleVerify} disabled={isLoading}>
              {isLoading 
                ?
                  <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
                    <CircularProgress size='1.5rem' style={{ margin: 'auto' }} />
                  </div>
                : lastStep() 
                  ? readOnly ? 'Close' : 'Finish' 
                  : 'Next'
              }
            </Button>
          </div>
        </div>
    </Dialog>
  );
}