import React, { useContext } from 'react';
import { IAuction } from '../../Interfaces/Database/Auction';
import { colors } from '../../Config/Global';
import { 
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  IconButton, makeStyles, Paper,
  Table, TableCell, TableHead, TableRow, Toolbar, Typography, 
  Checkbox,
  Container
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { RootContext } from '../../Store/RootStore';
import CreateAuctionStepper, { IAuctionStaff, IUserMap } from './CreateAuctionStepper';
import { IUser } from '../../Interfaces/Database/User';
import { QueryResult } from '../../Interfaces/Service';
import { ServiceMethods } from '@feathersjs/feathers';
import { IStaffRole } from '../../Interfaces/Database/StaffRole';
import MultipleSelectChip from '../Input/MultiSelectChip';

const useStyles = makeStyles(theme => ({
  grid: {
    padding: '20px',
  },
  content: {
    height: '100%',
    overflowY: 'scroll'
  },
  footer: {
    padding: '1rem',
    backgroundColor: colors.navBarSecondary6up,
  },
  bottomButtons: {
    float: 'right'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '18px'
  },
  manage: {
    marginTop: '10px'
  },
  errorText: {
    float: 'left', 
    marginTop: '5px', 
    marginRight: '10px', 
    fontStyle: 'italic'
  },
  backButton: {
    marginRight: '10px',
  },
}));

interface IDisabledMap {
  [staffID: string]: boolean
}


interface IProps {
  auctionID: string,
  isOpen: boolean,
  onClose: () => void
}

export default function AuctionStaffDialog({ auctionID, isOpen, onClose }: IProps) {
  const styles = useStyles();
  const store = useContext(RootContext);
  const { client, user, authToken } = store;

  const [isLoadingSave, setIsLoadingSave] = React.useState<boolean>(false);
  const [isLoadingRetrieval, setIsLoadingRetrieval] = React.useState<boolean>(false);
  const [isError, setIsError] = React.useState<boolean>(false);
  const [allStaffArray, setAllStaffArray] = React.useState<IUser[]>([]);
  const [allStaff, setAllStaff] = React.useState<IUserMap>({});
  const [savedStaff, setSavedStaff] = React.useState<IStaffRole[]>([]);


  const [newlySelectedClerk, setNewlySelectedClerk] = React.useState<string[]>([]);
  const [newlySelectedAdmin, setNewlySelectedAdmin] = React.useState<string[]>([]);

  const [toDisableClerk, setToDisableClerk] = React.useState<IDisabledMap>({});
  const [toDisableAdmin, setToDisableAdmin] = React.useState<IDisabledMap>({});

  React.useEffect(() => {
    if(isOpen){
      const promises = [];
      setIsLoadingRetrieval(true);
      // get All Possible Staff
      promises.push(
        client?.service('users').find({
          headers: { Authorization: authToken },
          user: user || undefined,
          query: { 
            $limit: 0,
            isStaff: true
          },
        })
          .then((res: any) => (
            client?.service('users').find({
              headers: { Authorization: authToken },
              user: user || undefined,
              query: { 
                $limit: (res as QueryResult<IUser>).total,
                isStaff: true
              },
            })
          ))
          .then((res: any) => res.data)
          .then((staff: IUser[]) => {
            const staffMap = staff.reduce((acc, user) => (
              { ...acc, [user.email]: user }
            ), {});
            setAllStaff(staffMap);
            setAllStaffArray(staff);
          })
          .catch((err: any) => console.log('Find All Staff Error', err))
      );

      // Get Current Auction Staff
      promises.push(
        client?.service('staff-roles').find({
          headers: { Authorization: authToken },
          user: user || undefined,
          query: { 
            $limit: 0,
            auctionID: auctionID
          },
        })
          .then((res: any) => (
            client?.service('staff-roles').find({
              headers: { Authorization: authToken },
              user: user || undefined,
              query: { 
                $limit: (res as QueryResult<IStaffRole>).total,
                auctionID: auctionID
              },
            })
          ))
          .then((res: any) => res.data)
          .then((staff: IStaffRole[]) => {
            setSavedStaff(staff);
          })
          .catch((err: any) => console.log('StaffRole Error', err))
      );

      Promise.all(promises)
      .finally(() => setIsLoadingRetrieval(false));
    }
  }, [ isOpen ]);


  const onChangeClerks = (selected: string[]) => {
    console.log('selected: ', selected);
    setNewlySelectedClerk(selected);
  }

  const onChangeAdmin = (selected: string[]) => {
    setNewlySelectedAdmin(selected);
  }

  const usersWhoAreNotAdmin = React.useMemo(() => {
    const savedAdmin = savedStaff.filter(staff => staff.role === 'admin').map(admin => admin.userID);
    const notAdmin = allStaffArray.filter(user => !savedAdmin.includes(user._id));
    return notAdmin.reduce((acc, user) => (
      { ...acc, [user.email]: user }
    ), {});
  }, [savedStaff, allStaffArray]);

  const usersWhoAreNotClerk = React.useMemo(() => {
    const savedClerk = savedStaff.filter(staff => staff.role === 'clerk').map(clerk => clerk.userID);
    const notClerk = allStaffArray.filter(user => !savedClerk.includes(user._id));
    return notClerk.reduce((acc, user) => (
      { ...acc, [user.email]: user }
    ), {});
  }, [savedStaff, allStaffArray]);

  const handleSave = () => {
    setIsLoadingSave(true);
    Promise.all([createNewStaffUsers(), disableStaffUsers()])
      .then(() => handleClose())
      .catch(() => setIsError(true))
      .finally(() => setIsLoadingSave(false));
  }

  const createNewStaffUsers = () => {
    const staffRolesService: ServiceMethods<IStaffRole> = client?.service('staff-roles');
    const newClerk: IStaffRole[] = newlySelectedClerk.map(email => ({
      userID: allStaff[email]._id,
      email, 
      firstName: allStaff[email].firstName,
      lastName: allStaff[email].lastName,
      auctionID,
      role: 'clerk',
      activated: new Date(),
      expired: 'NA'
    } as IStaffRole));
    const newAdmin: IStaffRole[] = newlySelectedAdmin.map(email => ({
      userID: allStaff[email]._id,
      email, 
      firstName: allStaff[email].firstName,
      lastName: allStaff[email].lastName,
      auctionID,
      role: 'admin',
      activated: new Date(),
      expired: 'NA'
    } as IStaffRole));
    const newStaff = [...newClerk, ...newAdmin];

    if(newStaff.length > 0){
      console.log('save: ', newStaff)
      return staffRolesService.create(newStaff, {
        headers: { Authorization: authToken },
        user: user || undefined
      })
    }
  }

  const disableStaffUsers = () => {
    const staffRolesService: ServiceMethods<IStaffRole> = client?.service('staff-roles');
    const array = [...Object.keys(toDisableClerk), ...Object.keys(toDisableAdmin)];

    if(array.length){
      console.log('patch: ', array)
      return staffRolesService.patch(null, { expired: new Date() }, {
        headers: { Authorization: authToken },
        user: user || undefined,
        query: {
          _id: { $in: array }
        }
      });
    }
  };

  const handleClose = () => {
    setNewlySelectedClerk([]);
    setNewlySelectedAdmin([]);
    setToDisableClerk({});
    setToDisableAdmin({});
    setIsError(false);
    onClose();
  };

  const table = (savedStaff: IStaffRole[], toDisableMap: IDisabledMap, toDisableSetter: any) => {
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>Name</strong></TableCell>
            <TableCell><strong>Activated</strong></TableCell>
            <TableCell><strong>Disabled</strong></TableCell>
          </TableRow>
        </TableHead>
        {savedStaff.map(staff => 
          <TableRow style={(staff.expired as any) !== 'NA' ? { color: 'red' } : { color: 'green' }}>
            <TableCell>{staff.firstName} {staff.lastName}</TableCell>
            <TableCell>{new Date(staff.activated).toLocaleString()}</TableCell>
            <TableCell>{(staff.expired as any) !== 'NA'
              ? new Date(staff.expired as any).toLocaleString() 
              : <Checkbox 
                  checked={toDisableMap[staff._id]} 
                  onChange={(e) => toDisableSetter({ ...toDisableMap, [staff._id]: e.target.checked })}
                />
            }</TableCell>
          </TableRow>
        )}
      </Table>
    )
  }

  const savedStaffClerk = React.useMemo(() => savedStaff.filter(staff => staff.role === 'clerk'), [ savedStaff ])
  const savedStaffAdmin = React.useMemo(() => savedStaff.filter(staff => staff.role === 'admin'), [ savedStaff ])


  return (
    <Dialog
      fullScreen
      open={isOpen}
    >
      <AppBar style={{position: 'relative'}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose}>
            <CloseIcon/>
          </IconButton>
          <Typography variant="h6">Staff Management</Typography>
        </Toolbar>
      </AppBar>

      <div className={styles.content}>
        <Container>
          {isLoadingRetrieval 
            ? <Grid container style={{ marginTop: '30px', display: 'flex', height: '100%' }}>
                <CircularProgress style={{ margin: 'auto' }} />
              </Grid>
            : <Grid container style={{ marginTop: '30px' }}>

                <Grid item key={'clerk-input-grid'} xs={12} md={6} className={styles.grid}>
                  <Typography className={styles.title}>Clerk Have Access To:</Typography>
                  <ul>
                    <li>View, create, and edit items</li>
                    <li>Live auction managment including:</li>
                    <ul>
                      <li>Setting item as live and sold</li>
                      <li>Bid on behalf of in-person bidders</li>
                      <li>Pause and end auction</li>
                    </ul>
                  </ul>
                  <Typography>Add Clerk:</Typography>
                  <MultipleSelectChip allStaff={usersWhoAreNotClerk} selected={newlySelectedClerk} onChange={onChangeClerks}/>
                  {savedStaffClerk.length > 0 &&
                    <>
                      <Typography className={styles.manage}>Manage Clerk:</Typography>
                      {table(savedStaffClerk, toDisableClerk, setToDisableClerk)}
                    </>
                  }
                </Grid>

                <Grid item key={'admin-input-grid'} xs={12} md={6} className={styles.grid}>
                  <Typography className={styles.title}>Admin Have Access To:</Typography>
                  <ul style={{ marginBottom: '55px' }}>
                    <li>In-person bidder check-in</li>
                    <li>View and print invoices</li>
                    <li>Initiate payment</li>
                  </ul>
                  <Typography>Add Admin:</Typography>
                  <MultipleSelectChip allStaff={usersWhoAreNotAdmin} selected={newlySelectedAdmin} onChange={onChangeAdmin}/>
                  {savedStaffAdmin.length > 0 &&
                    <>
                      <Typography className={styles.manage}>Manage Admin:</Typography>
                      {table(savedStaffAdmin, toDisableAdmin, setToDisableAdmin)}
                    </>
                  }
                </Grid>

              </Grid>
          }
        </Container>
      </div>

      <div className={styles.footer}>
        <div className={styles.bottomButtons}>
          {isError &&
            <Typography className={styles.errorText} color={'error'}>An error has occurred</Typography>
          }
          <Button className={styles.backButton} variant="contained" color="primary" onClick={handleSave} disabled={isLoadingSave || isLoadingRetrieval}>
            {isLoadingSave 
              ?
                <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
                  <CircularProgress size='2.5rem' style={{ margin: 'auto' }} />
                </div>
              : 'Save'
            }
          </Button>
        </div>
      </div>
    </Dialog>
  );
}