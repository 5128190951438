import React, { useContext } from 'react';
import { IAuction } from '../../Interfaces/Database/Auction';
import { colors } from '../../Config/Global';
import clsx from 'clsx';


import {
  Box, Container, Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import ItemsList from '../ItemsList';
import AuctionHeader from './AuctionHeader';
import { ICategoryMap } from '../../App';
import LiveStream from '../LiveAuction/liveStream';

// Local Styles
const useStyles = makeStyles({
  root: {
  },
  infoBar: {
    width: '100%',
  },
  main: {
    backgroundColor: colors.offWhite,
    minHeight: '500px',
    paddingTop: '30px',
  },
  text: {
    padding: 5,
    textAlign: 'center',
    color: colors.darkBlueFont,
  },
  charityText: {
    margin: 'auto',
    padding: '4px',
    textAlign: 'center',
    color: colors.darkBlueFont,
    backgroundColor: '#ffe699', 
    borderRadius: 5, 
  },
  tab: {
    border: '1px solid black',
    borderBottom: 0,
    borderRadius: '2px',
    margin: '0px 5px -2px',
    padding: '7px',
    paddingBottom: '7px',
  },
  viewing: {
    border: '2px solid black',
    borderBottom: 0,
    borderRadius: '2px',
    margin: '0px 5px -2px',
    padding: '7px',
    paddingBottom: '7px',
    backgroundColor: colors.offWhite,
  },
  hide: {
    display: 'none',
  },
});

interface IProps {
  auction: IAuction,
  categories: ICategoryMap,

  // Callbacks
  onAuctionUpdate?: (id: string | null, auction: Partial<IAuction>) => Promise<any>,
  onAuctionDelete?: (id: string, history: History) => void,
  onLogin: () => void,
}

export default function ViewAuction({ auction, categories, onAuctionUpdate, onAuctionDelete, onLogin }: IProps) {
  const styles = useStyles();
  const [viewing, setViewing] = React.useState<string>('items'); // items, live

  return (
    <Container className={styles.root}>

      <Box className={styles.infoBar}>
        <AuctionHeader auction={auction} onAuctionDelete={onAuctionDelete} onAuctionUpdate={onAuctionUpdate} />
        {auction.type === 'liveAndOnline' &&
          <>
            <Button className={clsx(styles.tab, {[styles.viewing]: viewing === 'items'})}  onClick={() => setViewing('items')}>Items</Button>
            <Button className={clsx(styles.tab, {[styles.viewing]: viewing === 'live'})} onClick={() => setViewing('live')}>Live</Button>
          </>
        }
      </Box>

      <Box className={styles.main}>
        {viewing === 'items' &&
          <ItemsList 
            auction={auction} 
            categories={categories}
            onLogin={onLogin}
          /> 
        }

        {viewing === 'live' &&
          <LiveStream auction={auction} categories={categories} onLogin={onLogin}/>
        }
      </Box>

    </Container>
  );
}