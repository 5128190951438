import React from 'react';
import {  Link as RouterLink } from 'react-router-dom';
import {
  Box, Typography,
} from '@material-ui/core';



export function termsOfService(link: boolean){

  return (
    <Box style={{ width: '100%' }}>
      <Typography style={{ margin: '10px 0 5px' }}>
        Revised August 5, 2021
      </Typography>
      <Typography>
        Royale Treasures Auction requires that you read, understand, accept and comply with the following terms and conditions in order to bid in an online auction hosted by us.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Bidder Eligibility</Typography>
      <Typography>
        You must be at least 18 years of age and able to form legally binding contracts under applicable law.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Auction Close</Typography>
      <Typography>
        If an item is offered for online prebidding only, then bidding for the item will continue during a silent/live event auction and Auction Close refers to the end of 
        bidding during the silent/live event auction. Otherwise, Auction Close means the end of online bidding or the end of online fixed-price item sale for the item.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Your Bid or Purchase is a Contract</Typography>
      <Typography>
        When you place a bid on an online auction item, you enter into a legally binding contract to purchase the item from the seller if you are the winning bidder. You are 
        the winning bidder if your bid is the highest bid at Auction Close and your bid is accepted by the seller. When you purchase a fixed-price item, you enter into a 
        legally binding contract to purchase the item from the seller.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Payment Terms</Typography>
      <Typography>
        If you are a winning bidder or purchase a fixed-price item, you agree to pay the seller the full amount of your winning bid (for biddable items) or the sale price 
        (for fixed-price items) plus any applicable sales or use tax on your purchase, as well as any applicable shipping or delivery charges. You agree to honor the
        seller’s requirements regarding method and time of payment.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Seller’s Terms</Typography>
      <Typography>
        When you place a bid on an online auction item or purchase a fixed-price item, you are agreeing to any specific terms and conditions imposed by the seller 
        and included by the seller in the item’s description (except in the case that such terms would violate the law or conflict with 
        Royale Treasures Auction’s Terms of Service).
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>All Bids are Final</Typography>
      <Typography>
        Once you have placed and confirmed a bid, your bid can not be retracted (except in the case that the completion of the transaction would violate 
        the law or the Royale Treasures Auction Terms of Service for Bidders).
        All Purchases are Final Once you have confirmed a purchase, your purchase is final and can not be canceled (except in the case that the completion of the transaction 
        would violate the law or the Royale Treasures Auction Terms of Service for Bidders).
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Tied Bids</Typography>
      <Typography>
        In the case of two bidders placing the same maximum bid, the bid first received by the Royale Treasures Auction system will be deemed the leading bid.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Government Regulations and Taxes</Typography>
      <Typography>
        You are solely responsible for compliance with any federal, state or local tax laws governing your purchase. It is your responsibility to report and pay any 
        applicable taxes. We suggest you consult a professional tax adviser.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Auction Changes</Typography>
      <Typography>
        Sellers reserve the right to add or remove items from the online auction at any time without notice.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Acceptance of Terms of Service</Typography>
      <Typography>
        You must read, understand, accept and comply with the
         {link 
            ?
              <RouterLink style={{ color: '#000', textDecoration: 'none' }} to={'/bidders-terms-of-service'}>
                Royale Treasures Auction Terms of Service for Bidders.
              </RouterLink>
            :  'Royale Treasures Auction Terms of Service for Bidders.'
          }
      </Typography>
     
      
     
    </Box>
  );
}

export function biddersTermsOfService(){

  return (
    <Box style={{ width: '100%' }}>
      <Typography style={{ margin: '10px 0 5px' }}>
        Revised August 5, 2021
      </Typography>
      <Typography>
        Welcome to Royale Treasures Auction! This Agreement describes the terms and conditions applicable to your use of the Royale Treasures Auction web site, 
        services and software (collectively, the “Services”). By using the Services, you accept the terms and conditions of this Agreement. If you do not accept 
        these terms and conditions, you are not authorized to use the Royale Treasures Auction web site (the “Web Site”) or Services.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>1. Use of Services</Typography>
      <Typography>
        The Services are available only to individuals who can form legally binding contracts under applicable law. Additionally, it is our intent that the charity 
        feature Services be used only by non-profit entities or by for-profit entities raising money on behalf of and for the exclusive benefit of a non-profit entity. 
        If you do not qualify under these terms, you may not use the Services. As long as you remain in compliance with these Terms of Service, Royale Treasures Auction 
        grants you the right to use the Services as described herein. You agree that the Services constitutes a valuable property and trade secret of Royale Treasures 
        Auction and that Royale Treasures Auction retains all intellectual property rights thereto (and to any related materials, information and processes). You agree not 
        to modify, copy, disclose, reverse-engineer, decompile, disassemble or create derivative works from the Services or disclose or provide access to the Services to 
        any unauthorized party. You agree not to remove or modify any of Royale Treasures Auction’s copyright and other proprietary notices included with the Services.
        If you violate any of these Terms of Service, your permission to use the Services automatically terminates and you must immediately cease your use of the Services.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>2. Payment Terms</Typography>
      <Typography>
        Payment must be made in full before items are shipped. All payments must be made by credit card at time of purchase. We do not accept cash, checks,
        invoices or any other method of payment.
      </Typography>      
      <Typography>
        All Royale Treasures Auction fees are exclusive of any federal, state and local taxes. Payment of appropriate taxes is your responsibility.
      </Typography>
      <Typography>
        All Royale Treasures Auction fees are exclusive of credit card processing fees. Payment of credit card processing fees is your responsibility. 
        One of Royale Treasures Auction’s merchant processing partners may agree to process credit cards on your behalf.
      </Typography>
      <Typography>
        Prices are subject to change without notice.
      </Typography>
      <Typography>
        All purchases are final and non-refundable.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>3. Password and Security</Typography>
      <Typography>
        Every user of Royale Treasures Auction must register with the Royale Treasures Auction web site in order to establish a unique 
        personal login (email address) and password. You are responsible for keeping your password confidential. We recommend you memorize 
        your password, and not write it down. You may not share your password with any other person. You are responsible for all activity 
        occurring through use of your account and/or password with or without your knowledge. If you knowingly provide your login and password
        information to another person, your account privileges may be suspended temporarily or terminated. You agree to immediately notify Royale
        Treasures Auction of any actual or suspected unauthorized use of your account or password. Royale Treasures Auction cannot and will not be 
        responsible for any loss to you arising from your failure to comply with the above. You agree to use software produced by third parties, including, 
        but not limited to, “browser” software that supports a data security protocol compatible with the protocol used by Royale Treasures Auction. Until 
        notified otherwise by Royale Treasures Auction, you agree to use software that supports the Secure Socket Layer (SSL) protocol or other protocols 
        accepted by Royale Treasures Auction and follow Royale Treasures Auction’s log-on procedures. You acknowledge that Royale Treasures Auction is not 
        responsible for notifying you of any upgrades, fixes or enhancements to any such software or for any compromise of data transmitted across computer 
        networks or telecommunications facilities, including, but not limited to, the Internet. You acknowledge that it is possible that electronic 
        communications may be accessed by unauthorized third parties when communicated between you and Royale Treasures Auction using the Internet, other 
        network communications facilities, telephone or any other electronic means.
      </Typography>    
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>4. Access</Typography>
      <Typography>
        In order to use the Services, you need the equipment necessary to connect to the World Wide Web, and the Internet connection necessary to access it. 
        You are responsible for any fees associated with such connection or access (such as those charged by an Internet Service Provider (ISP) or other online service). 
        You will also need an appropriate computer, related equipment, and software (your “Computer”). You are responsible for installing, maintaining, and operating your 
        Computer. Royale Treasures Auction is not responsible for any problems caused by your Computer, including any virus or related problems associated with your use 
        of the Services on your Computer. We do not guarantee continuous, uninterrupted or secure access to our services, and operation of the Web Site and Services may 
        be interfered with by numerous factors outside of our control.
      </Typography>    
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>5. Use of Materials</Typography>
      <Typography>
        The contents of the Web Site, such as text, graphics, images, downloadable and other material (“Materials”), are protected by copyright under both United States and foreign 
        laws. Unauthorized use of the Materials may violate copyright, trademark, and other laws and is prohibited.
      </Typography>    
      <Typography>
        Royale Treasures Auction authorizes you to view and download the Materials solely for your and your organization’s noncommercial use. Special rules may apply to the use of 
        certain features and services and other items provided on the Web Site. Any such special rules are listed as Legal Notices on the Web Site and are incorporated 
        into this Agreement by reference.
      </Typography>  
      <Typography>
        You must retain all copyright and other proprietary notices contained in the original Materials on any copy you make of the Materials. You may not sell the Materials. 
        You may not reproduce, display, publicly perform, distribute, or otherwise use the Materials in any way for any public or commercial purpose except in connection 
        with the promotion and/or conducting of your event. The Materials may not be used on any other web site for any purpose unless our prior written permission is obtained.
      </Typography>  
      <Typography>
        If you violate any of these Terms of Service, your permission to use the Materials automatically terminates and you must immediately destroy any copies you have made of the Materials.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>6. Electronic Message Deliverability</Typography>
      <Typography>
        The Services may include features enabling the sending of electronic messages via external networks that are outside of Royale Treasures Auction’s control. Examples include sending 
        email messages via the Internet, and sending text messages via short message service cellular networks. Royale Treasures Auction makes no guarantee as to the deliverability of 
        such messages. You acknowledge that Royale Treasures Auction is not responsible for the delivery of electronic messages sent through the Royale Treasures Auction service. 
        Such messages may be delayed for an indeterminate period of time, or may not be delivered at all.
      </Typography>    
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>7. Links to Other Sites</Typography>
      <Typography>
        The Web Site contains links to third party web sites. These links are provided solely as a convenience to you and not as an endorsement by Royale Treasures Auction of the 
        contents on such third-party web sites. We are not responsible for the content of linked third-party sites and do not make any representations regarding the content 
        or accuracy of materials on such third-party web sites. If you decide to access linked third-party web sites, you do so at your own risk.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>8. Liability to Damages</Typography>
      <Typography>
        IN NO EVENT SHALL ROYALE TREASURES AUCTION OR ITS SUPPLIERS BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION LOST PROFITS OR ANY SPECIAL, 
        INCIDENTAL OR CONSEQUENTIAL DAMAGES (HOWEVER ARISING, INCLUDING NEGLIGENCE) ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT OR RESULTING FROM THE USE OR 
        INABILITY TO USE THE SERVICES AND THE MATERIALS. 
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>9. No Warranty</Typography>
      <Typography>
        The Materials, including information obtained from independent suppliers not affiliated with Royale Treasures Auction, is believed to be reliable, but may contain 
        inaccuracies or typographical errors. We make no representations about the accuracy, reliability, completeness, sequence or timeliness of the Materials or about the 
        results obtained from using the Web Site, the Services or the Materials. The use of the Web Site, the Services and the Materials is at your own risk. Changes are 
        periodically made to the Web Site and may be made at any time.
      </Typography>
      <Typography>
        ROYALE TREASURES AUCTION DOES NOT WARRANT THAT THE WEB SITE WILL OPERATE ERROR-FREE OR THAT THE WEB SITE AND ITS SERVER ARE FREE OF COMPUTER VIRUSES AND OTHER HARMFUL GOODS. 
        IF YOUR USE OF THE WEB SITE OR THE MATERIALS RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, ROYALE TREASURES AUCTION IS NOT RESPONSIBLE FOR THOSE COSTS. 
        WE AND OUR SUPPLIERS PROVIDE THE WEB SITE AND THE SERVICES “AS IS” AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMITTED BY LAW, WE 
        AND OUR SUPPLIERS SPECIFICALLY DISCLAIM THE IMPLIED WARRANTIES OF TITLE, FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY AND NON-INFRINGEMENT. ROYALE TREASURES AUCTION 
        AND ITS SUPPLIERS MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE MATERIALS, SERVICES, SOFTWARE, TEXT, GRAPHICS, 
        AND LINKS.
      </Typography>
      <Typography>
        Some states do not allow the disclaimer of implied warranties, so the foregoing disclaimer may not apply to you. In such states, Royale Treasures Auction’s liability is limited to 
        the greatest extent permitted by law. This warranty gives you specific legal rights and you may also have other legal rights which vary from state to state.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>10. Royale Treasures Auction’s Role</Typography>
      <Typography>
        Royale Treasures Auction’s role is to facilitate non-profit fundraising events. We are not involved in the actual transaction between sellers and buyers, and, as such, 
        we have no control over the delivery, quality, safety or legality of the items advertised, the truth or accuracy of the listings, the ability of sellers to sell items or 
        the ability of buyers to pay for items. Therefore, we cannot guarantee that two parties will actually complete a transaction.
        As an intermediary, we will not and cannot be involved in your organization’s dealings. In the event that a dispute arises among one or more users, or between a buyer and 
        a seller, you release Royale Treasures Auction (and our officers, directors, employees and agents) from any and all claims, demands and damages (actual and consequential) 
        of every kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way connected with such disputes. If you are a 
        California resident, you waive California Civil Code §1542, which says: “A general release does not extend to claims which the creditor does not know or suspect to exist 
        in his favor at the time of executing the release, which if known by him must have materially affected his settlement with the debtor.”
      </Typography>
      <Typography>
        For legal reasons, we cannot nor do we try to control the information provided by other users which is made available through our system. By its very nature, other people’s 
        information may be offensive, harmful or inaccurate, and in some cases will be mislabeled or deceptively labeled. We expect that you will use caution – and common sense – 
        when using the Web Site.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>11. Registration Data</Typography>
      <Typography>
        Your registration information includes any information you and/or your organization provides to us during the registration process (“Registration Data”). The Registration Data is 
        subject to our Privacy and Security Policy. In consideration for your use of the Web Site, you agree to provide true, accurate, current and complete Registration Data, and to 
        maintain and promptly update the Registration Data to keep it true, accurate, current and complete.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>12. System Integrity</Typography>
      <Typography>
        You may not use any device, software or routine to interfere or attempt to interfere with the proper working of the Web Site. You may not take any action which imposes an unreasonable 
        or disproportionately large load on our infrastructure. You may neither disclose your password to nor share your password with any third parties or use your password for 
        any unauthorized purpose.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>13. Breach</Typography>
      <Typography>
        We may immediately issue a warning, temporarily suspend, indefinitely suspend or terminate your account, and remove any information you place on the Web Site if you breach this 
        Agreement or if we are unable to verify or authenticate any information you provide to us.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>14. General Compliance with Laws</Typography>
      <Typography>
        You shall comply with all applicable laws, statutes, ordinances and regulations regarding your use of the Services.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>15. Indemnity</Typography>
      <Typography>
        You agree to defend, indemnify, and hold harmless Royale Treasures Auction, its officers, directors, employees and agents, from and against any claims, actions or demands, including 
        without limitation reasonable legal and accounting fees, alleging or resulting from (a) your use of the Services or Materials (b) your breach of the terms of this Agreement (c) 
        any activity (including negligent or wrongful conduct) by you or any person in your organization (or acting on your organization’s behalf) in connection with your use of the 
        Services or Materials.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>16. No Agency</Typography>
      <Typography>
        You and/or your organization and Royale Treasures Auction are independent contractors, and no agency, partnership, joint venture, employee-employer or franchisor-franchisee 
        relationship is intended or created by this Agreement.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>17. Notices</Typography>
      <Typography>
        Except as explicitly stated otherwise, any notices to Royale Treasures Auction shall be given by email to the support email address provided on the Royale Treasures Auction 
        web site and any notices to you shall be given by email to the email address you provide to Royale Treasures Auction during the registration process. Notice shall be deemed 
        given 24 hours after email is sent, unless the sending party is notified that the email address is invalid. Alternatively, we may give you notice by certified mail, 
        postage prepaid and return receipt requested, to the address provided to Royale Treasures Auction during the registration process. In such case, notice shall be deemed 
        given 3 days after the date of mailing.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>18. Privacy Policy</Typography>
      <Typography>
        By accepting these Terms, you acknowledge that you have read, understood and accepted Royale Treasures Auction’s Privacy and Security Policy. Royale Treasures Auction may change 
        its Privacy Policy at any time upon notice to its users.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>19. Support Policy</Typography>
      <Typography>
        Technical support during your non-profit auction hosted by Royale Treasures Auction is available for additional fees. Contact Royale Treasures Auction for more details. 
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>20. General</Typography>
      <Typography>
        This Agreement shall be governed in all respects by the laws of the State of Michigan, United States of America, without respect to its conflicts of laws principles. You and Royale 
        Treasures Auction agree to submit to the personal and exclusive jurisdiction of the courts located within the State of Michigan. If any provision of this Agreement is held to be 
        invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced. Headings are for reference purposes only and in no way define, limit, 
        construe or describe the scope or extent of such section. Our failure to act with respect to a breach by you or others does not waive our right to act with respect to subsequent 
        or similar breaches. This Agreement sets forth the entire understanding and agreement between us with respect to the subject matter hereof.
      </Typography>
    </Box>
  );
}


export function privacySecurity(){

  return (
    <Box style={{ width: '100%' }}>
      <Typography style={{ margin: '10px 0 5px' }}>
        Revised August 5, 2021
      </Typography>
      <Typography>
        Royale Treasures Auction is committed to protecting your personal information and your right to privacy. This privacy notice applies to all information collected through our services. 
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Collected Information</Typography>
      <Typography>
        Personal information you disclose to us. We collect personal information that you voluntarily provide us when you register for the our site including names, 
        phone numbers, email addresses, and mailing addresses. 
      </Typography>
      <Typography>
        All payment data is stored externally through Square payment service provider. You can navigate to Square&quot;s &nbsp;
        <a href='https://squareup.com/help/us/en/article/3796-privacy-and-security'>privacy policy</a> 
        &nbsp;for more information.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>How We Use Your Information</Typography>
      <Typography>
        We use personal information collected purely to fulfill and manage your purchases and item delivery. 
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>How Your Information Will be Shared</Typography>
      <Typography>
        We only share your information with your consent, to comply with laws, to provide you services, and to fulfill business obligations. We may disclose information where we are 
        legally required to do so in order to comply with applicable law, governmental requests, or legal process. We may disclose your information where we believe it is necessary
        to investigate, prevent, or take action regarding potential violations of our policies and suspected fraud. We may share or transfer your information in connection with 
        payment or shipment processes. 
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Cookie Usage</Typography>
      <Typography>
        Royale Treasures Auction uses functionality cookies to allow us to remember your login details to allow for reduced login time.	If you prefer to avoid the use of cookies on 
        the website, first you must disable the user of cookies in your browser and then delete the cookies saved in your browser associated with this site. You may use this option to 
        prevent the use of cookies at any time.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Keeping Information Secure</Typography>
      <Typography>
        We have implemented appropriate security measures designed to protect the security of any personal information we process. Although we do our best to protect your information, 
        we cannot guarantee that hackers or other unauthorized third parties will not be able to defeat our security. You should only access the website within a secure environment.
      </Typography>
      <Typography style={{ fontWeight: 'bold', margin: '10px 0 5px' }}>Acceptance of Terms of Service</Typography>
      <Typography>
        Royale Treasures Auction privacy policy may be updated at any time. Any updated versions will be indicated by an updated “Revised” date. If you have any questions or concerns  
        about this privacy notice or our practices with regards to your personal information, you can contact us at royaletreasuresauction@gmail.com.
      </Typography>
     
      
     
    </Box>
  );
}