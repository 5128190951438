import React from 'react';
import { RootContext } from '../../Store/RootStore';
import {
  Dialog, DialogActions, DialogTitle, DialogContent,
  TextField, Button, DialogContentText, Typography, CircularProgress,
} from '@material-ui/core';
import { IAuthManagement } from '../../Interfaces/Database/User';
import { Service } from '@feathersjs/feathers';
import ReviewTermsAndConditions from '../../Components/Profile/ReviewTermsAndConditions';
import { useHistory } from "react-router-dom";


interface Props {
  locationSearch: any,
}

export default function FinishSetup(props: Props): JSX.Element {
  const store = React.useContext(RootContext);
  const history = useHistory();
  const [open, setOpen] = React.useState<boolean>(true);
  const [passError, setPassError] = React.useState<boolean>(false);
  const [passErrMsg, setPassErrMsg] = React.useState<string>('');
  const [error, setError] = React.useState<string>('');
  const [success, setSuccess] = React.useState<boolean>(false);
  const [isAgreed, setIsAgreed] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const pass1Ref = React.createRef<HTMLInputElement>();
  const pass2Ref = React.createRef<HTMLInputElement>();
  
  const token = React.useMemo(() => props.locationSearch
    .split('?token=')
    .pop(), [ props.locationSearch ]);
  
  const handleSubmit = () => {
    setPassErrMsg('');
    setPassError(false);
    
    if (!pass1Ref.current?.value || !pass2Ref.current?.value) {
      setPassError(true);
      return pass1Ref.current?.focus();
    }

    else if (pass1Ref.current.value !== pass2Ref.current.value) {
      setPassErrMsg('* Your passwords do not match');
      setPassError(true);
      return pass1Ref.current?.focus();
    }

    const { client } = store;
    const authManagement: Service<IAuthManagement> = client?.service('authManagement');
    setIsLoading(true);
    authManagement.create({
      action: 'resetPwdLong',
      value: {
        token,
        password: pass1Ref.current.value,
      },
      notifierOptions: {
        finishUserSetup: true
      }
    })
      .then(() => {
        setSuccess(true);
      })
      .catch(err => {
        setPassError(false);
        setPassErrMsg('');
        setError('(*) ' + err.message);
      })
      .finally(() => setIsLoading(false));
  };

  
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle style={{textAlign: 'center'}}>Finish Account Setup</DialogTitle>
      {!success 
        ? 
          <DialogContent>
            <TextField 
              variant='outlined'
              label='New Password'
              type='password'
              fullWidth
              error={passError}
              inputRef={pass1Ref}
              style={{ marginBottom: '10px' }}
            />
            <TextField 
              variant='outlined'
              label='Repeat New Password'
              type='password'
              fullWidth
              error={passError}
              inputRef={pass2Ref}
            />
            <Typography color='error'>{passErrMsg}</Typography>

            <ReviewTermsAndConditions
              isAgreed={isAgreed}
              onChange={setIsAgreed}
            />

            <DialogContentText style={{ color: '#E74C3C' }}>
              {error}
            </DialogContentText>
          
            <DialogActions>
              <Button disabled={!isAgreed} onClick={handleSubmit} variant="contained" color='primary' fullWidth>
                {isLoading 
                  ? <div style={{ width: '100%', textAlign: 'center'}}>
                      <CircularProgress size='1.5rem' style={{ color: 'white' }} />
                    </div>
                  : 'Submit'
                }
              </Button>
            </DialogActions>
          </DialogContent>

        :
          <DialogContent>
            <DialogContentText>
              Your account setup is complete! You may log in with your new password.
            </DialogContentText>
            <DialogActions>
              <Button
                onClick={() => {
                  setOpen(false);
                  setSuccess(false);
                  history.push('/');
                }}
                variant='contained'
                fullWidth
                color='primary'
              >
                Close
              </Button>
            </DialogActions>
          </DialogContent>
      }

    </Dialog>
  );
};