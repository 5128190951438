import React from 'react';
import { RootContext } from '../../Store/RootStore';
import './square.css';
import { v4 as uuid } from 'uuid';
import Config, { colors } from '../../Config/Global';
import {
  makeStyles, createStyles, Theme,
  Button, CircularProgress, Typography, Grid,
} from '@material-ui/core';

interface IProps {
  square: any,
}

interface ICard {
  cardBrand: string,
  lastFour: string, 
  expire: string,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: '500px',
    },
    cardImage: {
      width: '70px',
      height: '40px',
    },
    container: {
      margin: '20px auto',
      backgroundColor: '#eff2f5',
      borderRadius: '5px',
      [theme.breakpoints.down('md')]: {
        padding: '20px',
        width: '95%',
      },
      [theme.breakpoints.up('md')]: {
        padding: '50px',
        width: '80%',
      },
    },
    heading: {
      color: colors.darkBlueFont,
      fontSize: 22,
      marginBottom: '10px',
      fontWeight: 'bold',
    },
    text: {
      color: colors.darkBlueFont,
      margin: '10px',
    },
    currentCard: {
      backgroundColor: 'white',
      borderRadius: '5px',
      border: '1px solid #e6e6e6',
      padding: '10px',

    },
  }),
);



export default function Payment({ square }: IProps) {
  const styles = useStyles();
  const { client, user, authToken } = React.useContext(RootContext);
  const appId = Config.SQUARE_APP_ID;
  const locationId = Config.SQUARE_LOCATION_ID;

  const [cardContainerSet, setCardContainerSet] = React.useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
  const [currentCard, setCurrentCard] = React.useState<ICard | null | undefined>(undefined);

  async function initializeCard(payments: any) {
    const card = await payments.card();
    await card.attach('#card-container'); 
    return card; 
  }


  function displayStatus(status: string) {
    const statusContainer: any = document.getElementById('payment-status-container');
    if (status === 'SUCCESS') {
      statusContainer.innerHTML = '';
      statusContainer.classList.remove('is-failure');
      statusContainer.classList.add('is-success');
    } else {
      statusContainer.classList.remove('is-success');
      statusContainer.classList.add('is-failure');
      statusContainer.innerHTML = status;
    }
    statusContainer.style.visibility = 'visible';
  }  


  async function handleCardSubmit(event: any, paymentMethod: any) {
    event.preventDefault();
    setSubmitLoading(true);

    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      client?.service('square-cards').create({
        idempotencyKey: uuid(),
        locationId,
        sourceId: tokenResult.token, // card nonce
        card: {
          customerId: user?.customerID,
        },
      }, { 
        user,
        Authentication: authToken,
      })
        .then((res: any) => {
          displayStatus('SUCCESS');
          setCurrentCard(res);
          setSubmitLoading(false);
        })
        .catch((err: any) => {
          console.log('err: ', err);
          displayStatus(err.message);
          setSubmitLoading(false);
        });
    } else {
      setSubmitLoading(false);
      displayStatus(JSON.stringify(tokenResult.status));
      console.log(tokenResult);
    }
  }  


  function getCurrentCard(){
    if(user){
      client?.service('square-cards').get(user.customerID, {
        Authorization: authToken,
        user,
      })
        .then((res: any) => {
          setCurrentCard(res ? res : null);
      })
        .catch((err: any) => {
          console.log('cards err: ', err);
          setCurrentCard(null);
      });
    } 
  }


  React.useEffect(() => {
    getCurrentCard();
  }, [ user ]);


  React.useEffect(() => {
    if(square && currentCard !== undefined && !cardContainerSet){
      // Entry point of the Web Payments SDK
      console.log('Square.js load success');
      const payments = square.payments(appId, locationId);
      let card: any;
      initializeCard(payments)
        .then(res => {
          card = res;
          setCardContainerSet(true);
        })
        .catch(err => {
          console.error('Initializing Card failed', err);
          return;
        });
    
        // Step 5.2: create card payment
        document.getElementById('card-button')?.addEventListener('click', function (event: any) {
          handleCardSubmit(event, card);
        });

    } else {
      console.log('Square.js failed to load properly');
    }

  }, [square, currentCard]);


  return (
    <div className={styles.root}>
      {currentCard === undefined
        ?
          <div style={{ width: '100%', textAlign: 'center'}}>
            <CircularProgress size='1rem' style={{ margin: 'auto' }} />
          </div>
        : 
          <Grid container>
            {currentCard &&
                <Grid item xs={12} lg={4} >
                  <div className={styles.container}>
                    <Typography className={styles.heading}>Card on File</Typography>
                    <Grid container className={styles.currentCard}>
                      <Grid item xs={12}>
                        <img 
                          className={styles.cardImage} 
                          src={require(`../../images/card-type-${currentCard.cardBrand}.png`)}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='caption'>Number:</Typography>
                        <Typography>****{currentCard.lastFour}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='caption'>Expires:</Typography>
                        <Typography>{currentCard.expire}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
            }

            {/* Square Payment Form */}
            <Grid item xs={12} lg={8}>
              <form className={styles.container} id="payment-form">
                {currentCard 
                  ?
                    <>
                      <Typography className={styles.heading}>Update Card</Typography>
                      <Typography className={styles.text}>
                        Customers may only have one card on file. Upon updating, your current card will be replaced.
                      </Typography>
                    </>
                  : <Typography className={styles.heading}>Add Card on File</Typography>
                }
                <div id="card-container"></div>
                <Button id='card-button' disabled={submitLoading}>
                  {submitLoading 
                    ? <div style={{ width: '100%', textAlign: 'center'}}>
                        <CircularProgress size='1rem' style={{ margin: 'auto' }} />
                      </div>
                    : <Typography>{currentCard ? 'update' : 'submit'}</Typography>
                  }   
                </Button>
              </form>
            <div id="payment-status-container"></div>
            </Grid>
          </Grid>
      }
    </div>
  );
};