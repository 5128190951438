import React, { useContext } from 'react';
import {
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { IUser } from '../../Interfaces/Database/User';
import { RootContext } from '../../Store/RootStore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DoneAllIcon from '@material-ui/icons/DoneAll';

interface Props {
  isStaff: boolean,
  totalUsers: number
}

export default function CopyEmails({ isStaff, totalUsers }: Props) {
  const store = useContext(RootContext);
  const {client, user, authToken} = store;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isCopied, setIsCopied] = React.useState<boolean>(false);

  const copyAllUserEmails = async () => {
    const totalQueries = Math.ceil(totalUsers / 50);
    let users: IUser[] = [];
    setIsLoading(true);
    setIsCopied(false);
    for(let currentPage = 0; currentPage < totalQueries; currentPage++){
      const startingPoint = currentPage * 50;
      let endingPoint = startingPoint + 49;
      if(endingPoint > totalUsers){
        endingPoint = totalUsers;
      }
      const skip = startingPoint > 0 ? startingPoint - 1 : 0;

      await client?.service('users').find({
        Authorization: authToken,
        user: user || undefined,
        query: {
          $limit: 50,
          $skip: skip,
          $select: ['email'],
          isStaff
        }
      }).then((res: any) => {
        users = [ 
          ...users, 
          ...res.data
        ]
      });
    }

    navigator.clipboard.writeText(users.map(user => user.email).join(', '));
    setIsLoading(false);
    setIsCopied(true);
  }

  return (
    <Button style={{ margin: 10 }} variant='contained' onClick={copyAllUserEmails} disabled={isLoading}>
      {isLoading 
        ?
          <CircularProgress size='1rem'/>
        : isCopied
          ? <DoneAllIcon color='primary'/>
          : <FileCopyIcon color='primary'/> 
        }
      <Typography color='primary' style={{ marginLeft: '5px' }}>Copy All Emails</Typography>
    </Button>
  );
}