import { Service } from '@feathersjs/feathers';
import { IAuction } from '../../Interfaces/Database/Auction';
import { IRootStore } from '../RootStore';
import { QueryResult } from '../../Interfaces/Service';
import { IItem } from '../../Interfaces/Database/Item';
import { auctionReducer,  ItemStore, IPageData } from '../Reducers/AuctionReducer';
import { StateSetter } from './Interfaces';
import { IBidWithUser } from '../../Interfaces/Database/bidHistory';


/**
 * Queries all Auctions, storing them in a Global State
 *  Returns the Query or Cached if foud
 * @param state Global State
 * @param client Feathers Client
 * @param setState - State Setter for the new State
 */
export const queryAllAuctions = async (state: IRootStore, setState: StateSetter) => {
  
  // Check if in State
  if (state.auctionStore && Object.keys(state.auctionStore).length > 0) {
    return Object.keys(state.auctionStore)
      .map( auctionID =>  state.auctionStore[auctionID].auction );
  }
  
  // Establish Connection
  const { client } = state;
  if (!client) return;
  const auctionsService: Service<IAuction> = client.service('auctions');
  
  // Extract Required Keys
  const { authToken, user } = state;
  
  // Query total Auctions
  const headers = authToken ? { Authorization: authToken } : undefined;
  let query = {};

  if(!user?.permissions?.includes('admin')){
    query = {
      isTesting: false
    }
  }
  
  return auctionsService.find({
    query: { 
      $limit: 0, // Only get Total Auctions First
      ...query,
    },     
    headers,
    user: user || undefined,
  })
    .then((res: any) => (
      auctionsService.find({
        headers,
        user: user || undefined,
        query: {
          $limit: (res as QueryResult<IAuction>).total,
          $sort: { startDate: -1 },
          ...query,
        },
      })
    ))
    .then((res: any) => res.data)
    .then((data: IAuction[]) => {


      // Store Data in State
      const auctionStore = data.reduce((acc, curr) => ({
        ...acc,
        [curr._id]: {
          auction: curr,
          items: null,
        },
      }), {});
      
      //Update State
      setState((previousState: IRootStore) => {
        return {
          ...previousState,
          auctionStore,
        };
      });
      
      // Return data from Query
      return data;
    });
};

/**
 * Adds new Auction in the Database updating the
 *  Global State returning the new Auction
 * @param auction Auction to Add to the Database
 * @param state The current state
 * @param setState State Setter to update the state
 */
export const addAuction = async (auction: Partial<IAuction>, state: IRootStore, setState: StateSetter): Promise<IAuction> => {

  // Add Auction to Server
  const { client, user, authToken } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;

  if (!client) return Promise.reject(null);
  const auctionsService: Service<IAuction> = client?.service('auctions');

  
  return auctionsService.create(auction, {
    headers,
    user: user || undefined,
  })
    .then(newAuction => {
      // Add new Auction to Global State
      setState(
        auctionReducer(state, {
          type: 'ADD_AUCTION',
          payload: {
            [newAuction._id]: {
              auction: newAuction,
              items: null,
            },
          },
        }),
      );

      // Return auction for .then Chain
      return newAuction;
    });
};

/**
 * Patches Auction in the Database, updates the
 *  Global State, returned patched Auction
 * @param auction Auction to Patch to the Database
 * @param state The current state
 * @param setState State Setter to update the state
 */
export const updateAuction = async (id: string, auction: Partial<IAuction>, state: IRootStore, setState: StateSetter): Promise<IAuction> => {

  // Patch Auction from Server
  const { client, user, authToken } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;

  if (!client) return Promise.reject(null);
  const auctionsService: Service<IAuction> = client?.service('auctions');

  
  return auctionsService.patch(id, auction, {
    headers,
    user: user || undefined,
  })
    .then(newAuction => {
      // Update Patched Auction to Global State
      setState(
        auctionReducer(state, {
          type: 'ADD_AUCTION',
          payload: {
            [newAuction._id]: {
              auction: newAuction,
              items: state.auctionStore[newAuction._id].items,
            },
          },
        }),
      );

      // Return auction for .then Chain
      return newAuction;
    });
};


/**
 * Removes Given Auction from the Global State and Server
 * @param auctionID Auction's ID to remove
 * @param state Current Global State
 * @param setState State Setter to update current State
 */
export const removeAuction = async (auctionID: string, state: IRootStore, setState: StateSetter): Promise<IAuction | any> => {
  // Remove Auction from Server
  const { client, user, authToken } = state;
  if(!client) return Promise.reject(null);
  const auctionService: Service<IAuction> = client?.service('auctions');
  const itemsService: Service<IItem> = client?.service('items');
  const headers = authToken ? { Authorization: authToken } : undefined;

  // Find All Auction
  return itemsService.find({
    headers,
    user: user || undefined,
    query: { 
      auctionID,
      $limit: 50
    },
  })
    .then((res: any) => res.data)
    .then((data: IItem[]) => {
      // Delete All Items Linked to the Auction
      data.forEach(item => {
        itemsService.remove(item._id, {
          headers,
          user: user || undefined,
        });
      });
    })
    .then(() => {
      // Delete Auction
      return auctionService.remove(auctionID, {
        headers,
        user: user || undefined,
      });
    })
    .then(removedAuction => {
      // Remove Auction from Global State
      setState(
        auctionReducer(state, {
          type: 'REM_AUCTION',
          payload: auctionID,
        }),
      );

      return removedAuction;
    });

};

const queryXItems = async (auctionID: string, skip: number, x: number, state: IRootStore): Promise<IItem[]> => {
  const { client } = state;
  const itemsService: Service<IItem> = client?.service('items');
  
  // Extract Required Keys
  const { authToken, user } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;

  return itemsService.find({
    headers,
    user: user || undefined,
    query: {
      $limit: x,
      $skip: skip,
      auctionID,
      $sort: { itemNumber: 1 }
    },
  }).then((res: any) => res.data);
};


const addAlltemsToStore = async (auctionID: string, itemsPerPage: number, totalItems: number, state: IRootStore, setState: StateSetter) => {
  const { client } = state;
  const { authToken, user } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;
  const bidHistoryService: Service<IBidWithUser> = client?.service('bid-history');
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  
  for(let i = 0; i < totalPages; i++){
    const skip = i * itemsPerPage;
    await queryXItems(auctionID, skip, itemsPerPage, state)
      .then(async (data: IItem[]) => {

        // Get Highest Bid Data
        const highestBid = await Promise.all(
          data.map((item) => ( 
            item.highestBidID 
            ? bidHistoryService.get(item.highestBidID, {headers, user: user || undefined}) 
            : null ),
        ));
        const items = data
          .reduce((acc, item, index) => ({ 
            ...acc,  
            [item._id]: {
              item,
              highestBid: highestBid[index],
            }, 
          } as ItemStore), {} );

        // Add Items to Store
        setState((prevState: IRootStore) => {
          return {
            ...prevState,
            auctionStore: {
              ...prevState.auctionStore,
              [auctionID]: {
                ...prevState.auctionStore[auctionID],
                items: prevState.auctionStore[auctionID].items 
                  ? [...prevState.auctionStore[auctionID].items as any, ...data.map(item => item._id)] 
                  : data.map(item => item._id),
              },
            },
            itemStore: {
              ...prevState.itemStore,
              ...items,
            }, 
          };
        });
    });
  }
};

/**
 * Queries a given AuctionID's Items
 *  managing the Global Store's data
 * @param auctionID Auction's ID for Items
 * @param state Current State
 * @param setState State Setter
 */
 export const queryItemsNextPage = async (auctionID: string, currentPage: number, itemsPerPage: number, totalItems: number, state: IRootStore, setState: StateSetter): Promise<IPageData> => {
  const startingPoint = currentPage * itemsPerPage;
  let endingPoint = startingPoint + itemsPerPage - 1;
  if(endingPoint > totalItems){
    endingPoint = totalItems;
  }


  // Check if in State
  if (state.auctionStore[auctionID] && state.auctionStore[auctionID].items) {
    if((state.auctionStore[auctionID].items as any).length >= endingPoint){
      const page = state.auctionStore[auctionID].items?.slice(startingPoint, endingPoint + 1);
      return {
        data: (page as any).map((itemID: string) => state.itemStore[itemID].item) as IItem[],
        totalRows: totalItems,
      }; 
    }
  }

  const { client } = state;
  const itemsService: Service<IItem> = client?.service('items');
  const { authToken, user } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;

  const skip = startingPoint > 0 ? startingPoint - 1 : 0;
  const data = await queryXItems(auctionID, skip, itemsPerPage, state);


  // First Call
  if(totalItems === 0){

    // Get initial total item count
    totalItems = await itemsService.find({
      query: { 
        $limit: 0,
        auctionID,
      },
      headers,
      user: user || undefined,
    }).then(async (res: any) => (res as QueryResult<IItem>).total);

    // Initiate query all items in the background
    addAlltemsToStore(auctionID, itemsPerPage, totalItems, state, setState);
  } 
  
  return {
    data: data, 
    totalRows: totalItems,
  };

};