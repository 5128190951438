import React from 'react';
import { colors } from '../Config/Global';

import {
  Box, Typography,
  makeStyles,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';


// Local Styles
const useStyles = makeStyles({
  root: {
    minHeight: '70vh',
  },
  text: {
    margin: '30px auto',
    textAlign: 'center',
    color: colors.darkBlueFont,
    padding: 10,
    maxWidth: '70%'
  },
    
});


export default function Page404(){
  const styles = useStyles();
  const location = useLocation();
  const [text, setText] = React.useState<string>('');

  // Wait Two Seconds Before Displaying Page Not Found
  React.useEffect(() => {
    setTimeout(() => {
      if(location.pathname.includes('/auctions/')){
        setText('This auction has recently closed. To review your purchased items, navigate to the "Purchase History" page by selecting the profile icon on the top right.');
      } else {
        setText('Page Not Found');
      }
    }, 2000);
  }, []);


  return (
    <Box className={styles.root}>
      <Typography className={styles.text} variant="h5">{text}</Typography>
    </Box>
  );
}