import React from 'react';
import { colors } from '../Config/Global';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box, Typography,
  makeStyles,
  Divider,
  Grid,
} from '@material-ui/core';
import { IAuction } from '../Interfaces/Database/Auction';


// Local Styles
const useStyles = makeStyles({
  root: {
    minHeight: '70vh',
  },
  text: {
    textAlign: 'center',
    color: colors.darkBlueFont,
    marginBottom: '5px',
  },
  heading: {
    color: colors.darkBlueFont,
    fontSize: 22,
    margin: '10px',
    fontWeight: 'bold',
  },
  subtitle: {
    textAlign: 'center',
    color: colors.darkBlueFont,
    fontSize: 18,
    marginBottom: '20px',
    fontWeight: 'bold',
  },
  subText: {
    color: colors.darkBlueFont,
    fontSize: 16,
    margin: '20px 10px',
  },
  image: {
    width: '100%',
    maxWidth: '350px',
    objectFit: 'contain',
    borderRadius: '2px',
    textAlign: 'center',
  },
  centerChildren: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface IProps {
  auctions: IAuction[],
}

const removeMilli = (date: Date) => {
  const d = new Date(date).toLocaleString();
  return d.slice(0, -6).concat(d.slice(-2));
};

export default function Charity({ auctions }: IProps){
  const styles = useStyles();

  const startDate = React.useMemo(() => 
    auctions.map(auction =>  removeMilli(auction.startDate))
  , [ auctions ]);

  const endDate = React.useMemo(() => 
    auctions.map(auction =>  auction.endDate ? removeMilli(auction.endDate) : '')
  , [ auctions ]);


  return (
    <Box className={styles.root}>
      <Typography className={styles.heading}>Giving Back</Typography>

      <Typography className={styles.subText} >
        Royale Treasures Auction holds several charity auctions every year. Feel free to contact us to see if we can host an auction for your organization!
      </Typography>
      <Typography className={styles.subText} >
        Browse through our recent charity auctions below.
      </Typography>

  
      {auctions.map(( auction, index ) =>
        <>
          <Divider style={{ margin: '20px' }}/>
          <Grid spacing={2} container className={styles.centerChildren}>

            <Grid item xs={12} md={auction.charityImage ? 8 : 12}>
              <div style={{ margin: '5px' }}>
                {auction.state === 1 || auction.state === 2 
                  ? // Can only Navigate to a Non-Closed Auction
                    <RouterLink key={index} to={`/auctions/${auction._id}`}>
                      <Typography key={index} className={styles.subtitle}>Charity: {auction.charityName}</Typography> 
                    </RouterLink>
                  : <Typography key={index} className={styles.subtitle}>Charity: {auction.charityName}</Typography> 
                }
                <Typography key={index} className={styles.text} style={{ fontWeight: 'bold' }}>
                  {auction.type === 'liveAndOnline' ? 'Live' : ''} Auction: {auction.name}
                </Typography> 
                <Typography key={index} className={styles.text}>
                  {startDate[index]} {auction.type === 'liveAndOnline' ? '' : ` - ${endDate[index]}`} 
                </Typography> 
                {auction.charityDescription &&
                  <Typography key={index} className={styles.text}>{auction.charityDescription}</Typography> 
                }
              </div>
            </Grid>

            {auction.charityImage &&
              <Grid item xs={12} md={4} style={{ textAlign: 'center' }}>
                    <img className={styles.image} src={`data:image/png;base64,${auction.charityImage}`} alt={'charityImage'}/>
              </Grid>
            }
          </Grid>
        </>,
      )}

      <Divider style={{ margin: '20px' }}/>
    </Box>
  );
}