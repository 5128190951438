import React from 'react';
import {
  createStyles, withStyles, Theme, WithStyles,
  AppBar, Tabs, Tab, Typography, Box, CircularProgress,
} from '@material-ui/core';
import ShippingTable from './ShippingTable';
import NonshippingTable from './NonshippingTable';
import ItemTable from './ItemTable';

import { IAuction } from '../../Interfaces/Database/Auction';
import { IUser } from '../../Interfaces/Database/User';


// The Store!
import {
  IRootStore,
  RootContext,
  withAppContext,
} from '../../Store/RootStore';
import { PurchaseItemPkg } from '../../Interfaces/Database/Invoice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



const styles = (theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  load: {
    margin: 'auto',
  },
});


export interface IByAddressUser {
  address: string | undefined,
  user: IUser,
  items: PurchaseItemPkg[],
}

interface IProps extends WithStyles<typeof styles> {
  auction: IAuction,
  context: IRootStore,
}

interface IState {
  tabValue: number,
  purchaseData: PurchaseItemPkg[],
  loadingData: boolean,
  switchingTo: number,
}


class ItemDeliveryTabs extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      tabValue: 0,
      purchaseData: [],
      loadingData: false,
      switchingTo: 0,
    };

    //Bind Member Methods
    this.a11yProps = this.a11yProps.bind(this);
  }


  componentDidMount() {

    const { actions } = this.props.context;
    const { auction } = this.props;

    this.setState({ loadingData: true });

    actions.queryAuctionReport(auction._id)
      .then(() => {
        this.setState({ loadingData: false });
      })
      .catch( err => console.log('🍋', err) );
  }

  componentDidUpdate(previousProps: IProps, previousState: IState) {
    this.a11yProps(this.state.switchingTo);
  }

  private a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
    };
  }



  render() {
    const { tabValue } = this.state;
    const { classes, auction } = this.props;
    const { auctionReportStore } = this.props.context;

    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={tabValue}
            onChange={(e: React.ChangeEvent<unknown>, val: number) => this.setState({ tabValue: val })}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab label={ 
              auctionReportStore[auction._id] && auctionReportStore[auction._id].pickupData  
                ? 'Pickup (' + auctionReportStore[auction._id].pickupData.length + ')' : 'Pickup'}{...this.a11yProps(0)} />
            <Tab label={ 
              auctionReportStore[auction._id] && auctionReportStore[auction._id].shippingData  
                ? 'Shipping (' + auctionReportStore[auction._id].shippingData.length + ')' : 'Shipping' }{...this.a11yProps(1)} />
            <Tab label={ 
              auctionReportStore[auction._id] && auctionReportStore[auction._id].noMethodData  
                ? 'Pending (' + auctionReportStore[auction._id].noMethodData.length + ')' : 'Pending' }{...this.a11yProps(2)} />
            <Tab label={ 
              auctionReportStore[auction._id] && auctionReportStore[auction._id].notSoldData  
                ? 'Not Sold (' + auctionReportStore[auction._id].notSoldData.length + ')' : 'Not Sold' }{...this.a11yProps(3)} />
          </Tabs>
        </AppBar>

        {!auctionReportStore[auction._id]
          ?
          <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
            <CircularProgress size='2.5rem' className={classes.load} />
          </div>
          : <>
            <TabPanel value={tabValue} index={0} >
              <NonshippingTable
                auctionID={auction._id} 
                type={'PICKUP'}
              />
            </TabPanel>

            <TabPanel value={tabValue} index={1}>
              <ShippingTable auctionID={auction._id}/>
            </TabPanel>

            <TabPanel value={tabValue} index={2}>
              <NonshippingTable 
                auctionID={auction._id} 
                type={'NO_METHOD'}  
              /> 
            </TabPanel>

            <TabPanel value={tabValue} index={3}>
              <ItemTable auctionID={auction._id}/> 
            </TabPanel>
          </>}

      </div>
    );
  }
}

// Wrap Hooks with HOC
export default withAppContext(
  RootContext, withStyles(styles)(ItemDeliveryTabs),
);