import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { AuctionTypes } from '../../Interfaces/Database/Auction';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    formControl: {
      margin: theme.spacing(3),
    },
  }),
);

interface IProps {
  typeSelected: AuctionTypes,
  optionSelected: boolean,
  readOnly: boolean,
  onChangeType: (type: AuctionTypes) => void,
  onChangeOption: (option: boolean) => void,
}

export default function AuctionTypeSelector({ typeSelected, optionSelected, readOnly, onChangeType, onChangeOption }: IProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Auction Type</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={typeSelected === 'generalOnline'} disabled={readOnly} onChange={() => onChangeType('generalOnline')} name="generalOnline" />}
            label="Online Auction"
          />
          <FormControlLabel
            control={<Checkbox checked={typeSelected === 'liveAndOnline'} disabled={readOnly} onChange={() => onChangeType('liveAndOnline')} name="liveAndOnline" />}
            label="Live + Online"
          />
        </FormGroup>
      </FormControl>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Options</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox checked={!optionSelected} disabled={readOnly} onChange={() => onChangeOption(false)} name="public"/>}
            label="Public"
          />
          <FormControlLabel
            control={<Checkbox checked={optionSelected} disabled={readOnly} onChange={() => onChangeOption(true)} name="internal-testing"/>}
            label="Internal Testing"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
}