import React, { useContext } from 'react';
import {
  makeStyles, Theme, createStyles,
  Drawer, List, ListItemText, Hidden, ClickAwayListener, 
} from '@material-ui/core';
import { colors } from '../../Config/Global';


// Store & Interfaces
import { RootContext } from '../../Store/RootStore';
import { IAuction } from '../../Interfaces/Database/Auction';
import AccordionTabs from './AccordionTabs';

const drawerWidthLarge = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidthLarge,
      flexShrink: 0,
      height: '100%',
    },
    drawerPaper: {
      paddingTop: '40px',
      width: drawerWidthLarge,
      backgroundColor: colors.navBarMain,
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '1em',
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: `inset 0 0 6px ${colors.navBarSecondary}`,
        webkitBoxShadow: `inset 0 0 6px ${colors.navBarSecondary}`,
        //backgroundColor: colors.navBarSecondary
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.navBarSecondary,
        outline: '1px solid slategrey',
      },
    },
    userType: {
      textAlign: 'center',
      margin: '20px 0px 5px',
      color: colors.navBarSecondary, 
      fontSize: '18px',
    },
  }),
);

interface IProps {
  isOpen: boolean,
  onClose: () => void,
  auctions: IAuction[],
  auctionsAdmin: IAuction[],
  onCreateAuction: () => void, 
}

export default function Navbar({isOpen, onClose, auctions, auctionsAdmin, onCreateAuction }: IProps): JSX.Element {
  // Attach Hooks
  const classes = useStyles();
  const store = useContext(RootContext);

  // Check if User is Admin
  const isAdmin = React.useMemo(() => {
    return store.user?.permissions?.includes('admin');
  }, [ store.user ]);

  
  // Navigation
  const [openAuctions, setOpenAuctions] = React.useState<IAuction[]>([]); 
  const [futureAuctionsPublic, setFutureAuctionsPublic] = React.useState<IAuction[]>([]);  
  const [futureAuctions, setFutureAuctions] = React.useState<IAuction[]>([]); //Admin only
  const [closedAuctions, setClosedAuctions] = React.useState<IAuction[]>([]); //Admin only
  const [pastAuctions, setPastAuctions] = React.useState<IAuction[]>([]); //Admin only
  const [pastCharityAuctions, setPastCharityAuctions] = React.useState<IAuction[]>([]); //Admin only


  React.useEffect(() => {
    if(auctions){
      //UserView
      setOpenAuctions(auctions.filter(el => el.state === 2));
      setFutureAuctionsPublic(auctions.filter(el => el.state === 1).reverse());

      if(auctionsAdmin){
        //Admin only
        const past = auctionsAdmin.filter(el => el.state === 4);
        setPastAuctions(past.filter(el => el.charityName === undefined));
        setPastCharityAuctions(past.filter(el => el.charityName));
        // Data Queried in descending order to largest section
        // Rest of the auctions should be displayed in ascending order:
        const restAuctions = (auctionsAdmin.filter(el => el.state !== 4)).reverse();
        setFutureAuctions(restAuctions.filter(el => (el.state === 0 || el.state === 1)));
        setClosedAuctions(restAuctions.filter(el => el.state === 3));
      }
    }
  }, [auctions, auctionsAdmin]);



  const drawerTabs = () => {
    return (
    <div className={classes.drawer}>

      {/* -------------------User View----------------- */}
      <List>

        {isAdmin &&
          <ListItemText primary='Public' classes={{primary: classes.userType}}/>
        }

        <AccordionTabs
          label='Home'
          auctionData={[]}
          adminView={false}
          type={'no-expand'}
          onClick={onClose}
        />

        <AccordionTabs
          label='Open Auctions'
          auctionData={openAuctions}
          adminView={false}
          type={'expand'}
          onClick={onClose}
        />

        <AccordionTabs
          label='Future Auctions'
          auctionData={futureAuctionsPublic}
          adminView={false}
          type={'expand'}
          onClick={onClose}
        />

        <AccordionTabs
          label='Charity'
          auctionData={[]}
          adminView={false}
          type={'no-expand'}
          onClick={onClose}
        />

        <AccordionTabs
          label='FAQ'
          auctionData={[]}
          adminView={false}
          type={'no-expand'}
          onClick={onClose}
        />

      </List>

      {/* -------------------Admin View----------------- */}
      {isAdmin &&
      
        <List>

          <ListItemText classes={{ primary: classes.userType }} primary="Private"/>

          <AccordionTabs
            label='Users'
            auctionData={[]}
            adminView={true}
            type={'no-expand'}
            onClick={onClose}
          />

          <AccordionTabs
            label='Future Auctions'
            auctionData={futureAuctions}
            onCreate={() => {onCreateAuction();}}
            adminView={true}
            type={'expand'}
            onClick={onClose}
          />

          <AccordionTabs
            label='Closed Auctions'
            auctionData={closedAuctions}
            adminView={true}
            type={'expand'}
            onClick={onClose}
          />

          <AccordionTabs
            label='Past Auctions'
            auctionData={pastAuctions}
            adminView={true}
            type={'expand'}
            onClick={onClose}
          />

          <AccordionTabs
            label='Past Charity Auctions'
            auctionData={pastCharityAuctions}
            adminView={true}
            type={'expand'}
            onClick={onClose}
          />

        </List>
      
      }
    </div>
    );
  };

  return (
    <>
    {/* Visible for Screens smaller than large */}
    <Hidden lgUp >
      <Drawer
        variant="temporary"
        anchor="left"
        open={isOpen}
        onClose={() => {onClose();}}
        classes={{ paper: classes.drawerPaper }}
      > 
        {drawerTabs()}
      </Drawer>
    </Hidden>

    {/* Visible for Large Screens Large */}
    <Hidden mdDown >
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
      > {drawerTabs()}
      </Drawer>
    </Hidden>
  </>
  );
}
