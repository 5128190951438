import React, { useContext } from 'react';
import ItemDeliveryTabs from './ItemDeliveryTabs';
import { IAuction } from '../../Interfaces/Database/Auction';
import { colors } from '../../Config/Global';
import clsx from 'clsx';
import { ICategoryMap } from '../../App';
import PaymentsTable from './PaymentsTable';
import AuctionHeader from '../../Components/Auction/AuctionHeader';

import {
  Box, Container, Typography,
  makeStyles,
  Button,
} from '@material-ui/core';
import ItemsList from '../../Components/ItemsList';
import Report from './Report';

// Local Styles
const useStyles = makeStyles({
  root: {
  },
  infoBar: {
    width: '100%',
  },
  main: {
    backgroundColor: colors.offWhite,
    minHeight: '500px',
    paddingTop: '30px',
  },
  text: {
    padding: 5,
    textAlign: 'center',
    color: colors.darkBlueFont,
  },
  charityText: {
    margin: 'auto',
    padding: '4px',
    textAlign: 'center',
    color: colors.darkBlueFont,
    backgroundColor: '#ffe699', 
    borderRadius: 5, 
  },
  tab: {
    border: '1px solid black',
    borderBottom: 0,
    borderRadius: '2px',
    margin: '0px 5px -2px',
    padding: '7px',
    paddingBottom: '7px',
  },
  viewing: {
    border: '2px solid black',
    borderBottom: 0,
    borderRadius: '2px',
    margin: '0px 5px -2px',
    padding: '7px',
    paddingBottom: '7px',
    backgroundColor: colors.offWhite,
  },
  hide: {
    display: 'none',
  },
});

interface IProps {
  auction: IAuction,
  categories: ICategoryMap,
}

export default function Home({ auction, categories }: IProps) {
  const styles = useStyles();
  const [viewing, setViewing] = React.useState<string>('items'); // items, delivery, purchases, report

  return (
    <Container className={styles.root}>

        <Box className={styles.infoBar}>
          <AuctionHeader auction={auction} />
          <Button className={clsx(styles.tab, {[styles.viewing]: viewing === 'items'})}  onClick={() => setViewing('items')}> Items </Button>
          <Button className={clsx(styles.tab, {[styles.viewing]: viewing === 'delivery'})} onClick={() => setViewing('delivery')}> Delivery</Button>
          <Button className={clsx(styles.tab, {[styles.viewing]: viewing === 'payments'})} onClick={() => setViewing('payments')}>Payments</Button>
          <Button className={clsx(styles.tab, {[styles.viewing]: viewing === 'report'})} onClick={() => setViewing('report')}>Report</Button>
        </Box>

        <Box className={styles.main}>
          {viewing === 'items' &&
            <ItemsList 
              auction={auction} 
              categories={categories}
              onLogin={() => console.log('no login option')}
            />
          }

          {viewing === 'delivery' &&
            <ItemDeliveryTabs auction={auction}  />
          }

          {viewing === 'payments' &&
            <PaymentsTable auction={auction}  />
          }

          {viewing === 'report' &&
            <Report auctionID={auction._id}/>
          }
        </Box>

      
    </Container>
  );
}