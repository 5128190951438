// All-in-one Global Configuration for APIs/URLs
export default {
  // PROD
  SERVER_URL: 'https://auction-prod.herokuapp.com/',
  SQUARE_SCRIPT: 'https://web.squarecdn.com/v1/square.js',
  SQUARE_APP_ID: 'sq0idp-Crj7OCnBarJCSWk2gtk-zA',
  SQUARE_LOCATION_ID: 'L40EJTTW1018Y',
  HOST: 'royaletreasuresauction.com',
  HOST_STAFF: 'staff.royaletreasuresauction.com'

  // LOCAL
  // SERVER_URL: 'http://localhost:3030',
  // SQUARE_SCRIPT: 'https://sandbox.web.squarecdn.com/v1/square.js',
  // SQUARE_APP_ID: 'sandbox-sq0idb-qUn5noNNMYagX8TV5EDIWQ',
  // SQUARE_LOCATION_ID: 'LHM9EJX0RZ486',
  // HOST: 'localhost:3000',
  // HOST_STAFF: 'localhost:3001'
};


const colors = {
  navBarMain: '#59363F',            // Maroon
  navBarMain1up: '#5f3a43',
  navBarMain2up: '#6f444f',
  navBarMain3up: '#7e4e5a',

  navBarSecondary: '#9BABBF',       // Light Blue
  navBarSecondary2up: '#b1becd', 
  navBarSecondary5up: '#e0e5eb',
  navBarSecondary6up: '#eff2f5',
  narBarSecondaryNearWhite: '#e0e5eb', 

  headerMain: '#f3d8d8',            // Mauve
  headerSecondary: '#e5d5cd',

  lightFont: 'white',
  darkFont: 'black',
  darkBlueFont: '#364b61',

  offWhite: '#eff2f5',
  errorRed: '#e74c3c ',
  successGreen: '#56D64C',
  primaryButton: '#3f51b5',

  closedAuctionInfo: '#ddc9be',     // Gold

};

export { colors };