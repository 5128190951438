import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { RootContext } from '../../Store/RootStore';
import {
  Dialog, DialogActions, DialogTitle, DialogContent,
  TextField, Button, DialogContentText, CircularProgress,
} from '@material-ui/core';
import { IAuthManagement } from '../../Interfaces/Database/User';
import { Service, ServiceMethods } from '@feathersjs/feathers';


interface Props {
  locationSearch: any,
}

interface state {
  success: boolean,
  message?: string,
}

export default function VerifyEmail(props: Props): JSX.Element {
  // Store & States
  const store = React.useContext(RootContext);
  const { client } = store;
  const [state, setState] = React.useState<state | null>(null);
  const [open, setOpen] = React.useState<boolean>(true);


  React.useEffect(() => {

    // Obtain Token from URL
    const token = props.locationSearch.split('?token=').pop();

    // Submit email verification
    if(!client) return;
    const authManagement: ServiceMethods<IAuthManagement> = client.service('authManagement');
    authManagement.create({
      action: 'verifySignupLong',
      value: token,
    })
      .then(() => setState({ success: true }))
      .catch(err => setState({ success: false, message: err.message }));

  }, [ client ]);

  
  return (
    <Dialog open={open}>
      <DialogTitle>Email Verification</DialogTitle>

      {!state

        ? // Waiting for Response
          <DialogContent>

            <DialogContentText variant='subtitle2'>
              Verification is in process
            </DialogContentText>
            
            <div style={{ width: '100%', textAlign: 'center'}}>
              <CircularProgress size='1rem' style={{ margin: 'auto' }} />
            </div>
          </DialogContent>


        : // Verification Returned
          <DialogContent>

            <DialogContentText variant='subtitle2'>
              {state.success 
                ? 'Your email has been successful updated'
                : state.message
              } 
            </DialogContentText>

          </DialogContent>
      }

      <DialogActions>
        <RouterLink to={'/'} style={{ textDecoration: 'none' }}>
          <Button onClick={() => setOpen(false)}>
            Close
          </Button>
        </RouterLink>
      </DialogActions>

    </Dialog>

  );
};