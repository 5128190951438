import React from 'react';
import { 
  makeStyles,
  List, Button,
  ListItem, ListItemText, ListItemIcon,
  Dialog, DialogTitle, DialogContent,
  DialogActions, TextField, DialogContentText, CircularProgress, Typography,
} from '@material-ui/core';
import {
  Email, PhoneAndroid,
  PermContactCalendar, PersonOutline, Lock, PinDrop,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import PhoneNumberFormat from '../../Helpers/PhoneNumberFormat';
import { RootContext } from '../../Store/RootStore';
import { Service, ServiceMethods } from '@feathersjs/feathers';
import { IAuthManagement, IUser } from '../../Interfaces/Database/User';


// Styles
const useStyles = makeStyles({
  root: {
    flex: 1,
    width: '25rem',
    overflow: 'hidden',
  },
});

// User-Defined Types
type ProfileModType = 'Email' | 'Phone Number' | 'Address' | 'Password';

interface IModProfileProps {
  label: ProfileModType | null,
  isOpen: boolean,
  onClose: () => void,
}


function ModifyProfile(props: IModProfileProps) {
  // Hooks
  const { client, user, actions } = React.useContext(RootContext);
  
  // States
  const [password, setPassword] = React.useState<string>(''); // value
  const [newData, setNewData] = React.useState<string>(''); 
  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const [updateLoading, setUpdateLoading] = React.useState<boolean>(false);
  const [success, setSuccess] = React.useState<boolean>(false);


  // Handle Profile Patch
  const handlePatch = () => {
    if(!user) return;

    const authManagement: ServiceMethods<IAuthManagement> = client?.service('authManagement');
    const userService: ServiceMethods<IUser> = client?.service('users');

    //Assume Entry Valid
    setErrorMsg('');

    
    if(props.label === 'Phone Number'){
      // Check Phone Number Valid
      if(newData.length === 12){
        for(let i = 0; i < 12; i++){
          if((newData[i] < '0' || newData[i] > '9') && newData[i] !== '-'){
            setErrorMsg('Invalid Entry');
            return;
          }
        }

        // Valid Phone Number - Patch User
        userService.patch(user._id, { phoneNumber: newData })
          .then(res => {
            setSuccess(true);
            setUpdateLoading(false);
            actions.setUser(res as IUser);
          })
          .catch(err => {
            setErrorMsg('Invalid Entry');
            setUpdateLoading(false);
            console.log('Error', err);
          });
      }
      else {
        setErrorMsg('Invalid Entry');
        return;
      };
    }

    else if(props.label === 'Address'){
      userService.patch(user._id, { address: newData })
        .then(res => {
          setSuccess(true);
          setUpdateLoading(false);
          actions.setUser(res as IUser);
        })
        .catch(err => {
          setErrorMsg('Invalid Entry');
          setUpdateLoading(false);
          console.log('Error', err);
        });
    }

    else if(props.label === 'Email'){
      // Submit new Email!
      authManagement.create({
        action: 'identityChange',
        value: {
          user: { email: user.email },
          password: password,
          changes: { email: newData.toLocaleLowerCase() },
        },
      })
        .then(res => {
          setSuccess(true);
          setUpdateLoading(false);
          actions.setUser(res as IUser);
        })
        .catch(err => {
          setErrorMsg('Invalid Entry');
          setUpdateLoading(false);
          console.log('Error', err);
        });
    }

    else if(props.label === 'Password'){
      // Submit new Password!
      authManagement.create({
        action: 'passwordChange',
        value: {
          user: { email: user.email },
          oldPassword: password,
          password: newData,
        },
      })
        .then(res => {
          setSuccess(true);
          setUpdateLoading(false);
          actions.setUser(res as IUser);
        })
        .catch(err => {
          setErrorMsg('Invalid Entry');
          setUpdateLoading(false);
          console.log('Error', err);
        });
    } 
    
  };

  const closeModal = () => {
    setPassword('');
    setNewData('');
    setErrorMsg('');
    setUpdateLoading(false);
    setSuccess(false);
    props.onClose();
  };
  
  return (
    <Dialog onClose={() => closeModal()} open={props.isOpen}>

      <DialogTitle style={{ textAlign: 'center' }}>
        <CloseIcon style={{float: 'right'}} onClick={() => closeModal()} />
        Update Your {props.label}
      </DialogTitle>

      {!success 
        ? //Input Information
          <DialogContent>
            <TextField
              margin="dense"
              type="Password"
              fullWidth
              label={props.label === 'Password' ? 'Old Password' : 'Password'}
              helperText={errorMsg ? 'Invalid Entry' : ''}
              error={errorMsg.length > 0}
              onChange={val => setPassword(val.currentTarget.value)}
            />


            {props.label === 'Phone Number'
              ?
                <TextField
                  fullWidth
                  margin="dense"
                  label={props.label}
                  onChange={val => setNewData(val.currentTarget.value)}
                  InputProps={{
                    inputComponent: PhoneNumberFormat as any,
                  }}
                  helperText={errorMsg ? 'Invalid Entry' : ''}
                  error={errorMsg.length > 0}
                />
              :
                <TextField
                  type={props.label === 'Password' ? 'password' : 'text'}
                  fullWidth
                  margin="dense"
                  label={props.label === 'Password' ? 'New Password' : props.label}
                  helperText={errorMsg ? 'Invalid Entry' : ''}
                  error={errorMsg.length > 0}
                  onChange={val => setNewData(val.currentTarget.value)}
                />
            }
             <DialogActions>
              <Button onClick={() => closeModal()}>Cancel</Button>
              <Button 
                disabled={updateLoading}
                onClick={() => { handlePatch(); setUpdateLoading(true); }}
              >
                {updateLoading 
                  ? <div style={{ width: '100%', textAlign: 'center'}}>
                      <CircularProgress size='1rem' style={{ margin: 'auto' }} />
                    </div>
                  : <Typography>Submit</Typography>
                }
              </Button>

            </DialogActions>
          </DialogContent>
          
        : // Successful Update
          <DialogContent>

            <DialogContentText>
              {props.label === 'Email' 
                ? 'An message has been sent to your inputted email address for verification.'
                : 'Your information has been successfully updated.'
              }  
            </DialogContentText>

            <DialogActions>
              <Button onClick={() => closeModal()}>Close</Button>
            </DialogActions>

          </DialogContent>

      }

    </Dialog>
  );
};


interface IProfileProps {
  isOpen: boolean,      // Dialog State
  onClose: () => void,  // Close Callback
}
export default function Profile(props: IProfileProps): JSX.Element {
  // Hooks
  const styles = useStyles();
  const { user } = React.useContext(RootContext);

  // States
  const [modifyProfile, setModifyProfile] = React.useState<ProfileModType | null>(null);

  return (
    <>
      {/* Dialog for Modifications */}
      <ModifyProfile
        label={modifyProfile || null}
        isOpen={modifyProfile !== null}
        onClose={() => setModifyProfile(null)}
      />

      <Dialog onClose={() => props.onClose()} open={Boolean(props.isOpen)}>

        <DialogTitle style={{ textAlign: 'center' }}>
          <CloseIcon style={{float: 'right'}} onClick={() => props.onClose()} />
          Account Details
        </DialogTitle>
        
        <List component="nav" className={styles.root}>

          <ListItem>
            <ListItemIcon> <PermContactCalendar /> </ListItemIcon>
            <ListItemText primary="Name" secondary={`${user?.firstName} ${user?.lastName}`}/>
          </ListItem>

          <ListItem>
            <ListItemIcon> <PersonOutline /> </ListItemIcon>
            <ListItemText primary={'Bidder Number'} secondary={user?.bidderNumber} />
          </ListItem>

          <ListItem button onClick={() => setModifyProfile('Email')}>
            <ListItemIcon> <Email /> </ListItemIcon>
            <ListItemText primary='Email' secondary={user?.email} />
            {'Edit'}
          </ListItem>

          <ListItem button onClick={() => setModifyProfile('Phone Number')}>
            <ListItemIcon> <PhoneAndroid /> </ListItemIcon>
            <ListItemText primary='Phone Number' secondary={user?.phoneNumber} />
            {'Edit'}
          </ListItem>

          <ListItem button onClick={() => setModifyProfile('Address')}>
            <ListItemIcon> <PinDrop/> </ListItemIcon>
            <ListItemText primary='Address' secondary={user?.address} />
            {'Edit'}
          </ListItem>

          <ListItem button onClick={() => setModifyProfile('Password')}>
            <ListItemIcon> <Lock /> </ListItemIcon>
            <ListItemText primary='Password' secondary={'********'}/>
              {'Edit'}
          </ListItem>


        </List>
      </Dialog>
    </>
  );
};