import React, { useContext } from 'react';
import {
  makeStyles, Paper, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
} from '@material-ui/core';
import { RootContext } from '../../Store/RootStore';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 440,
  },
});


interface IProps{
  auctionID: string,
}

export default function ItemTable({ auctionID }: IProps) {
  const classes = useStyles();
  const store = useContext(RootContext);
  const { notSoldData } = store.auctionReportStore[auctionID];
  
  return (

    <Paper className={classes.root}>
      <TableContainer className={classes.container}>

        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell style={{width: '100px'}}><strong>Item #</strong></TableCell>
              <TableCell ><strong>Title</strong></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {notSoldData.map(elt => (
              <TableRow key={elt._id}>
                <TableCell style={{width: '100px'}} component='th' scope='row'>{elt.itemNumber}</TableCell>
                <TableCell component='th' scope='row'>{elt.title}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

      </TableContainer>
    </Paper>
  );
}