import React, { useContext } from 'react';
import { IAuction } from '../../Interfaces/Database/Auction';
import { colors } from '../../Config/Global';
import { 
  IconButton, makeStyles, Paper, Typography, 
} from '@material-ui/core';
import {
  Settings as SettingsIcon,
} from '@material-ui/icons';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { RootContext } from '../../Store/RootStore';
import CreateAuctionStepper from './CreateAuctionStepper';
import AuctionStaffDialog from './AuctionStaffDialog';

const useStyles = makeStyles(theme => ({
  text: {
    textAlign: 'center',
    color: colors.darkBlueFont,
  },
  title: {
    color: colors.darkBlueFont,
    display: 'inline-block',
    width: '150px',
    fontWeight: 'bold',
    verticalAlign: 'top'
  },
  description: {
    color: colors.darkBlueFont,
    display: 'inline-block',
    overflowWrap: 'anywhere',
    maxWidth: '800px'
  },
  infoContainer: {
    width: 'fit-content',
    margin: 'auto',
    padding: '10px'
  },
  charityText: {
    margin: 'auto',
    padding: '4px',
    textAlign: 'center',
    color: colors.darkBlueFont,
    backgroundColor: '#ffe699', 
    borderRadius: 5, 
  },
}));


interface IProps {
  auction: IAuction,
  onAuctionUpdate?: (id: string | null, auction: Partial<IAuction>) => Promise<any>,
  onAuctionDelete?: (id: string, history: History) => void,
}

export default function AuctionHeader({ auction, onAuctionUpdate, onAuctionDelete }: IProps) {
  const styles = useStyles();
  const store = useContext(RootContext);

  const [modAuctionOpen, setModAuctionOpen] = React.useState<boolean>(false);
  const [staffDialogOpen, setStaffDialogOpen] = React.useState<boolean>(false);
  const [width, setWidth] = React.useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  React.useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  const startDate = React.useMemo(() => {
    const d = new Date(auction.startDate).toLocaleString();
    // Remove Milliseconds
    return d.slice(0, -6).concat(d.slice(-2));
  }, [ auction.startDate ]);

  
  const endDate = React.useMemo(() => {
    if (auction.endDate) {
      const d = new Date(auction.endDate).toLocaleString();
      // Remove Milliseconds
      return d.slice(0, -6).concat(d.slice(-2));
    } else {
      return '';
    }
  }, [ auction.endDate ]);

  const isAdmin = React.useMemo(() => {
    return store.user?.permissions?.includes('admin');
  }, [ store.user ]);

  return (
    <>

      <AuctionStaffDialog
        isOpen={staffDialogOpen}
        onClose={() => setStaffDialogOpen(false)}
        auctionID={auction._id}
      />

      {isAdmin && 
        <CreateAuctionStepper
          auction={auction}
          isOpen={modAuctionOpen}
          onClose={() => setModAuctionOpen(false)}
          onUpdateOrAdd={onAuctionUpdate ? onAuctionUpdate : () => Promise.reject()}
          onDelete={onAuctionDelete ? onAuctionDelete : () => Promise.reject()}
        />
      }
    
      <Typography className={styles.text} style={{padding: 10}}variant="h5">{auction.name} Auction</Typography> 
      {auction.charityName &&
        <Typography className={styles.charityText} variant="h6">{auction.charityName} Charity</Typography> 
      }

      <Paper style={{ margin: '15px 0' }}>
        <div className={styles.infoContainer}>
          <div>
            <Typography className={styles.title} variant="subtitle1">Auction Type:</Typography>
            <Typography className={styles.description} variant="subtitle1">
              {auction.type === 'liveAndOnline' ? 'Live + Online' : 'Online'}
              {auction.isTesting ? ' (INTERNAL TESTING)' : ''}
            </Typography>
          </div>
          {auction.charityDescription &&
            <div>
              <Typography className={styles.title} variant="subtitle1">Charity Info:</Typography>
              <Typography className={styles.description} variant="subtitle1">{auction.charityDescription}</Typography>
            </div>
          }

          {auction.type === 'liveAndOnline' && auction.liveLocation &&
            <div>
              <Typography className={styles.title} variant="subtitle1">Location:</Typography>
              <Typography className={styles.description} variant="subtitle1">{auction.liveLocation}</Typography>
            </div>
          }
          <div>
            <Typography className={styles.title} variant="subtitle1" style={{ color: 'orange' }}>Time:</Typography>
            <Typography className={styles.description}  style={{ color: 'orange' }}>{auction.state <=1 ? 'Opens: ' : 'Opened: '} {startDate}</Typography>
            {auction.endDate && 
              <Typography className={styles.description}  style={{ color: 'orange' }}>,&emsp;{auction.state <= 2 ? 'Closes: ' : 'Closed: ' }{endDate}</Typography>
            }
          </div>
          {auction.type === 'liveAndOnline' && auction.preBiddingEnabled &&
            <div>
              <Typography className={styles.title} style={{ color: 'blue' }}>Pre-Bidding:</Typography>
              {auction.preBiddingOpen 
                ? <Typography className={styles.description} style={{ color: 'blue' }}>OPEN NOW&emsp;Ends: {new Date(auction.preBiddingEnd as any).toLocaleString()}</Typography>
                : <Typography className={styles.description} style={{ color: 'blue' }}>
                    {new Date(auction.preBiddingStart as any) > new Date() ? 'Starts: ' : 'Started: '} {new Date(auction.preBiddingStart as any).toLocaleString()},&emsp;
                    {new Date(auction.preBiddingEnd as any) > new Date() ? 'Ends: ' : 'Ended: ' } {new Date(auction.preBiddingEnd as any).toLocaleString()}
                  </Typography>
              }
            </div>
          }
          {auction.additionalInformation &&
            <div style={{  marginTop: '10px' }}>
              <Typography className={styles.title} style={{ fontStyle: 'italic', }}>Additional Info:</Typography>
              <Typography className={styles.description}>{auction.additionalInformation}</Typography>
            </div>
          }
        </div>
      </Paper>


      {/* Admin View - Modify Future & Open Auctions Name and Timeframe */}
      {isAdmin &&
        <span style={isMobile ? { display: 'block', marginBottom: '10px' } : {}}>
          <IconButton onClick={() => setModAuctionOpen(true)} style={{ padding: 0, margin: '0 10px', color: 'orange' }} component='span'>
            <SettingsIcon style={{ width: '1em', height: '1em' }} />
            <Typography>Settings</Typography>
          </IconButton>
          {auction.type === 'liveAndOnline' &&
            <IconButton onClick={() => setStaffDialogOpen(true)} style={{ padding: 0, margin: '0 10px', color: 'blue' }} component='span'>
              <SupervisorAccountIcon style={{ width: '1em', height: '1em' }} />
              <Typography>Staff</Typography>
            </IconButton>
          }
        </span>
      }
      
    </>
  );
}