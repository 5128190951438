 import React from 'react';
import {
  Dialog, DialogContent,
  TextField, DialogContentText, 
  DialogActions, Button, DialogTitle, FormControl,
  InputLabel, Select, MenuItem,
  makeStyles, Box,
  FormControlLabel, Checkbox, TextareaAutosize, Typography, Grid, CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { IPurchase } from '../../Interfaces/Database/Purchase';
import { IItem } from '../../Interfaces/Database/Item';
import { RootContext } from '../../Store/RootStore';
import { IAuction } from '../../Interfaces/Database/Auction';
import { Service } from '@feathersjs/feathers';
import { IHistoryData } from '.';


// Local Styles
const useStyles = makeStyles({
  root: {
    minWidth: '25vw',
  },
  form: {
    marginTop: '1rem',
    width: '200px',
  },
  infoBar: {
    width: '100%',
    borderRadius: '3px',
  },
});



interface Props {
  purchase: IHistoryData | null,
  onClose: () => void,
  onDone: (addr: string | null) => Promise<any>,
}

export default function AddressInputDialog ({purchase, onClose, onDone}: Props) {
  // States
  const styles = useStyles();
  const { user, authToken, client } = React.useContext(RootContext);
  const [addressError, setAddressError] = React.useState<boolean>(false);
  const [method, setMethod] = React.useState<number>(0);  // pending: 0, pickup: 1, shipping: 2
  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const [auction, setAuction] = React.useState<IAuction>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);


  // Input References
  const addressRef = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    if(!client || !purchase) return;
    // Get Auction for Pickup Information
    const auctionsService: Service<IAuction> = client.service('auctions');
    
    const headers = authToken ? { Authorization: authToken } : undefined;
    
    auctionsService.get(purchase.auctionID, {
      headers,
      user: user || undefined,
    })
      .then((res: IAuction) =>{
        setAuction(res);
      })
      .catch(err => console.log('ERR: ', err));

  }, [ purchase ]);

  
  // Input Checks & Notify Parent
  const submit = () => {

    setErrorMsg('');
    
    // Shipping
    if (method === 2) {
      if (!addressRef.current?.value) {
        setAddressError(true);
        return addressRef.current?.focus();
      } 
      else {
        setAddressError(false);
      }

      // All is Good!!!
      setIsLoading(true);
      onDone(addressRef.current.value.trim())
        .finally(() => setIsLoading(false));
    }

    // Pickup
    else {
      setIsLoading(true);
      onDone(null)
        .finally(() => setIsLoading(false));
    }
  };

  const formatPickupInformation = () => {
    if(auction){
      return auction.pickupInformation.split('\n').map((str, index)=> <p key={index} style={{ margin: 0 }}>{str}</p>);
    }else {
      return <p style={{ margin: 0 }}>6103 M 80, Kinross, MI 49752</p>;
    }
  };


  return (
    <Dialog open={purchase ? true : false}>
      {purchase && auction &&
        <DialogContent className={styles.root}>
          <Box className={styles.infoBar}>
            <CloseIcon style={{float: 'right'}} onClick={() => onClose()} /> 
          </Box>
          <DialogTitle style={{ textAlign: 'center' }}>Confirm Item Purchase</DialogTitle>
          <Typography>
            Item #{purchase.item.itemNumber}: {purchase.item.title}
          </Typography>  
          <Typography>
            Purchase price: ${purchase.itemCost}
          </Typography>
          <Typography style={{ marginTop: '10px', fontStyle: 'italic' }}>
            Once all pending items for the auction are confirmed, the payment will be processed with the card on file. 
            There will be a {auction.servChargePercent * 100}% credit card service charge added to the total.
          </Typography>
          <Typography style={{ marginTop: '25px', marginBottom: '0px', fontWeight: 'bold' }}>
            Please select a delivery option:
          </Typography>
          
          {/* Pick-up */}
          <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={method === 1}
                    onChange={event => setMethod(event.target.checked ? 1 : 0)}
                    name="isPickup"
                    color="primary"
                  />
                }
                label="Pickup"
              />
            </Grid>

            <Grid item xs={12} md={9} style={{ marginTop: 10 }}>
              <DialogContentText style={{ marginLeft: 30 }}>
                {formatPickupInformation()}
              </DialogContentText>
            </Grid>
          </Grid>

          {/* Shipping */}
          {purchase.item.shippingCost === -1 
            ? 
              <DialogContentText>
                (Item is Pickup only)
              </DialogContentText>
            :
              <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={method === 2}
                        onChange={event => setMethod(event.target.checked ? 2 : 0)}
                        name="isShipping"
                        color="primary"
                      />
                    }
                    label="Shipping"
                  />
                </Grid>

                <Grid item xs={12} md={9} style={{ marginTop: 10 }}>
                  <DialogContentText style={{ marginLeft: 30 }}>
                    {purchase.item.shippingCost > 0
                        ? `$${purchase.item.shippingCost.toFixed(2)} ${purchase.item.shippingType}`
                        : 'Free'
                      }
                  </DialogContentText>
                </Grid>
              </Grid>
          }
          
          {method === 2 && <>
            <TextField
              margin="normal"
              id="address"
              label="Address"
              fullWidth
              multiline
              error={addressError}
              helperText={addressError ? 'Invalid Address.' : null}
              inputRef={addressRef}
              defaultValue={user?.address}
            />
          </>
          }
          
        </DialogContent>
      }

      <DialogActions style={{ flexDirection: 'column', justifyContent: 'center', marginBottom: '10px' }}>
        <p>{errorMsg}</p>
        <Button disabled={method === 0 || isLoading} onClick={() => submit()} color="primary" variant='contained'>
          {isLoading
            ? <CircularProgress size='1.5rem' style={{ margin: 'auto' }} />
            : 'Submit'
          }
        </Button>
      </DialogActions>

      </Dialog >
  );
};