import * as React from 'react';
import { 
  Chip, FormControl, MenuItem, OutlinedInput, Box, Select,
  Checkbox, ListItemText,
} from '@material-ui/core';
import { IUserMap } from '../Auction/CreateAuctionStepper';


const ITEM_HEIGHT = 75;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 250,
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};


interface IProps {
  allStaff: IUserMap,
  selected: string[],
  onChange: (selected: string[]) => void,
}

export default function MultipleSelectChip({ allStaff, selected, onChange }: IProps) {
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    onChange(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <div>
      <FormControl style={{ margin: 1 }} fullWidth>
        <Select
          multiple
          value={selected}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
          renderValue={(selectedValue: any) => (
            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selectedValue.map((email: string) => (
                <Chip 
                  key={email} 
                  label={`${allStaff[email].firstName} ${allStaff[email].lastName}`}
                  style={{ margin: '3px' }}
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps as any}
        >
          {Object.values(allStaff).map(staff => (
            <MenuItem
              key={staff._id}
              value={staff.email}
            >
               <Checkbox checked={selected.includes(staff.email)}/>
                <ListItemText primary={`${staff.firstName} ${staff.lastName}`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}