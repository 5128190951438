import { Service, ServiceMethods } from '@feathersjs/feathers';
import { 
  makeStyles, Box, Button, GridList, Grid,
  Typography, Menu, IconButton, CircularProgress, Dialog, TablePagination, TableRow, TableCell,
} from '@material-ui/core';
import {
  Settings as SettingsIcon, 
} from '@material-ui/icons';
import React, { useContext } from 'react';
import ItemCard from './Item/ItemCard';
import ItemsFilter from './Item/ItemsFilter';
import clsx from 'clsx';


import { RootContext } from '../Store/RootStore';
import { ICategoryMap } from '../App';
import { BidStore, IPageData } from '../Store/Reducers/AuctionReducer';
import { IItem } from '../Interfaces/Database/Item';
import { IAuction } from '../Interfaces/Database/Auction';
import { IWatchlist } from '../Interfaces/Database/Watchlist';
import { colors } from '../Config/Global';
import ItemDetail from './Item/ItemDetail';
import CreateUpdateItem from './Item/CreateUpdateItem';
import { ICategory } from '../Interfaces/Database/Category';
import { IMaxBid } from '../Interfaces/Database/MaxBid';
import { QueryResult } from '../Interfaces/Service';
import Paginate from './Paginate';

const itemsPerPage = 9;
type screenSizes = 'phone' | 'tablet' | 'desktop';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '1035px', 
    margin: 'auto',
    minHeight: '80vh',
    padding: 0,
  },
  main: {
    backgroundColor: colors.offWhite,
    minHeight: '500px',
    contain: 'content',
  },
  text: {
    textAlign: 'center',
    color: colors.darkBlueFont,
  },
  buttons: {
    border: '1px solid black',
    borderRadius: '2px',
    padding: '5px',
    margin: '0px 15px',
  }, 
  filter: {
    backgroundColor: colors.closedAuctionInfo,
    display: 'none',
  },
  tab: {
    border: '1px solid black',
    borderBottom: 0,
    borderRadius: '2px',
    margin: '0px 5px',
    padding: '7px',
    paddingBottom: '15px',
  },
  viewing: {
    border: '2px solid black',
    borderBottom: 0,
    borderRadius: '2px',
    margin:'0px 5px',
    padding: '7px',
    paddingBottom: '15px',
    backgroundColor: colors.offWhite,
  },
  load: {
    margin: 'auto',
  },
  dialogPaper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '70%',
      height: '80%',
    },
  },
  centerChildren: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface IItemWithBid {          
  item:         IItem,             
  highestBid:   BidStore | null,  
  bids:         BidStore[],        
}
export interface IAuctionCategoryData{
  id: string,
  name: string,
  number: number,
  selected: boolean,
}

interface MaxBidMap {
  [itemID: string]: IMaxBid | null,
}

// Watchlist Interfaces
interface WatchlistMap {
  [id: string]: boolean,   // For quick Lookup
}

interface UserWatchlist {
  isUpdated: boolean,
  items: WatchlistMap,
}

interface IProps {
  auction: IAuction,
  categories: ICategoryMap,

  // Callbacks
  onLogin: () => void,
}

export default function ItemsList({ auction, categories, onLogin }: IProps){ 
  const styles = useStyles();
  const store = useContext(RootContext);
  const { client, actions } = store;

  const topOfItemListRef = React.useRef(null);

  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [unfilteredItemsData, setUnfilteredItemsData] = React.useState<IItem[]>([]);
  const [itemsData, setItemsData] = React.useState<IItem[]>([]);
  const [categoryMap, setCategoryMap] = React.useState<ICategoryMap>({});
  const [maxBidMap, setMaxBidMap] = React.useState<MaxBidMap>({});
  const [auctionCategoryData, setAuctionCategoryData] = React.useState<IAuctionCategoryData[]>([]);


  const [allItemsCurrentPage, setAllItemsCurrentPage] = React.useState<number>(0);
  const [allItemsTotal, setAllItemsTotal] = React.useState<number>(0);

  // Item Tab Views
  type LIST_TAB = 'all_items' | 'watchlist_items';
  const [listTab, setListTab] = React.useState<LIST_TAB>('all_items');
  
  //Filtering
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Check if User is Admin
  const isAdmin = React.useMemo(() => {
    return store.user?.permissions?.includes('admin');
  }, [ store.user ]);

  // Copy of a Hash Mapped User Watchlist Items
  const [userWatchlist, setUserWatchlist] = React.useState<UserWatchlist>({
    isUpdated: false,
    items: {},
  });
  const [timeoutID, setTimeoutID] = React.useState<NodeJS.Timeout | null>(null);

  // List of Watchlist Items to Update Server with
  const [pendingWatchlist, setPendingWatchlist] = React.useState<WatchlistMap>({});
  
  // Check Once
  const [watchlistChecked, setWatchlistChecked] = React.useState<boolean>(false);

  const [filter, setFilter] = React.useState<{categories: IAuctionCategoryData[], buyNow: boolean}>({categories: [], buyNow: false});

  React.useEffect(() => {
    const {socket, actions} = store;
    if(socket){
      socket
      .on('items patched', (itemPatched: IItem) => {
        if(auction._id === itemPatched.auctionID){
          actions.addItemToStore(auction._id, itemPatched);
        }
      })
    }
  }, [ store.socket ])

  // Retrieve Data On Component Mount
  React.useEffect(() => {

    if(!client) return;
    // Get Initial Item Data
    setLoadingData(true);
    getItemData(allItemsTotal);

    // Add User's Watchlist to a Map 
    const headers = store.authToken ? { Authorization: store.authToken } : undefined;
    const params = {
      headers,
      user: store.user || undefined,
    };

    // TODO: Do we need watchlistChecked?????
    if (store.user && !watchlistChecked) {
      setWatchlistChecked(true);

      const watchlistServ: ServiceMethods<IWatchlist> = client.service('watchlist');
      watchlistServ.find({
        ...params,
        query: {
          userID: store.user._id,
        } as Partial<IWatchlist>,
      })
        .then((res: any) => res.data)
        .then((data: IWatchlist[]) => setUserWatchlist({
          ...userWatchlist,
          items: data.reduce((prev, curr) => ({ ...prev, [curr.itemID]: true }), {}),
        }))
        .catch(err => console.log('Watchlist Search Error', err));
    }

    //TODO: Remove and Only have in APPPPP
    const categoryService: ServiceMethods<ICategory> = client?.service('categories');
    categoryService.find({
      headers: { Authorization: store.authToken },
      user: store.user || undefined,
      query: { $limit: 0 },
    })
      .then((res: any) => (
        categoryService.find({
          headers: { Authorization: store.authToken },
          user: store.user || undefined,
          query: { $limit: (res as QueryResult<ICategory>).total },
        })
      ))
      .then((res: any) => res.data)
      .then((data: ICategory[]) => {
        const categoryMap = data.reduce((acc, elt) => ({
          ...acc, 
          [elt._id]: elt.name,
        }), {});
        setCategoryMap(categoryMap);
      })
      .catch(err => console.log('Category Error', err));
    
  }, []);

  React.useEffect(() => {
    const items = store.auctionStore[auction._id] ? store.auctionStore[auction._id].items : null;
    if(items && unfilteredItemsData.length !== items.length){
      getItemData(items.length);
    }
  }, [ store.auctionStore[auction._id] ]);


  const getItemData = async (totalItems: number) => {
    if(!client) return;
    
    actions.queryItemsNextPage(auction._id, allItemsCurrentPage, itemsPerPage, totalItems)
      .then((res: IPageData) => {
        setUnfilteredItemsData(res.data);
        handleFilterData(res.data);
        getMaxBidData(res.data);
        setAllItemsTotal(res.totalRows);
        setLoadingData(false);
      })
      .catch(err => {
        setLoadingData(false);
        console.log('Item Error', err);
      });
  };

  const changePage = (data: any) => {
    const newPage = data.selected;
    setAllItemsCurrentPage(newPage);
    setLoadingData(true);
    actions.queryItemsNextPage(auction._id, newPage, itemsPerPage, allItemsTotal)
    .then((res: IPageData) => {
      setUnfilteredItemsData(res.data);
      handleFilterData(res.data);
      getMaxBidData(res.data);
      setAllItemsTotal(res.totalRows);
      setLoadingData(false);
      (topOfItemListRef.current as any).scrollIntoView();
    })
    .catch(err => {
      setLoadingData(false);
      console.log('Item Error', err);
    });
  };

  // Retrieve Max Bid For All Items of the User
  const getMaxBidData = async (items: IItem[]) => {
   const maxBidService: Service<IMaxBid> = client?.service('maxbid');
    const headers = store.authToken ? { Authorization: store.authToken } : undefined;
    const params = {
      headers,
      user: store.user || undefined,
    };

    const userMaxBid: IMaxBid[] = 
      await Promise.all(
        items.map((item) => ( 
          store.user
          ? maxBidService.find({
              params,
              $limit: 1,
              query: {
                userID: store.user._id,
                itemID: item._id,
              },
            })
            .then((res: any) => (res.data.length ? res.data[0] : null ))
          : null
        )));

    const maxBidMap = items.reduce((acc, elt, index) => ({
      ...acc, 
      [elt._id]: userMaxBid[index],
    }), {});

    setMaxBidMap(maxBidMap);
  };

  // Set Auction Category Data For Filter
  React.useEffect(()=>{
    const ret: IAuctionCategoryData[] = [];
    Object.keys(categoryMap).forEach(index => {
      const cat: IAuctionCategoryData = {
        id: index,
        name: categoryMap[index],
        number: unfilteredItemsData.filter(item => item.categoryID === index).length,
        selected: false,
      };
      ret.push(cat);
    });
    setAuctionCategoryData(ret.sort((a, b) =>  b.number - a.number));

  }, [unfilteredItemsData, categoryMap]);

  // Handle updating the Watchlist Server
  React.useEffect(() => {
    const watchlistServ: ServiceMethods<IWatchlist> = client?.service('watchlist');
    const { user, authToken } = store;

    // Early return
    if (!user || !userWatchlist.isUpdated) return;

    // Update the Server after 2 seconds Delay
    if (timeoutID) clearTimeout(timeoutID);
    //@ts-ignore
    setTimeoutID( setTimeout( () => {

      // Add or Remove from Watchlist
      const params = {
        headers: {
          Authorization: authToken,
        },
        user,
      };
      
      Promise.all(Object.keys(pendingWatchlist).map(key =>
        pendingWatchlist[key]
          ? watchlistServ.create({ auctionID: auction._id, userID: user._id, itemID: key }, params)
          : watchlistServ.find({
            ...params,
            query: {
              userID: user._id,
              itemID: key,
            } as Partial<IWatchlist>,
          })
            .then((res: any) => res.data[0])
            .then((data: IWatchlist) => data ? watchlistServ.remove(data._id, params): null),
      ))
        .catch(err => console.log('Watchlist Update Error', err))
        .finally(() => setPendingWatchlist({}));

      setTimeoutID(null);
    }, 1500));
    
  }, [ userWatchlist ]);

  // When the filter changes, update the ItemsList View
  React.useEffect(() => {
    handleFilterData(unfilteredItemsData);
  }, [ filter ]);

  // Toggle Item from User's Watchlist (Add to Watchlist)
  const toggleWatchlist = (item: IItem) => {
    const { user } = store;

    // Early Returns
    if (!user?._id){
      onLogin();
      return;
    } 

    // Update the List
    setUserWatchlist({
      isUpdated: true,
      items: {
        ...userWatchlist.items,
        [item._id]: userWatchlist.items[item._id] ? false : true,
      },
    });

    // Add what to Update
    setPendingWatchlist({
      ...pendingWatchlist,
      [item._id]: userWatchlist.items[item._id] ? false : true,
    });
  };

  const handleFilterData = async (items: IItem[]): Promise<any> => {
    let filteredItems: IItem[] = [];

    //Cycle through all categories and filter the selected
    for (let i = 0; i < filter.categories.length; i++) {

      if (filter.categories[i].selected) {
        const curr = items.filter( elt => elt.categoryID === filter.categories[i].id );
        filteredItems = [...filteredItems, ...curr];
      }
    }
    if(filteredItems.length > 0){
      // one or more category filters are applied
      if (filter.buyNow) {
        // only display those categories with buy it now option
        filteredItems = filteredItems.filter( el => el.isBuyNow === true );
      }
      setItemsData(filteredItems);
    }
    else{
      if(filter.buyNow){
        filteredItems = unfilteredItemsData.filter(( el => el.isBuyNow === true ));
        setItemsData(filteredItems);
      }
      else{ // no filters applied - reset list to unfiltered
        setItemsData(unfilteredItemsData);
      }
    }
    return;
  };

  const [itemDialog, setItemDialog] = React.useState<{
    isOpen: boolean,
    type: string, //view, update
    itemID: string | null
  }>({ isOpen: false, type: '', itemID: null });

  const resetItemDialog = (type: string) => {
    if(type === 'clear') setItemDialog({ isOpen: false, type: '', itemID: null });
    else if(type === 'update') setItemDialog({...itemDialog, type: 'update'});
    else if(type === 'view') setItemDialog({...itemDialog, type: 'view'});
  };

  const pagination = () => {
    return (
      <Paginate
        totalElements={allItemsTotal}
        elementsPerPage={itemsPerPage}
        currentPage={allItemsCurrentPage}
        onPageChange={changePage}
      />
    );
  };

  return (<>
    <Box className={styles.root} >
      <Grid container className={styles.centerChildren}>
      
        <Grid item style={{ marginBottom: '10px' }}>

          {/* Admin View - Create New Item on Future and open Auctions */}
          {isAdmin && auction.state <= 2 &&
            <Button 
              className={styles.buttons} 
              onClick={() => setItemDialog({ isOpen: true, type: 'update', itemID: null }) }>
              Add Item
            </Button>
          }

          <Button 
            disabled={true}//{listTab === 'watchlist_items'}
            className={clsx(
              styles.buttons, 
              styles.filter,
             // {  [styles.filter]: unfilteredItemsData.length !== itemsData.length  },
            )}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}
          > {unfilteredItemsData.length === itemsData.length ? 'Filter Items' : 'Filters Applied'}</Button>

          {/* On click filter items, open filter menu */}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <ItemsFilter
              categories={auctionCategoryData}
              onChange={(newFilter: IAuctionCategoryData[], buyNow: boolean) => { 
                setAnchorEl(null); 
                setFilter({categories: newFilter, buyNow: buyNow}); 
              }}
              onClear={() => setItemsData(unfilteredItemsData)}
            />
          </Menu>
        </Grid>

        <Grid item style={{ marginTop: '10px' }}>
          {(auction.state === 1 || auction.state === 2) && // User Can Retrieve Watchlist on Open and Public Auctions
            <>
              {/* <Button 
                onClick={() => setListTab('all_items')} 
                className={clsx( listTab === 'all_items' ? styles.viewing : styles.tab)}>
                All Items
              </Button> */}

              {/* <Button 
                onClick={() => setListTab('watchlist_items')} 
                className={clsx( listTab === 'watchlist_items' ? styles.viewing : styles.tab)}>
                Watchlist
              </Button> */}
            </>
          }
        </Grid>

      </Grid> {/* End Info Bar */}

      <div className={styles.main}>

        <Dialog
          maxWidth='xl'
          classes={{ paper: styles.dialogPaper}}
          open={itemDialog.isOpen}
          onClose={() => itemDialog.type === 'view' ? resetItemDialog('clear') : {}}
        >

          {itemDialog.type === 'view' &&
            <ItemDetail
              itemID={itemDialog.itemID}
              maxBid={itemDialog.itemID ? maxBidMap[itemDialog.itemID] : null}
              categories={categoryMap}
              isAdmin={isAdmin}
              auction={auction}
              onClose={() => resetItemDialog('clear') }
              onDelete={() => resetItemDialog('clear') } 
              onUpdateMax={(max: IMaxBid) => {
                setMaxBidMap( {...maxBidMap, [max.itemID]: max } );
              }}
              onLogin={() => onLogin() }
              onEdit={() => resetItemDialog('update') }
            />
          }

          {itemDialog.type === 'update'  &&
            <CreateUpdateItem
              auction={auction}
              itemID={itemDialog.itemID}
              categories={categoryMap}
              onCreate={ () => resetItemDialog('clear')}
              onEdit={ () => resetItemDialog('view') }
              onCancel={ () => resetItemDialog('clear') }
            />
          }
        </Dialog>

        {loadingData
          ?
            <div style={{ width: '100%', textAlign: 'center', paddingTop: '20px' }}>
              <CircularProgress size='2.5rem' className={styles.load} />
            </div>
          : itemsData.length === 0
            ?  <Typography variant="h5" className={styles.text}>No Items</Typography>
            : 
              <>
                <div ref={topOfItemListRef}>
                 {pagination()}
                </div>
                <GridList cols={3}>
                  {(listTab === 'watchlist_items'
                    ? itemsData.filter(elt => userWatchlist.items[elt._id])
                    : itemsData
                  )
                    .map((el, index) =>
                      <ItemCard
                        key={index}
                        auction={auction}
                        itemID={el._id}
                        onToggleWatchlist={() => toggleWatchlist(el)}
                        isWatchlist={userWatchlist.items[el._id]}
                        onClick={() => setItemDialog({isOpen: true, type: 'view', itemID: el._id})}
                      />,
                    )}
                </GridList>
                {pagination()}
              </>
          }

      </div>
    </Box>
    </>
  );
}