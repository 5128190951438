import React, { useContext } from 'react';
import { IAuction } from '../../Interfaces/Database/Auction';
import { colors } from '../../Config/Global';
import { Link as RouterLink } from 'react-router-dom';

import {
  Typography, makeStyles, Button, Grid,
  TextField, CircularProgress,
} from '@material-ui/core';
import { RootContext } from '../../Store/RootStore';
import { IItem } from '../../Interfaces/Database/Item';
import { NumberFormatCustom } from '../../Helpers/NumberFormatCustom';
import { IMaxBid } from '../../Interfaces/Database/MaxBid';
import { Service } from '@feathersjs/feathers';
import { bidsViewable } from '../../Helpers/bidsViewable';

// Local Styles
const useStyles = makeStyles(theme => ({
  bidInput: {
    width: '100%',
    float: 'right',
    objectFit: 'contain',
    '.MuiTextField-root':{
      weight: '2px',
    },
  },  
  button: {
    float: 'right',
    border: '1px solid black',
    borderRadius: '2px',
    padding: '5px',
    margin: '10px',
    backgroundColor: colors.navBarSecondary5up,
  },
}));

interface IProps {
  item: IItem,
  auction: IAuction,
  onLogin: () => void,
}

type serverErrorType = 'No Error' | 'Item Already Sold' | 'Verify Email' | 'Payment Method' | 'General Error';

export default function SubmitBids({ item, auction, onLogin }: IProps) {
  const styles = useStyles();
  const store = useContext(RootContext);
  const [maxBid, setMaxBid] = React.useState<IMaxBid | null>(null); 
  const [bidLoading, setBidLoading] = React.useState<boolean>(false); 
  const [maxBidLoading, setMaxBidLoading] = React.useState<boolean>(false); 
  const [bidSubmitDisabled, setBidSubmitDisabled] = React.useState<boolean>(true); 
  const [minNextBid, setMinNextBid] = React.useState<number>(); 
  const [bidInput, setBidInput] = React.useState<number | null>(); 
  const [maxBidInput, setMaxBidInput] = React.useState<number | null>(); 
  const [maxBidSubmitDisabled, setMaxBidSubmitDisabled] = React.useState<boolean>(true); 
  const [serverError, setServerError] = React.useState<serverErrorType>('No Error');
  const [resentVerify, setResentVerify] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(bidsViewable(auction)){
      setMinNextBid(
        item.highestBidPrice
        ? item.highestBidPrice + item.bidIncrement
        : item.startingBid
        );
      } else {
      setMinNextBid(item.startingBid);
    }
    if(store.user && store.authToken){
      store.client?.service('maxbid').find({
        Authorization: store.authToken,
        user: store.user,
        query: {
          $limit: 1,
          userID: store.user._id,
          itemID: item._id,
        },
      })
        .then((res: any) => {
          const maxBid: IMaxBid | null = res.data.length ? res.data[0] : null;
          setMaxBid(maxBid);
          setMaxBidInput(maxBid ? maxBid.price : null);
        })
        .catch((err: any) => {
          console.log('maxBid error: ', err);
          setMaxBid(null);
          setMaxBidInput(null);
        })
    } else {
      setMaxBid(null);
      setMaxBidInput(null);
    }
  }, [ item ]);

  const handleBidInput = (value: string) => {
    const priceInput = parseInt(value);
    if(bidSubmitDisabled && minNextBid && priceInput >= minNextBid){
      setBidSubmitDisabled(false);
    } else if (!bidSubmitDisabled){
      setBidSubmitDisabled(true);
    }
    setBidInput(priceInput);
  }

  const handleMaxBidInput = (value: string) => {
    const priceInput = parseInt(value);
    if((maxBid && priceInput > maxBid.price) || !maxBid){ //increased max bid (cannot reduce previous max bid)
      if(minNextBid && priceInput >= minNextBid){ // valid next bid
        setMaxBidSubmitDisabled(false);
      } else setMaxBidSubmitDisabled(true);
    }else setMaxBidSubmitDisabled(true);
    setMaxBidInput(priceInput);
  }


  const checkValidUser = (): boolean => {
    if(!store.user){
      onLogin();
      return false;
    }
    else{
      return true;
    }
  }

  const submitBid = () => {
    const { user, actions } = store;
    setBidSubmitDisabled(true);
    setBidLoading(true);

    if (!item || !bidInput || !user) return;

    actions.addBid({
      itemID: item._id,
      price: bidInput,
      userID: user._id,
      isCreatedLive: false,
      bidderNumber: user.bidderNumber,
      initalTimeSet: Date.now(),
    } as any)
      .then(bid => {
        setBidLoading(false);
        setBidInput(null);
        setServerError('No Error');
        // TODO: do I need? If so, need to consider bidsViewable -> setMinNextBid((bid as IBid).price + item.bidIncrement)
      })
      .catch(err => {
        setBidLoading(false);
        setBidSubmitDisabled(false);
        console.log(err);
        if ((err.message as string).includes('Payment Method')) {
          setServerError('Payment Method');
        } else if ((err.message as string).includes('verify email')) {
          setServerError('Verify Email');
        } else {
          setServerError('General Error');
        }
      });
  }

  const submitMaxBid = () => {
    const { user, client, authToken } = store;

    setMaxBidSubmitDisabled(true);
    setMaxBidLoading(true);

    if (!item || !maxBidInput || !user) return;
    const maxBidService: Service<IMaxBid> = client?.service('maxbid');
    const headers = authToken ? { Authorization: authToken } : undefined;
    const params = {
      headers,
      user: user || undefined,
    };

    const create = () => {
      maxBidService.create({
        itemID: item._id,
        userID: user._id,
        bidderNumber: user.bidderNumber,
        price: maxBidInput,
      }, params)
        .then((res: any) => {
          setMaxBid(res);
          setMaxBidInput(res.price);
          setMaxBidLoading(false);
          setServerError('No Error');
        })
        .catch((err: any) => {
          console.log(err);
          setMaxBidSubmitDisabled(false);
          setMaxBidInput(null); //TODO: shouldnt be null, but what the current value is
          setMaxBidLoading(false);
          if ((err.message as string).includes('Payment Method')) {
            setServerError('Payment Method');
          } else if ((err.message as string).includes('verify email')) {
            setServerError('Verify Email');
          } else {
            setServerError('General Error');
          }
        });
    };

    // Max Bid was Previously Set By User
    if(maxBid){

      // Check if Maxbid was Deleted
      maxBidService.get(maxBid._id, params)
        .then(() => {
          // Max Bid Exists - Patch Existing
          maxBidService.patch(maxBid._id, { price: maxBidInput }, params)
            .then((res: any) => {
              setMaxBid(res);
              setMaxBidInput(res.price);
              setMaxBidLoading(false);
              setServerError('No Error');
            })
            .catch((err: any) => {
              console.log(err);
              setMaxBidSubmitDisabled(false);
              setMaxBidInput(null); //TODO: shouldnt be null, but what the current value is
              setMaxBidLoading(false);
              if ((err.message as string).includes('Payment Method')) {
                setServerError('Payment Method');
              } else if ((err.message as string).includes('verify email')) {
                setServerError('Verify Email');
              } else {
                setServerError('General Error');
              }
            });
        })
        .catch(() => {
          // Max Bid Was Removed Due to Being Outbid
          console.log('deleted: create new');
          create();
        });
    } 

    // Create User Max Bid
    else {
      create();
    }
  }

  const resendEmail = () => {
    const { user, client } = store;
    client?.service('authManagement').create({
      action: 'resendVerifySignup',
      value: { email: user?.email as string }, 
      notifierOptions: {},
    }) 
      .then((res: any) => setResentVerify(true))
      .catch((err: any) => console.log('error: ', err));
  }


  return (
    <>
      {auction.preBiddingOpen &&
        <Grid item xs={12}>
          <Typography variant='h6'>Pre-Bidding Open</Typography>
          {auction.preBiddingViewingOption && auction.preBiddingViewingOption === 'onOpen' &&
            <Typography>Bid history will be viewable once the item goes live.</Typography>
          }
        </Grid>
      }
      {serverError !== 'No Error' &&
        <Grid item xs={12}>
          {serverError === 'Payment Method'
            ?
              <div style={{ display: 'flex', float: 'right' }}>
                <RouterLink to={'/payment'}>
                  <Typography color="error" style={{ textDecoration: 'underline' }}>Add a card on File</Typography> 
                </RouterLink>
                <Typography color="error">&nbsp; prior to bidding</Typography>
              </div>
            : serverError === 'Verify Email'
              ? 
                <div style={{float: 'right' }}>
                  <Typography color="error">
                    Check your email for verification prior to bidding.
                  </Typography>
                  {resentVerify
                    ? 
                      <Typography color="error">
                        Email has been resent.
                      </Typography>
                    : 
                      <>
                        <Typography color="error" style={{ display: 'inline-block' }}>
                          Didn&quot;t receive?  &nbsp; 
                        </Typography>
                        <div style={{ cursor: 'pointer', display: 'inline-block' }}>
                          <Typography onClick={resendEmail} color="error" style={{ textDecoration: 'underline' }}>
                            Resend
                          </Typography> 
                        </div>
                      </>
                  }
                </div>
              :
              <>
                <Typography color="error" style={{ float: 'right' }}>
                  {serverError === 'Item Already Sold'
                    ? 'The item has been sold to another user'
                    : 'An error has occurred on the server'
                  }
                </Typography>

              </>
          }
        </Grid>
      }
      {/* ---------------------------------  Place Bid  ------------------------------------ */}
      <Grid item xs={7}>
        <TextField
          variant='outlined'
          id="place-bid" 
          size='small'
          className={styles.bidInput}
          disabled={item.isSold}
          placeholder={minNextBid ? `$${minNextBid.toString()} or higher` : ''}
          value={bidInput}
          onChange={value => handleBidInput(value.target?.value)}
          InputProps={{ inputComponent: NumberFormatCustom as any }}
        />
      </Grid>

      <Grid item xs={5}>
        <Button 
          className={styles.button} 
          style={{width: '90%', margin: 0}}
          disabled={item.isSold || bidSubmitDisabled}
          onClick={() => { if(checkValidUser()) submitBid(); }}
        >
          {bidLoading 
            ? <div style={{ width: '100%', textAlign: 'center'}}>
                <CircularProgress size='1rem' style={{ margin: 'auto' }} />
              </div>
            : <Typography>Place Bid</Typography>
          }
          
        </Button>
      </Grid>

      {/* ---------------------------------  Set Maximum Bid  ------------------------------------ */}
      <Grid item xs={7}>
        <TextField
          variant='outlined'
          id="set-max-bid" 
          size='small'
          className={styles.bidInput}
          disabled={item.isSold}
          placeholder={minNextBid ? `$${minNextBid.toString()} or higher` : ''}
          value={maxBidInput}
          onChange={value => handleMaxBidInput(value.target?.value)}
          InputProps={{ inputComponent: NumberFormatCustom as any }}
        />
        
      </Grid>

      <Grid item xs={5}>
        <Button 
          className={styles.button} 
          style={{width: '90%', margin: 0}}
          disabled={item.isSold || maxBidSubmitDisabled}
          onClick={() =>{ if(checkValidUser()) submitMaxBid(); }}
        >
          {maxBidLoading 
            ? <div style={{ width: '100%', textAlign: 'center'}}>
                <CircularProgress size='1rem' style={{ margin: 'auto' }} />
              </div>
            : <Typography>{maxBid ? 'Increase Max Bid' : 'Set Max Bid'}</Typography>
          }
          </Button>
      </Grid>
    </>
  );
}