import React from 'react';
import { colors } from '../Config/Global';

import {
  Box, Typography,
  makeStyles,
} from '@material-ui/core';


// Local Styles
const useStyles = makeStyles({
  root: {
    minHeight: '70vh',
  },
  text: {
    textAlign: 'center',
    color: colors.darkBlueFont,
  },
  question: {
    backgroundColor: colors.navBarSecondary5up,
    width: '100%',
    borderRadius: '5px',
    margin: '40px 10px 0 10px',
    padding: '5px',
    textAlign: 'center',
  },
  answer: {
    margin: '20px',
  },
});


export default function FAQ(){
  const styles = useStyles();


  return (
    <Box className={styles.root}>
      <Typography className={styles.text} style={{padding: 10}}variant="h5">Frequently Asked Questions</Typography>

      {/* How can I participate in the auctions? */}
      <Typography className={styles.question} variant="h6">How can I participate in the auctions?</Typography>
      <Typography className={styles.answer}>
        You must be a registered user of the Royale Treasures Auction website. Navigate to the login button
        on the top right of the site and select &quot;Sign up today&quot;.
      </Typography>
      <Typography className={styles.answer}>
        Payment information is required prior to bidding on auction items. To enter your payment information,  At the end of the auction, 
        all items that you have the current bid on will be added to your pending purchases. 
        The payment will process once the delivery method is resolved. 
      </Typography>
      <Typography className={styles.answer}>
        You may browse through future auctions and add items to your watchlist for easy access to your wanted items for when the auction opens. 
        Once the auction opens, you may bid on any of the items, set your maximum bid, or buy the item at a set price. More information regarding
        the bidding process is located in the &quot;How can I bid on/buy auction items?&quot; section. 
      </Typography>


      {/* Where can I find my account? */}
      <Typography className={styles.question} variant="h6">Where can I find my account?</Typography>
      <Typography className={styles.answer}>
        Once you are signed in, select the profile icon in the top right corner of the page. A menu displays your account options:
      </Typography>
              {/* Account Details */}
      <Typography className={styles.answer} style={{ fontWeight: 'bold' }}>Account Details</Typography>
      <Typography className={styles.answer}>
        View and edit your personal account information including your email, phone number, address, and password. Your contact information 
        will be available to the auctioneer for your purchased items. This information is important to keep up to date. 
      </Typography>
      <Typography className={styles.answer}>
        Your assigned bidder number is also displayed. This private number is used in place of your name for items that you bid on.  
      </Typography>
                {/* Payment Information */}
      <Typography className={styles.answer} style={{ fontWeight: 'bold' }}>Payment Information</Typography>
      <Typography className={styles.answer}>
        You may add/update your linked credit card. The information is securely stored through the Square service provider. After the 
        auction is closed and the delivery method is resolved, you will be automatically charged for your items, any shipping costs, 
        and a 3% service charge for using the payment service provider. 
      </Typography>
                {/* Purchase History */}
      <Typography className={styles.answer} style={{ fontWeight: 'bold' }}>Purchase History</Typography>
      <Typography className={styles.answer}>
        Upon auction closure, all of your won items will be added to your pending purchases. You must resolve the delivery method (pickup or 
        shipping) within 48 hours. Once all items have a delivery method selected, your automatic payment will be processed. If you bought an 
        item during the auction (But It Now) and select a delivery method, a payment will be processed at that time separately from any won 
        items after the auction is closed. 
      </Typography>
                {/* Sign Out */}
      <Typography className={styles.answer} style={{ fontWeight: 'bold' }}>Sign Out</Typography>



      {/* How can I bid on/buy auction items? */}
      <Typography className={styles.question} variant="h6">How can I bid on/buy auction items?</Typography>
              {/* Place Bid */}
      <Typography className={styles.answer} style={{ fontWeight: 'bold' }}>Place Bid</Typography>
      <Typography className={styles.answer}>
        All bids must be an increase of the previous bid by a multiple of the minimum bid amount displayed. If two bids equal the same amount, 
        the first bidder receives the highest bid. Only the bid amount, your bidder number, and time of bid are publicly viewable in the bid history. 
      </Typography>
              {/* Set Maximum Bid */}
      <Typography className={styles.answer} style={{ fontWeight: 'bold' }}>Set Maximum Bid</Typography>
      <Typography className={styles.answer}>
        Users have the option to set a maximum amount they are willing to pay for the item. If the user is 
        subsequently outbid, Royale Treasures Auction will continue to create new bids until their maximum 
        amount is reached. You will be notified by email for automatic bid placements and the occurrence of being outbid.   
        This maximum bid amount may be increased after setting the initial maximum bid amount. 
      </Typography>
              {/* Buy It Now */}
      <Typography className={styles.answer} style={{ fontWeight: 'bold' }}>Buy It Now</Typography>
      <Typography className={styles.answer}>
        If there is a Buy It Now option to the item, you can either purchase the item right away at the set price listed or place a 
        bid. If two users attempt to buy the item at the same moment, the user whos purchase does not go through will receive a server error. 
        If the bid price exceeds the Buy Now price, the Buy Now option is removed. 
      </Typography>
      
    </Box>
  );
}