import { Service, ServiceMethods } from '@feathersjs/feathers';
import { IRootStore } from '../RootStore';
import { StateSetter } from './Interfaces';
import { IInvoice, IMultiInvoice } from '../../Interfaces/Database/Invoice';
import { IPurchase, deliveryType, DELIVERY_TYPE } from '../../Interfaces/Database/Purchase';
import { reportReducer } from '../Reducers/ReportReducer';
import { ReportAction } from '../Reducers/ReportReducer';
import { IBid } from '../../Interfaces/Database/Bid';
import { IItem } from '../../Interfaces/Database/Item';
import { QueryResult } from '../../Interfaces/Service';

type ActionType = 'NEXT_BID' | 'NOT_SOLD';


export interface updatePurchaseData {
  action: ActionType,
  payload: {
    auctionID: string,
    data: string | IBid,
  } 
}

const notSoldItems = (auctionID: string, state: IRootStore) => {
    // Establish Connection
    const { client, authToken, user } = state;
    const headers = authToken ? { Authorization: authToken } : undefined;
    if (!client) return;
    const itemService: Service<IItem> = client.service('items');
    const purchaseService: Service<IPurchase> = client.service('purchases');

    // Retrieve All Auction Items
    return itemService.find({
      query: { 
        auctionID,
        $limit: 0, // Only get Total Items First
      },
      headers,
      user: user || undefined, 
    })
      .then(async (res: any) => {
        const totalItems = res.total;
        const numberOfQueries = Math.ceil(totalItems / 50);
        const promises = [];
        const items: IItem[] = [];
        for(let i = 0; i < numberOfQueries; i++){
          promises.push(itemService.find({
            headers: { Authorization: authToken },
            user: user || undefined,
            query: {
              auctionID,
              $limit: res.total,
              $skip: i * 50,
              $sort: { itemNumber: 1 }
            },
          })
            .then((res: any) => {
              res.data.forEach((item: IItem) => {
                items.push(item);
              })
            }));
        }
        await Promise.all(promises);

        const purchases = await Promise.all(items.map(async item => {
          const purchase = await purchaseService.find({
            query: {
              itemID: item._id,
              $limit: 1,
            },
            headers,
            user: user || undefined, 
          });
          // If No Purchase Was Found, Return Item
          return (purchase as QueryResult<IPurchase>).total === 1 ? null : item;
        }));
        return(purchases.filter(item => item !== null) as IItem[]);
      });
};

export const queryAuctionReport = async (auctionID: string, state: IRootStore, setState: StateSetter) => {
  
  // Check if in State
  if (state.auctionReportStore[auctionID]) {
    return state.auctionReportStore[auctionID];
  }
  
  // Establish Connection
  const { client, authToken, user } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;
  if (!client) return;
  

  // Get thy Report!
  return (client.service('report') as ServiceMethods<IInvoice>)
  .find({
    query: {
      auctionID: auctionID,
    },
    headers,
    user: user || undefined,
  })
  .then((res: any) => {
    // Readability
    const data: IMultiInvoice = res;
    
    // Promisified Filtering Function, for "Mutlithreading"
    const filterFn = (data: IMultiInvoice, deliveryType: DELIVERY_TYPE) => Promise.resolve(
      data.invoice.reduce((acc: any[], curr) => (
        deliveryType === 'shipping' 
          ? [...acc, ...Object.keys(curr.orders[deliveryType]).map((addrKey: string) => ({
            address: addrKey,
            user: curr.user,
            items: (curr.orders[deliveryType] as any)[addrKey],       // AddressHash
          }))]
          : Object.keys(curr.orders[deliveryType]).length > 0  
            ? [...acc, {
              user: curr.user,
              items: Object.values(curr.orders[deliveryType]) as IPurchase[],
            }]
          : acc
      ), []),
    );
    
    // Filter Out all Shipping Types
    return Promise.all((deliveryType.map( type => filterFn(data, type as any) )));
  })
  .then(async res => { 
    const notSold = await notSoldItems(auctionID, state);

    setState({
      ...state,
      auctionReportStore: {
        ...state.auctionReportStore,
        [auctionID]: {
          noMethodData: res[0],
          shippingData: res[1],
          pickupData: res[2],
          notSoldData: notSold,
        }},
    });

    // Return data from Query
    return res;
  });
};

export const updateDeliveryData = async (data: ReportAction, state: IRootStore, setState: StateSetter) => {
  setState(reportReducer(state, data));
};

export const queryItemBidders = async (itemID: string, state: IRootStore, setState: StateSetter): Promise<any> => {

   // Check if in State
   if (state.nextBidderStore[itemID]) {
    return state.nextBidderStore[itemID];
  }
  
  // Establish Connection
  const { client, authToken, user } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;
  if (!client) return;

  // Retrieve Item Bidders
  return client.service('bid-mod').find({
    query: { itemID },
    headers: headers,
    user: user || undefined,
  })
    .then((res: any) => {

      // Update Global State
      setState({
        ...state,
        nextBidderStore: {
          ...state.nextBidderStore,
          [itemID]: res,
        },
      });
      return res;
    });
    
};

export const updatePurchase = async (data: updatePurchaseData, state: IRootStore, setState: StateSetter) => {
  // Establish Connection
  const { client, authToken, user } = state;
  const { noMethodData, notSoldData } = state.auctionReportStore[data.payload.auctionID];
  if (!client) return;

  const updatedNoMethod = [ ...noMethodData ];
  const updatedNotSold = [ ...notSoldData ];
  const itemID = (data.payload.data as IBid).itemID ? (data.payload.data as IBid).itemID : (data.payload.data as string);


  client.service('bid-mod').create({action: data.action, payload: data.payload.data}, {
    headers: { Authorization: authToken },
    user: user || undefined,
  })
    .then((res: any) => {
      // Remove Item From Previous User Purchase
      let found = false;
      // Cycle Through Each User
      for(let user = 0; user < noMethodData.length && !found; user++){

        // For Each User, Cycle Through Items
        for(let item = 0; item < noMethodData[user].items.length && !found; item++){

          //Found Updated Item Purchase
          if(noMethodData[user].items[item].item._id === itemID){
            found = true;
            
            // Filter out Item
            if(noMethodData[user].items.length > 1){
              updatedNoMethod[user].items.splice(item, 1);
            }
            // Remove User
            else {
              updatedNoMethod.splice(user, 1);
            }
          }
        }
      }

      // // Add Purchase To New User
      if(data.action === 'NEXT_BID'){
        const userIndex = updatedNoMethod.findIndex( elt => elt.user._id === res.user._id );

        // User Already Has Purchases in No Bidder Data - Append Purchase to User Item List
        if(userIndex !== -1){
          updatedNoMethod[userIndex].items.push(res.item);
        }

        // Add User To No Bidder Data With Purchase
        else{
          updatedNoMethod.push({
            address: undefined,
            user: res.user,
            items: [ res.item ],
          });
        }
      }

      // Marked Item As Not Sold
      else{
        updatedNotSold.push(res.item);
      }

      //return updatedNoMethod;
      setState(reportReducer(state, {
        type: 'update',
        payload: {
          auctionID: data.payload.auctionID,
          itemID,
          pendingData: updatedNoMethod,
          notSoldData: updatedNotSold,
        },
      }));
    });
};