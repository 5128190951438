import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {  IAuctionSettings } from './CreateAuctionStepper';
import { FormControl, FormControlLabel, Grid, InputLabel, Select, TextField, Switch } from '@material-ui/core';
import { colors } from '../../Config/Global';

import { IntegerFormatCustom, NumberFormatCustom } from '../../Helpers/NumberFormatCustom';
import { addAsterickOnError } from '../../Helpers/Accessibility';
import { AuctionTypes, PreBiddingViewOptionTypes } from '../../Interfaces/Database/Auction';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grid: {
      padding: '20px',
    },
    gridElement:{
      margin: 'auto',
      marginBottom: '20px',
      width: '95%',
    },
    imageInput: {
      margin: 'auto',
      marginBottom: '20px',
      width: '95%',
      border: '1px solid #bfbfbf',
      borderRadius: '5px',
    },
    textInput: {
      width: '100%',
    },
    imageContainer:{
      width: '90%',
      border: '1px black solid',
      margin: 'auto',
      backgroundColor: colors.navBarSecondary6up,
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
}));

interface IProps {
  auctionOpen: boolean,
  auctionType: AuctionTypes,
  settings: IAuctionSettings,
  verifyInput: boolean,
  readOnly: boolean,
  onVerify: () => void,
  onUpdate: (info: IAuctionSettings) => void,
  onError: (error: string) => void
}

export default function AuctionSettingsInput({ auctionOpen, auctionType, settings, verifyInput, readOnly, onVerify, onError, onUpdate }: IProps) {
  const classes = useStyles();

  const preBiddingStartRef = React.createRef<HTMLInputElement>();
  const preBiddingEndRef = React.createRef<HTMLInputElement>();

  const [viewingOptions, setViewingOptions] = React.useState<string>(settings.state === 0 ? 'private' : 'public');
  const [preBiddingEnabled, setPreBiddingEnabled] = React.useState<boolean>(settings.preBiddingEnabled);
  const [preBiddingStart, setPreBiddingStart] = React.useState<number>(settings.preBiddingStart !== undefined ? settings.preBiddingStart : 24);
  const [preBiddingEnd, setPreBiddingEnd] = React.useState<number>(settings.preBiddingEnd !== undefined  ? settings.preBiddingEnd : 1);
  const [preBiddingViewingOption, setPreBiddingViewingOption] = React.useState<PreBiddingViewOptionTypes>(settings.preBiddingViewingOption ? settings.preBiddingViewingOption : 'onOpen');
  
  const [preBiddingStartError, setPreBiddingStartError] = React.useState<boolean>(false);
  const [preBiddingEndError, setPreBiddingEndError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(verifyInput){
      if(!readOnly){
        let missingFields = false;
  
        if(auctionType === 'liveAndOnline' && preBiddingEnabled){
          if(isNaN(preBiddingStart)){
            setPreBiddingStartError(true);
            missingFields = true;
          }
          if(isNaN(preBiddingEnd)){
            setPreBiddingEndError(true);
            missingFields = true;
          }
  
          if(!auctionOpen && preBiddingStart < 1){
            setPreBiddingStartError(true);
            return onError('Minimum starting time is 1 hour before auction begins');
          }
  
          if(!auctionOpen && preBiddingEnd >= preBiddingStart){
            setPreBiddingStartError(true);
            setPreBiddingEndError(true);
            return onError('Minimum pre-bidding time duration is 1 hour');
          }
        }
  
        if(missingFields){
          return onError('Fill out required fields');
        }
  
        setPreBiddingStartError(false);
        setPreBiddingEndError(false);
  
        onUpdate({
          state: getState(viewingOptions),
          preBiddingEnabled: preBiddingEnabled,
          preBiddingStart: preBiddingStart,
          preBiddingEnd: preBiddingEnd,
          preBiddingViewingOption: preBiddingViewingOption
        });
      }
      onVerify();
    }
  }, [ verifyInput ]);


  const getState = (viewing: string) => {
    return viewing === 'public' ? 1 : 0;
  };



  return (
    <Grid container>
      <Grid item xs={12} lg={6} className={classes.grid}>
        <Grid container direction='column'>

          <Grid item className={classes.gridElement}>
            <FormControl variant="outlined" className={classes.textInput} >
              <InputLabel>Viewing Options</InputLabel> 
              <Select
                native
                label='Viewing Options'
                value={viewingOptions}
                onChange={event => {
                  const option = event.target.value as string;
                  setViewingOptions(option);
                  onUpdate({
                    state: getState(option),
                    preBiddingEnabled,
                    preBiddingStart,
                    preBiddingEnd,
                    preBiddingViewingOption
                  });
                }}
                disabled={readOnly || auctionOpen}
              >
                <option value={'public'}>Public</option>
                <option value={'private'}>Private (Once open, becomes public)</option>
              </Select>
            </FormControl>
          </Grid>

        </Grid>
      </Grid>

      {auctionType === 'liveAndOnline' &&
        <Grid item xs={12} lg={6} className={classes.grid}>
          <Grid container direction='column'>
  
            <Grid item className={classes.gridElement}>
                    
              <FormControlLabel
                control={
                  <Switch
                      checked={preBiddingEnabled}
                      onChange={event => {
                        const checked = event.target.checked;
                        setPreBiddingEnabled(checked);
                        onUpdate({
                          state: getState(viewingOptions),
                          preBiddingEnabled: checked,
                          preBiddingStart,
                          preBiddingEnd,
                          preBiddingViewingOption
                        });
                      }}
                      name={'Pre-bidding enabled'}
                      color={'primary'}
                      disabled={readOnly || auctionOpen}
                    />
                } 
                label={'Pre-bidding enabled'}
              />
            </Grid>
  
            {preBiddingEnabled &&
              <div style={{ marginLeft: '60px' }}>
  
                <Grid item className={classes.gridElement}>
                  <TextField
                    label={addAsterickOnError(preBiddingStartError, 'Start: x hours before auction begins')}
                    variant='outlined'
                    error={preBiddingStartError}
                    className={classes.textInput}
                    inputRef={preBiddingStartRef}
                    value={preBiddingStart}
                    onChange={value => {
                      const newPreBiddingStart = parseInt(value.target?.value);
                      setPreBiddingStart(newPreBiddingStart);
                      onUpdate({
                        state: getState(viewingOptions),
                        preBiddingEnabled,
                        preBiddingStart: newPreBiddingStart,
                        preBiddingEnd,
                        preBiddingViewingOption
                      });
                    }}
                    InputProps={{ inputComponent: IntegerFormatCustom as any }}
                    disabled={readOnly || auctionOpen}
                    placeholder={'mininum value: 1 hour'}
                  />
                </Grid>
  
                <Grid item className={classes.gridElement}>
                  <TextField
                    label={addAsterickOnError(preBiddingEndError, 'End: x hours before auction begins')}
                    variant='outlined'
                    error={preBiddingEndError}
                    className={classes.textInput}
                    inputRef={preBiddingEndRef}
                    value={preBiddingEnd}
                    onChange={value => {
                      const newPreBiddingEnd = parseInt(value.target?.value);
                      setPreBiddingEnd(newPreBiddingEnd);
                      onUpdate({
                        state: getState(viewingOptions),
                        preBiddingEnabled,
                        preBiddingStart,
                        preBiddingEnd: newPreBiddingEnd,
                        preBiddingViewingOption
                      });
                    }}
                    InputProps={{ inputComponent: IntegerFormatCustom as any }}
                    disabled={readOnly || auctionOpen}
                  />
                </Grid>
  
                <Grid item className={classes.gridElement}>
                  <FormControl variant="outlined" className={classes.textInput} >
                    <InputLabel>Pre-bidding Viewing Options</InputLabel> 
                    <Select
                      native
                      label='Pre-bidding Viewing Options'
                      value={preBiddingViewingOption}
                      onChange={event => {
                        const newPreBiddingViewingOption = event.target.value as PreBiddingViewOptionTypes
                        setPreBiddingViewingOption(newPreBiddingViewingOption);
                        onUpdate({
                          state: getState(viewingOptions),
                          preBiddingEnabled,
                          preBiddingStart,
                          preBiddingEnd,
                          preBiddingViewingOption: newPreBiddingViewingOption
                        });
                      }}
                      disabled={readOnly || auctionOpen}
                    >
                      <option value={'onBid'}>On bid</option>
                      <option value={'onOpen'}>Once item is live</option>
                    </Select>
                  </FormControl>
                </Grid>
              </div>
            }
  
          </Grid>
        </Grid>
      }

    </Grid>
  );
}