import { IItem } from '../../Interfaces/Database/Item';
import { IBid } from '../../Interfaces/Database/Bid';
import { IRootStore } from '../RootStore';
import { IAuction } from '../../Interfaces/Database/Auction';

/**
 * Auction Store
 */

 export interface IPageData {
  data: IItem[], // OR
  totalRows: number,
}

export interface ISlideShowData {
  auction: IAuction,
  featuredItems: IItem[],
}

export interface IFilteredItems {
  itemIds: string[] | null,
}

export interface BidStore {
  bid:          IBid,
  number:       number,
}

export interface ItemStore {
  [itemID:   string]: {               // Starts off Empty unless Queried
    item:         IItem,             
    highestBid:   BidStore | null,
    //isWatching:   boolean,  
  }
}

export interface AuctionStore {
  [auctionID:   string]: {
    auction:    IAuction,
    items:      string[] | null,      // Starts off Empty unless Queried (null)
  }
}

// Bid History Store for Caching
export interface BidHistoryStore {
  [itemID: string]: BidStore[],       // Starts off Empty unless Queried
}


/** PAYLOAD SECTION */
type AuctionType = 'SET_AUCTION_STORE' | 'ADD_AUCTION' | 'REM_AUCTION' | 'ADD_ITEM' | 'REM_ITEM' | 'ADD_BID' | 'ADD_BID_ITEM' | 'ADD_PURCHASE'; 

export interface AuctionItemPayload {
  auctionID: string,     
  item: IItem,
}

export interface AuctionItemIDPayload {
  auctionID: string,
  itemID: string,
}

export interface AuctionAction {
  type: AuctionType,
  payload: AuctionStore | AuctionItemPayload | AuctionItemIDPayload | BidStore | string, 
}

/**
 * Used to Manipulate the state given an Action
 * @param state State to Manipulate
 * @param action Action includes type and payload
 */
export const auctionReducer = (state: IRootStore, action: AuctionAction): IRootStore => {

  switch (action.type) {
    case 'ADD_AUCTION':             // Adds/Overwrites a new Auction
    return {
        ...state,
        auctionStore: {
          ...state.auctionStore,
          ...(action.payload as AuctionStore),
        },
      };

    case 'REM_AUCTION':             // Removes given AuctionID
      return {
        ...state,
        //auctionStore: Object.keys(state.auctionStore).filter(auctionID => auctionID !== (action.payload as string)),
        auctionStore: Object.keys(state.auctionStore)
          .reduce((acc, auctionID) => (
            auctionID.toString() !== (action.payload as string)
              ? { ...acc, [auctionID]: state.auctionStore[auctionID] }
              : acc
          ), {}),
        //itemStore: Object.values(state.itemStore).filter(elt => elt.item.auctionID !== (action.payload as string)),
        itemStore: Object.values(state.itemStore)
          .reduce((acc, item) => (
            item.item.auctionID.toString() !== (action.payload as string)
              ? { ...acc, [item.item._id]: state.itemStore[item.item._id] }
              : acc
          ), {}),
      };
    
    case 'SET_AUCTION_STORE':       // Overwrites entire Auction Store with a new One
      return {
        ...state,
        auctionStore: action.payload as AuctionStore,
      };

    case 'ADD_ITEM':                // Adds/Updates Item
      return {
        ...state,
        auctionStore: { // Add / Update ItemID in auctionStore
          ...state.auctionStore,
          [(action.payload as AuctionItemPayload).auctionID]: {
            ...state.auctionStore[(action.payload as AuctionItemPayload).auctionID],
            items: state.auctionStore[(action.payload as AuctionItemPayload).auctionID].items 
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              ? state.auctionStore[(action.payload as AuctionItemPayload).auctionID].items 
                  // Filter Out ItemID
                  .filter( item => item !== (action.payload as AuctionItemPayload).item._id)
    
                  // Add the new Item
                  .concat((action.payload as AuctionItemPayload).item._id)
              : [ (action.payload as AuctionItemPayload).item._id ],
          },
        },
        itemStore: { // Add/Update Item to ItemStore
          ...state.itemStore,
          [(action.payload as AuctionItemPayload).item._id] : {
            item: (action.payload as AuctionItemPayload).item,
            highestBid: null,
          },
        },
      };

    case 'REM_ITEM':                // PURGES thy Item
      return {
        ...state,
        auctionStore: { // Remove ItemID from AuctionStore
          ...state.auctionStore,
          [(action.payload as AuctionItemIDPayload).auctionID]: {
            ...state.auctionStore[(action.payload as AuctionItemIDPayload).auctionID],
            items: 
            //@ts-ignore
            state.auctionStore[(action.payload as AuctionItemIDPayload).auctionID].items
            .reduce((acc, itemID) => (
              itemID !== (action.payload as AuctionItemIDPayload).itemID
               //@ts-ignore
                ? acc.concat((action.payload as AuctionItemIDPayload).itemID)
                : acc
            ), []),
          },
        },
        itemStore: Object.values(state.itemStore) // Remove Item from ItemStore
          .reduce((acc, item) => (
            item.item._id !== (action.payload as AuctionItemIDPayload).itemID
              ? { ...acc, [item.item._id]: {item: item.item, highestBid: null }}
              : acc
          ), {}),
      };

    case 'ADD_BID':                 // Adds Bid to BidHistoryStore
      return {
        ...state,
        bidHistoryStore: {
          ...state.bidHistoryStore,
          [(action.payload as BidStore).bid.itemID]: 
            (state.bidHistoryStore[(action.payload as BidStore).bid.itemID] || [])
              .concat(action.payload as BidStore),
        } as BidHistoryStore,
      };

      case 'ADD_BID_ITEM':          // Attaches Bid to Item Data and Adds Bid to BidHistoryStore
      return {
        ...state,
        itemStore: {
          ...state.itemStore,
          [(action.payload as BidStore).bid.itemID]: {
            ...state.itemStore[(action.payload as BidStore).bid.itemID],
            item: {
              ...state.itemStore[(action.payload as BidStore).bid.itemID].item,
              highestBidID: (action.payload as BidStore).bid._id,
            },
            highestBid: (action.payload as BidStore),
          },
        },
        bidHistoryStore: {
          ...state.bidHistoryStore,
          [(action.payload as BidStore).bid.itemID]: 
            (state.bidHistoryStore[(action.payload as BidStore).bid.itemID] || [])
              .concat(action.payload as BidStore),
        } as BidHistoryStore,
      };

    case 'ADD_PURCHASE':
      return {
        ...state,
        itemStore: {
          ...state.itemStore,
          [action.payload as string]: {
            ...state.itemStore[action.payload as string],
            item: {
              ...state.itemStore[action.payload as string].item,
              isSold: true,
            },
            highestBid: null,
          },
        },
      };

    default:
      break;
  }

  return state as any;
};