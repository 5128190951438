import { Service } from '@feathersjs/feathers';
import { IRootStore } from '../RootStore';
import { auctionReducer } from '../Reducers/AuctionReducer';
import { IPurchase } from '../../Interfaces/Database/Purchase';
import { StateSetter } from './Interfaces';


export const addPurchase = async (purchase: Partial<IPurchase>, state: IRootStore, setState: StateSetter): Promise<any> => {
  // Add Bid to Server
  const { client, user, authToken } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;

  if (!client || !purchase || !purchase.itemID) return Promise.reject(null);

  const purchaseService: Service<IPurchase> = client?.service('purchases');

  
  return purchaseService.create(purchase, {
    headers,
    user: user || undefined,
  })
    .then(() => {
      // Attach Purchase to Item to Global State
      setState(
        auctionReducer(state, {
          type: 'ADD_PURCHASE',
          payload:  purchase.itemID as string,
        },
      ));

      return;
    });
};
