import React, { useContext } from 'react';
import {
  makeStyles, Paper, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TablePagination, TableFooter,
} from '@material-ui/core';

import { IByAddressUser } from './ItemDeliveryTabs';
import CollapsableSection from './CollapsableSection';
import { IBid } from '../../Interfaces/Database/Bid';
import { IUser } from '../../Interfaces/Database/User';
import { RootContext } from '../../Store/RootStore';
import PaymentStatus from './PaymentStatus';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 440,
  },
});

interface IProps {
  auctionID: string
  type: string,
  onUpdate?: () => void,
}

export default function ItemDeliveryTable({ auctionID, type, onUpdate }: IProps) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const store = useContext(RootContext);
  const { auctionReportStore } = store;
  const data: IByAddressUser[] = type === 'NO_METHOD' 
    ? auctionReportStore[auctionID].noMethodData
    : auctionReportStore[auctionID].pickupData;


  return (<>

    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell><strong>Payment Status</strong></TableCell>
              <TableCell align='left'><strong>Customer</strong></TableCell>
              <TableCell><strong>Contact</strong></TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <CollapsableSection key={index} items={row.items} auctionID={auctionID} nextBidderOption={type === 'NO_METHOD' ? true : false} onUpdate={onUpdate}>
                  <PaymentStatus purchaseItems={row.items}/>
                  <TableCell >{row.user.firstName} {row.user.lastName}</TableCell>
                  <TableCell>{row.user.email}<br/>{row.user.phoneNumber}</TableCell>
                </CollapsableSection>
              ))}
          </TableBody>
        </Table>

        <TableFooter>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={(_, newPage) => setPage(newPage)}
            onChangeRowsPerPage={e => setRowsPerPage(+e.target.value)}
          />
        </TableFooter>
      </TableContainer>
    </Paper>
  </>);
}
