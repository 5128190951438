import React from 'react';
import clsx from 'clsx';
import { 
  Theme, makeStyles, createStyles,
  Checkbox, FormControlLabel, FormGroup,
  Button, Typography,
} 
from '@material-ui/core';
import { IAuctionCategoryData } from '../ItemsList';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //margin: '10px',
    },
    filters: {
      margin: '10px',
    },
    checkBox: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      //padding: '5px',
    },
    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
          'url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 16 16\'%3E%3Cpath' +
          ' fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 ' +
          '1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z\' fill=\'%23fff\'/%3E%3C/svg%3E")',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
    formControlLabel:{
      // '.MuiFormControlLabel-root': {
      //   margin-left: '0px',
    },
  }),
);


interface IProps {
  categories: IAuctionCategoryData[],
  onChange: (newFilter: IAuctionCategoryData[], buyNow: boolean) => void,
  //onSubmit: (states: FilterStates) => void,
  onClear: () => void,
}


export default function ItemsFilter({categories, onChange, onClear }: IProps){ 
  const classes = useStyles();

  const [categoriesList, setCategoriesList] = React.useState<IAuctionCategoryData[]>([]); 
  const [buyNow, setBuyNow] = React.useState<boolean>(false); 

  React.useEffect(() => {
    setCategoriesList(categories);
  }, [ categories ]);


  React.useEffect(() => {
    onChange(categoriesList, buyNow);
  }, [categoriesList, buyNow]);


  const handleCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const temp = categoriesList;
    temp[+event.target.id].selected = event.target.checked;
    setCategoriesList([ ...temp ]);
  };

  const handleBuyNowChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBuyNow(event.target.checked);
  };

  const clearFilters = () => {
    const temp = categoriesList;
    temp.forEach(el => el.selected = false);
 
    setCategoriesList([ ...temp ]);
    setBuyNow(false);
    onClear();
  };


  return (
    <div className={classes.root}>
      <Button onClick={() => clearFilters()}>Clear Filters</Button>

      <FormGroup className={classes.filters}>

        {/* Buy It Now */}
        <FormControlLabel
          control={
            <Checkbox
              className={classes.checkBox}
              disableRipple
              checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
              icon={<span className={classes.icon} />}
              inputProps={{ 'aria-label': 'decorative checkbox' }}
              checked={buyNow}
              onChange={handleBuyNowChange}
              name={'Buy It Now'}
              color="default"
            />
          }
          label={'Buy It Now'}
        />

        <Typography>Categories </Typography>
        {categoriesList.map((el, index) => (
            el.number > 0 &&
              <FormControlLabel
              control={
                <Checkbox
                  id={index.toString()}
                  className={classes.checkBox}
                  disableRipple
                  checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                  icon={<span className={classes.icon} />}
                  inputProps={{ 'aria-label': 'decorative checkbox' }}
                  checked={el.selected}
                  onChange={handleCategoryChange}
                  name={el.name}
                  color="default"
                />
              }
              label={el.name + '(' + el.number + ')'}
            />
          ),
        )}

      </FormGroup>

    </div> 
  );
}