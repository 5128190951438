import { Service } from '@feathersjs/feathers';
import { IRootStore } from '../RootStore';
import { IItem } from '../../Interfaces/Database/Item';
import { auctionReducer, BidStore, ItemStore } from '../Reducers/AuctionReducer';
import { StateSetter } from './Interfaces';

export const addItemToStore = async (auctionID: string, item: IItem, state: IRootStore, setState: StateSetter) => {
  setState(
    auctionReducer(state, {
      type: 'ADD_ITEM',
      payload: {
        auctionID,
        item,
      },
    }),
  );
}

export const retrieveItem = async (itemID: string, state: IRootStore, setState: StateSetter): Promise<IItem> => {
  const { client, user, authToken } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;
  if (!client) return Promise.reject(null);

  if(state.itemStore[itemID]){
    return state.itemStore[itemID].item;
  }

  return client?.service('items').get(itemID, {
    headers,
    user: user
  })
  .then((item: IItem) => {
    setState(
      auctionReducer(state, {
        type: 'ADD_ITEM',
        payload: {
          auctionID: item.auctionID,
          item,
        },
      }),
    );
    return item;
  });
}

export const addItem = async (auctionID: string, item: IItem, state: IRootStore, setState: StateSetter): Promise<IItem> => {
  // Add Item to Server
  const { client, user, authToken } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;

  if (!client) return Promise.reject(null);
  const itemsService: Service<IItem> = client?.service('items');

  
  return itemsService.create(item, {
    headers,
    user: user || undefined,
  })
    .then(newItem => {
      // Add new Item to Global State
      setState(
        auctionReducer(state, {
          type: 'ADD_ITEM',
          payload: {
            auctionID,
            item: newItem,
          },
        }),
      );

      return newItem;
    });
};


/**
 * Update Item in the Root State
 * @param auctionID - Auction for which the Item is in
 * @param item - Includes item with bid history
 * @param state - The Current State
 * @param setState - State Setter for the new State
 */
export const updateItem = async (auctionID: string, itemID: string, item: Partial<IItem>, state: IRootStore, setState: StateSetter): Promise<IItem> => {
  // Add Item to Server
  const { client, user, authToken } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;

  if (!client) return Promise.reject(null);
  
  if(itemID) {
    const itemsService: Service<IItem> = client?.service('items');
    return itemsService.patch(itemID, item, {
      headers,
      user: user || undefined,
    })
      .then((newItem: IItem) => {
        // Update Item to Global State
        setState(
          auctionReducer(state, {
            type: 'ADD_ITEM',
            payload: {
              auctionID,
              item: newItem,
            },
          }),
        );
        return newItem;
      });
  }
  else{
    return Promise.reject(null);
  }
};


/**
 * Removed Given itemID from the Root State 
 *  as well as the Server
 * @param auctionID - Auction for which the Item is in
 * @param itemID - The item's Unique ID
 * @param state - The Current State
 * @param setState - State Setter for the new State
 */
export const removeItem = async (auctionID: string, itemID: string, state: IRootStore, setState: StateSetter): Promise<any> => {
  // Extract Required Keys
  const { authToken, user } = state;
  const headers = authToken ? { Authorization: authToken } : undefined;

  // Remove from Server
  const { client } = state;
  if (!client) return Promise.reject();
  
  const itemService: Service<IItem> = client.service('items');
  return itemService.remove(itemID, {
    headers,
    user: user || undefined,
  })
    // Remove from Global State
    .then(async (res) => {
      // Update State
      setState(auctionReducer(state, {
        type: 'REM_ITEM', payload: {
          auctionID,
          itemID: itemID,
        },
      }));

      // Return the Result from Service Removal
      return res;
    });
};


export const queryItemBids = async (itemID: string, state: IRootStore, setState: StateSetter) => {
  
  // Check if in State
  if (state.bidHistoryStore[itemID].length) {
    return state.bidHistoryStore[itemID];
  }
  
  // Establish Connection
  const { client } = state;
  if(!client) return;

  // Extract Required Keys
  const { authToken, user } = state;

  // Query Item & its Bids
  const bidHistoryService: Service<BidStore> = client?.service('bid-history');
  const params = {
    headers: authToken ? { Authorization: authToken } : undefined,
    user: user || undefined,
    query: {
      itemID,
      $limit: 50
    },
  };


  bidHistoryService.find(params)
    .then((res: any ) => {
      // Add the Bid History to the Item Store State
      setState({
        ...state,
        itemStore: {
          ...state.itemStore,
          [itemID]: {
            ...state.itemStore[itemID],
            bids: res,
          },
        },
      });
      return res;
    });
};