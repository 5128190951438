// React & Style Libraries
import React from 'react';
import {
  createStyles,
  CircularProgress,
  Paper,
  TableContainer, Table, TableRow, TableCell,
  TableHead, TableBody,
  Theme,
  WithStyles, withStyles,
  Button,
  Typography,
  TableFooter,
  TablePagination,
  Grid,
} from '@material-ui/core';

// Components
import PaymentReportDetails from '../../Components/PaymentReportDetails';
import AddressDialog from './AddressInputDialog';

// Type Imports
import { IPurchase } from '../../Interfaces/Database/Purchase';
import { IItem } from '../../Interfaces/Database/Item';
import { ServiceMethods } from '@feathersjs/feathers';
import { AuctionBundle } from '../../Interfaces/Database/PurchaseReport';

// The Store!
import {
  IRootStore,
  RootContext,
  withAppContext,
} from '../../Store/RootStore';
import { QueryResult } from '../../Interfaces/Service';


import { colors } from '../../Config/Global';
// Local Styles
const styles = (theme: Theme) => createStyles({
  root: {
    maxWidth: 800,
    minHeight: 500,
    paddingTop: '2%',
    textAlign: 'center',
    margin: 'auto',
  },
  heading: {
    margin: '15px',
  },
  tableRow: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

// Modified Purchase Data
export interface IHistoryData extends IPurchase {
  item: IItem,    // Item Linked to Purchase
}


// App's Interfaces
interface IProps extends WithStyles<typeof styles> {
  context: IRootStore,
}
interface IState {
  loaded:             boolean,
  //hasActionRequired:  IHistoryData[],     // Items Requiring Action
  pendingPurchases:   IHistoryData[],
  submittedPurchases: AuctionBundle[];
  page:               number,
  rowsPerPage:        number,
  loadingData:        boolean,            // State of Loading Data
  modalItem:          IHistoryData | null,
}

class PurchaseHistory extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    // Initial State
    this.state = {
      loaded: false,
      pendingPurchases: [],
      submittedPurchases: [],
      page: 0,
      rowsPerPage: 5,
      loadingData: false,
      //hasActionRequired: [],
      modalItem: null,
    };
    
    // Bind Member Methods
    this.retrieveData = this.retrieveData.bind(this);
    this.updateShippingAddress = this.updateShippingAddress.bind(this);
  }

  // Navigated to
  componentDidMount() {
    this.init();
  }
  
  /**
   * Once User Data Loads
   * Linked to
   */
  componentDidUpdate() {
    this.init();
  }

  /**
   * Initiates the Component with Data
   */
  private init() {
    const { client, user } = this.props.context;

    // If Client & User Loaded
    if (client && user && !this.state.loaded) {
      this.setState({ loaded: true }, this.retrieveData);
    }
  }

  private retrieveData(){
    const { client, user, authToken } = this.props.context;
    if (!client || !user || !authToken) return;
    const promises = [];

    this.setState({ loadingData: true });

    // Initiate Services
    const purchaseService: ServiceMethods<IPurchase> = client.service('purchases');
    const itemsService: ServiceMethods<IItem> = client.service('items');
    const purchaseReportService: ServiceMethods<any> = client.service('purchases-report');

    // Get Pending Purchase Data and Join with Item Data
    promises.push(purchaseService.find({
      query: {
        userID: user._id,
        $or: [
          {  paidPackageID: 'pending' },
          {  paidPackageID: 'failure' },
        ],
        $limit: 0, // Only get Total Auctions First
      },
      headers: { Authorization: authToken },
      user: user || undefined,
    })
      .then((res: any) => (
        purchaseService.find({
          query: {
            userID: user._id,
            $or: [
              {  paidPackageID: 'pending' },
              {  paidPackageID: 'failure' },
            ],
            $limit: (res as QueryResult<IPurchase>).total,
            $sort: { createdAt: -1 },
          },
          headers: { Authorization: authToken },
          user: user || undefined,
        })
      ))
      .then((res: any) => {
        Promise.all((res.data as IPurchase[]).map(purchase => 
          itemsService.get(purchase.itemID, {
            headers: { Authorization: authToken },
            user,
          })
          .then(itemRes => ({
            ...purchase,
            item: itemRes,
          })),
        ))
          .then(newData => {
            this.setState({ pendingPurchases: newData });
          })
          .catch(err => console.log('Load Data Error', err));
      })
      .catch(err => console.log('Pending Purchases Error', err) )
    );

    // Submitted Purchases
    promises.push(purchaseReportService.get(user._id, {
      query: { userID: user._id },
      headers: { Authorization: authToken },
      paidPackageID: { $nin: [ 'pending', 'failure' ] },
      user,
    })
      .then((res: any) => {
        this.setState({ submittedPurchases: res });
      })
      .catch((err: any) => {
        console.log('Purchase Report Error', err);
      })
    );

    Promise.all(promises).finally(() => this.setState({ loadingData: false }));
  }


  /**
   * Updates Purchase Item's Shipping Address on the Server
   * @param purchaseID
   * @param address Shipping Address of the Item
   * @param shippingType Item's Shipping Type
   */
  private updateShippingAddress(purchaseID: string, address: string | null): Promise<any> {
    const { authToken, user, client } = this.props.context;
    const purchaseService: ServiceMethods<IPurchase> = client?.service('purchases');
    
    return purchaseService.patch(purchaseID || '', {
      shippingAddress: address || undefined,
      deliveryType: address ? 2 : 1,
    }, {
      headers: {
        Authorization: authToken,
      },
      user: user || undefined,
    })
      .then((res: any) => 
        // Update pendingPurchases State
        this.setState(prev => ({
          pendingPurchases: prev.pendingPurchases.reduce((acc: IHistoryData[], curr: IHistoryData) => (
            curr._id === res._id
              ? [...acc, { ...res, item: curr.item }]
              : [...acc, curr]
          ), []),
          modalItem: null,
        })),
      );
  }


  
  render() {
    const { submittedPurchases, pendingPurchases, page, rowsPerPage, loadingData, modalItem } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.root}>

        {modalItem &&
          <AddressDialog
            purchase={modalItem}
            onClose={() => this.setState({ modalItem: null })}
            //@ts-ignore
            onDone={(addr: string | null) => this.updateShippingAddress(modalItem._id, addr)}
          />
        }

        {loadingData
          ? <CircularProgress size='2.5rem' />
          : <>
            {pendingPurchases.length 
              ?  <TableContainer component={Paper}>
                  <Table size='small'>
                    <TableHead>
                      <TableRow style={{ fontSize: 20, fontWeight: 'bold' }}>    
                        Pending Purchases
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {pendingPurchases
                        .map((elt, index) => (
                          <TableRow key={index} className={classes.tableRow}>
                            <TableCell style={{ borderBottom: '1px solid #bfbfbf', borderTop: '1px solid #bfbfbf' }} colSpan={6}>
                              

                                <Grid container>
                                  <Grid container xs={12} md={4}>
                                    <Typography>Item #{elt.item.itemNumber}</Typography>
                                  </Grid>

                                  <Grid container xs={12} md={8}>

                                    <Grid item xs={4}>
                                      <Typography>Item:</Typography>
                                    </Grid>

                                    <Grid item xs={8}>
                                      <Typography>{elt.item.title}</Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                      <Typography>Cost:</Typography>
                                    </Grid>

                                    <Grid item xs={8}>
                                      <Typography>${elt.itemCost.toFixed(2)}</Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                      <Typography>Delivery Method:</Typography>
                                    </Grid>

                                    <Grid item xs={8}>
                                      {elt.deliveryType === 0 
                                        ? 
                                          <Button
                                            onClick={() => this.setState({ modalItem: elt })}
                                            size='small'
                                            variant='outlined'
                                            color='secondary'
                                          >
                                            Action Required
                                          </Button>
                                        : 
                                          <React.Fragment>
                                            <Typography>
                                              {elt.deliveryType === 1 
                                                ? 'Pickup'
                                                : elt.item.shippingCost > 0
                                                  ? `$${elt.item.shippingCost.toFixed(2)} ${elt.item.shippingType}`
                                                  : 'Free'
                                              }
                                            </Typography>
                                            <Typography>
                                              {elt.deliveryType === 2 && 
                                                elt.shippingAddress
                                              }
                                            </Typography>
                                          </React.Fragment>
                                      }
                                    </Grid>
                                  </Grid>

                                </Grid>
                            </TableCell>

                          </TableRow>

                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              : null
            }
            

            <Typography variant="h6" className={classes.heading} component="div">
              Purchase History
            </Typography>
            {submittedPurchases.length
              ?
                <TableContainer component={Paper}>
                  <Table stickyHeader aria-label="sticky table">
                    
                    <TableBody>
                      {submittedPurchases
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <PaymentReportDetails key={index} data={row.data}>
                            <TableCell scope='row' component='th'><strong>Auction:</strong> {row.auction.name} {row.auction.isTesting && '(INTERNAL TESTING)'}</TableCell>
                            <TableCell><strong>Payment Date:</strong> { new Date(row.purchaseDate).toLocaleDateString() }</TableCell>
                          </PaymentReportDetails>
                        ))}
                    </TableBody>
                  </Table>

                  <TableFooter>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component='div'
                      count={submittedPurchases.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={(_, newPage) => this.setState({ page: newPage })}
                      onChangeRowsPerPage={e => this.setState({ rowsPerPage: +e.target.value })}
                    />
                  </TableFooter>
                </TableContainer>
              :  <Typography style={{marginBottom: 20}}>None</Typography>
            }
          </>}

      </div >
    );
  }
}


// Wrap Hooks with HOC
export default withAppContext(
  RootContext, withStyles(styles)(PurchaseHistory),
);