import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles,
  TextField, Button,
  Dialog, DialogActions,
  DialogTitle, DialogContent, DialogContentText,
  Link,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Store & Interfaces
import { RootContext } from '../../Store/RootStore';

// Helper Imports
import { emailRegex } from '../../Helpers/Regex';
import PhoneNumberFormat from '../../Helpers/PhoneNumberFormat';
import { Service } from '@feathersjs/feathers';
import IAuthentication, { IAuthResponse } from '../../Interfaces/Database/Authentication';
import { IUser, IAuthManagement } from '../../Interfaces/Database/User';
import { addCookie } from '../../Helpers';
import ReviewTermsAndConditions from './ReviewTermsAndConditions';


const useStyles = makeStyles({
  root: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  
  loginBtn: {
    margin: '15px',
  },
});

interface IProps {
  dialogOpen: boolean,
  onClose: () => void,
}

export default function RenderProfileSection({ dialogOpen, onClose }: IProps): JSX.Element {
  // Hooks
  const styles = useStyles();
  const store = useContext(RootContext);
  const { client } = store;
  const history = useHistory();     // Routes History
  
  // Login/Register State
  const [isLogin, setIsLogin] = React.useState<boolean>(false);
  const [isRegister, setIsRegister] = React.useState<boolean>(false);
  const [isRegisterComplete, setIsRegisterComplete] = React.useState<boolean>(false);
  const [isForgotPass, setIsForgotPass] = React.useState<boolean>(false);
  const [isFinishSetup, setIsFinishSetup] = React.useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = React.useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [isAgreed, setIsAgreed] = React.useState<boolean>(false);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);


  // Error States
  const [errorMsg, setErrorMsg] = React.useState<string>('');
  const [isEmailErr, setEmailErr] = React.useState<boolean>(false);
  const [isPassErr, setPassErr] = React.useState<boolean>(false);
  const [isPassMismatch, setPassMatchErr] = React.useState<boolean>(false);
  const [isFNameErr, setFNameErr] = React.useState<boolean>(false);
  const [isLNameErr, setLNameErr] = React.useState<boolean>(false);
  const [isPhoneErr, setPhoneErr] = React.useState<boolean>(false);
  const [isAddressErr, setAddressErr] = React.useState<boolean>(false);

  
  // References
  const emailRef = React.createRef<HTMLInputElement>();
  const passRef = React.createRef<HTMLInputElement>();
  const pass2Ref = React.createRef<HTMLInputElement>();
  const fnameRef = React.createRef<HTMLInputElement>();
  const lnameRef = React.createRef<HTMLInputElement>();
  const phoneRef = React.createRef<HTMLInputElement>();
  const addressRef = React.createRef<HTMLInputElement>();
  const forgotPassRef = React.createRef<HTMLInputElement>();

  React.useEffect(() => {
    if(dialogOpen){
      setIsLogin(dialogOpen);
    } else {
      setIsLogin(false);
      setIsRegister(false);
      setIsForgotPass(false);
      setIsEmailSent(false);
      resetState();
    }
  }, [ dialogOpen ]);


  // Resets all States to Default
  const resetState = () => {
    setErrorMsg('');
    setEmailErr(false);
    setPassErr(false);
    setPassMatchErr(false);
    setFNameErr(false);
    setLNameErr(false);
    setPhoneErr(false);
  };

  const setToRegister = () => {
    setErrorMsg('');
    setIsRegister(true); 
    setIsLogin(false);
  };

  // Issues a Login to given User Input
  const login = (email: string, password: string) => {
    const authService: Service<any> = client?.service('authentication');
    authService.create({
      email: email.toLocaleLowerCase(),
      password,
      strategy: 'local',
    } as IAuthentication)
      .then((res: IAuthResponse) => {
        if(!res.user.isStaff){
          // Store Data
          store.actions.setUser(res.user);
          store.actions.setAuthToken(res.accessToken);
          localStorage.setItem('token', res.accessToken);

          // Store Access Token Cookie | Expires in 7 Days
          const expiration = new Date(Date.now() + 1000 * 60 * 60 * 24 * 7);
          addCookie('accessToken', res.accessToken, expiration);
          addCookie('userId', res.user._id, expiration);

          // Update State
          setIsLogin(false);
          setIsRegister(false);
          setSubmitLoading(false);

          // Refresh Page
          history.go(0);
        } else {
          setErrorMsg('(*) Invalid login');
          setSubmitLoading(false);
        }
      })
      .catch(err => {
        setErrorMsg('(*) ' + err.message);
        setSubmitLoading(false);
      });
  };

  const handleNextSteps = () => { 
    const { client } = store;
    const authManagement: Service<IAuthManagement> = client?.service('authManagement');

    setEmailErr(false);
    setErrorMsg('');
    if (!forgotPassRef.current?.value) {
      setEmailErr(true);
      setErrorMsg('(*) Enter your email');
      return;
    }

    let notifierOptions = {};
    if(isFinishSetup){
      notifierOptions = {
        finishUserSetup: true
      }
    }

    setSubmitLoading(true);
    authManagement.create({
      action: 'sendResetPwd',
      value: {
        email: forgotPassRef.current.value.trim().toLocaleLowerCase(),
      },
      notifierOptions
    } as Partial<IAuthManagement>)
      .then(() => {
        setIsEmailSent(true);
        setIsForgotPass(false);
        setIsFinishSetup(false);
      })
      .catch((err: any) => {
        console.log('err: ', err);
        if ((err.message as string).includes('User not found')) {
          setErrorMsg('(*) User not found');
        } else {
          setErrorMsg('(*) Error on the server');
        }
        setEmailErr(true);
      })
      .finally(() => setSubmitLoading(false));
  };

  const handleRegister = () => {
    const { value: email } = emailRef.current as HTMLInputElement;
    const { value: pass1 } = passRef.current as HTMLInputElement;
    const { value: pass2 } = pass2Ref.current as HTMLInputElement;
    
    resetState();
    
    if (!email || email.match(emailRegex) === null) {
      setEmailErr(true);
      setSubmitLoading(false);
      return emailRef.current?.focus();
    } else if (!pass1) {
      setPassErr(true);
      setSubmitLoading(false);
      return passRef.current?.focus();
    } else if (!pass2 || pass1 !== pass2) {
      setPassMatchErr(true);
      setSubmitLoading(false);
      return pass2Ref.current?.focus();
    } else if (!fnameRef.current?.value) {
      setFNameErr(true);
      setSubmitLoading(false);
      return fnameRef.current?.focus();
    } else if (!lnameRef.current?.value) {
      setLNameErr(true);
      setSubmitLoading(false);
      return lnameRef.current?.focus();
    } else if (!phoneRef.current?.value) {
      setPhoneErr(true);
      setSubmitLoading(false);
      return phoneRef.current?.focus();
    } else if (!addressRef.current?.value) {
      setAddressErr(true);
      setSubmitLoading(false);
      return addressRef.current?.focus();
    }

    if(phoneRef.current?.value){
      for(let i = 0; i < phoneRef.current.value.length; i++){
        if((phoneRef.current.value[i] < '0' || phoneRef.current.value[i] > '9') && phoneRef.current.value[i] != '-'){
          setPhoneErr(true);
          setSubmitLoading(false);
          return phoneRef.current?.focus();
        }
      }
    }

    if(!isAgreed){
      setErrorMsg('(*) You must agree to the terms and conditions');
      setSubmitLoading(false);
      return;
    }

    //Attempt to Register the User
    const userService: Service<IUser> = client?.service('users');
    userService.create({
      email: email.toLocaleLowerCase(),
      password: pass1,
      firstName: fnameRef.current.value,
      lastName: lnameRef.current.value,
      phoneNumber: phoneRef.current.value,
      address: addressRef.current.value.trim(),
    })
      .then(() => {
        setIsRegisterComplete(true);
        setIsRegister(false);
        setIsLogin(true);
        setSubmitLoading(false);
      })
      .catch(err => {
        console.log('Create User Error:', err);
        setSubmitLoading(false);
        if (err.code === 409 || err.message === 'User Email Already Exists') {
          setErrorMsg('(*) Email Already Exists');
          return emailRef.current?.focus();
        } else {
          setErrorMsg('(*) An error has occurred');
        }
      });
    
  };

  // Handles Logging the User in
  const handleLogin = () => {
    // Destructure for Readability
    const { value: email } = emailRef.current as HTMLInputElement;
    const { value: password } = passRef.current as HTMLInputElement;
    
    // Assume Everything is all Good!
    resetState();
    
    // Quick Verification
    if (email.match(emailRegex) === null) {
      setEmailErr(true);
      setSubmitLoading(false);
      return emailRef.current?.focus();
    }
    else if (!password) {
      setPassErr(true);
      setSubmitLoading(false);
      return passRef.current?.focus();
    }

    // Attempt to Log User in
    login(email, password);
  };

  // Reset & Close Form
  const handleFormClose = () => {
    setIsLogin(false);
    setIsRegister(false);
    setIsForgotPass(false);
    setIsFinishSetup(false);
    resetState();
    onClose();
  };

  // Handles Submitting the Current Form
  const handleSubmit = () => {
    if(isRegisterComplete){
      // Bring user back to login 
      setIsRegisterComplete(false);
    } else {
      setSubmitLoading(true);
      if(isLogin){
        handleLogin();
      } else{
        handleRegister();
      }
    }
  };

  return (<>
    
    {/* ----------------------------------------------- Forgot Password Dialog -----------------------------------------------*/}
    <Dialog open={Boolean(isForgotPass || isFinishSetup || isEmailSent)} aria-labelledby="form-dialog-login">
      <DialogTitle id="form-dialog-login" style={{textAlign: 'center', margin: '10px'}}>
        {isForgotPass ? 'Forgot Your Password?' : 'Finish Account Setup'}
      </DialogTitle>
      <DialogContent style={{contain: 'content'}}>
        {!isEmailSent
          ? <>
              <DialogContentText style={{textAlign: 'center', margin: '10px'}}>
                Enter the email that is connected to your account. 
              </DialogContentText>

              <TextField
                id="standard-basic" 
                label="Email"
                variant='outlined'
                style={{width: '100%', margin: '10px'}}
                inputRef={forgotPassRef}
              />

              <DialogContentText variant='subtitle1' style={{ color: '#E74C3C', textAlign: 'right'}}>
                {errorMsg}
              </DialogContentText>

              <Button 
                className={styles.loginBtn}
                onClick={() => {
                  setErrorMsg('');
                  setIsLogin(true);
                  setSubmitLoading(false);
                  isForgotPass ? setIsForgotPass(false) : setIsFinishSetup(false); 
                }}
                color="primary"
                variant='outlined'
              >Cancel</Button>

              <Button
                className={styles.loginBtn}
                style={{float: 'right'}}
                onClick={handleNextSteps}
                color="primary"
                variant='contained'
              >
                {submitLoading 
                ? <div style={{ width: '100%', textAlign: 'center'}}>
                    <CircularProgress size='1.5rem' style={{ color: 'white' }} />
                  </div>
                : 'Submit'
              }   
              </Button>
            </>
          : <>
              <DialogContentText variant='subtitle1' style={{textAlign: 'center', margin: '10px', marginBottom: '30px', color: '#E74C3C'}}>
                An email has been sent regarding your next steps!
              </DialogContentText>
              <Button color="primary" fullWidth variant='contained' onClick={handleFormClose}>Close</Button>
            </>
        }

      </DialogContent>
    </Dialog>

    {/* ----------------------------------------------- Login/Register Dialog -----------------------------------------------*/}
    <Dialog open={Boolean(isLogin || isRegister)} aria-labelledby="form-dialog-login">
       
      <DialogTitle id="form-dialog-login" style={{textAlign: 'center'}}>
        <CloseIcon style={{float: 'right'}} onClick={handleFormClose} />
        {isRegister 
          ? 'Register' 
          : isRegisterComplete
            ? 'Registration Complete' 
            : 'Login'
        }
      </DialogTitle>

      {/* Login Info */}
      <DialogContent>

        {isRegisterComplete
          ?
            <div>
              <Typography>
                You have been successfully registered. A confirmation link has been sent to your email to verify your account.
              </Typography>
              <Typography variant='subtitle2' style={{ marginTop: '5px', color: 'red', fontStyle: 'italic' }}>
                Note: Many users are reporting that the email is not received. You still have full access 
                to your account without verifying your email.  
              </Typography>
            </div>
          :
            <div>
              {isLogin &&
                <Typography>Welcome to Royale Treasures Auction! Login to get started.</Typography>
              }
      
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email Address"
                type="email"
                fullWidth
      
                error={Boolean(isEmailErr)}
                helperText={isEmailErr ? 'Invalid Email Address.' : null}
                inputRef={emailRef}
              />
              <TextField
                margin="dense"
                id="pass"
                label="Password"
                type="Password"
                hidden
                fullWidth
                onKeyPress={e => {
                  if (e.key === 'Enter') handleSubmit();
                }}
      
                error={Boolean(isPassErr)}
                helperText={isPassErr ? 'Invalid Password.' : null}
                inputRef={passRef}
              />
      
              {isRegister && (<>
                <TextField
                  margin="dense"
                  id="pass2"
                  label="Re-Type Password"
                  type="Password"
                  hidden
                  fullWidth
      
                  error={Boolean(isPassMismatch)}
                  helperText={isPassMismatch ? 'Passwords do not match' : null}
                  inputRef={pass2Ref}
                />
      
                <TextField
                  margin="normal"
                  id="fname"
                  label="First Name"
                  fullWidth
      
                  error={Boolean(isFNameErr)}
                  helperText={isFNameErr ? 'Invalid Entry.' : null}
                  inputRef={fnameRef}
                />
      
                <TextField
                  margin="normal"
                  id="lname"
                  label="Last Name"
                  fullWidth
      
                  error={Boolean(isLNameErr)}
                  helperText={isLNameErr ? 'Invalid Entry.' : null}
                  inputRef={lnameRef}
                />
      
                <TextField
                  margin="normal"
                  id="phone"
                  label="Phone Number"
                  fullWidth
                  onKeyPress={e => {
                    if (e.key === 'Enter') handleSubmit();
                  }}
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                  InputProps={{
                    inputComponent: PhoneNumberFormat as any,
                  }}
                  error={Boolean(isPhoneErr)}
                  helperText={isPhoneErr ? 'Invalid Entry.' : null}
                  inputRef={phoneRef} 
                />
      
                <TextField
                  margin="normal"
                  id="address"
                  label="Address"
                  fullWidth
                  multiline
      
                  error={Boolean(isAddressErr)}
                  helperText={isAddressErr ? 'Invalid Entry.' : null}
                  inputRef={addressRef}
                />

                <ReviewTermsAndConditions
                  isAgreed={isAgreed}
                  onChange={setIsAgreed}
                />  
              </>)}
      
      
              {/*TODO: Password Reset */}
              {isLogin && (
                <div style={{ textAlign: 'right' }}>
                  <Link href='#' variant='body2' onClick={() => { setIsLogin(false); setIsForgotPass(true); }}>
                    Forgot Password?
                  </Link>
                </div>
              )}
            </div>
        }
        <DialogActions style={{ flexDirection: 'column', justifyContent: 'center', marginBottom: '10px' }}>
          <Button className={styles.loginBtn} onClick={handleSubmit} color="primary" variant='contained' fullWidth disabled={submitLoading}>
            {submitLoading 
              ? <div style={{ width: '100%', textAlign: 'center'}}>
                  <CircularProgress size='1rem' style={{ margin: 'auto' }} />
                </div>
              : <Typography>{isLogin ? 'Login' : 'Register'}</Typography>
            }   
          </Button>

          <DialogContentText variant='caption' style={{ color: '#E74C3C' }}>
            {errorMsg}
          </DialogContentText>


          {/* User Registration */}
          {isLogin && !isRegisterComplete &&
            <>
              <DialogContentText>
                Account created on your behalf?
                <Link href='#' onClick={() => { setIsLogin(false); setIsFinishSetup(true);}}> Finish setup </Link>
              </DialogContentText>
              <DialogContentText>
                Don&apos;t have an account?
                <Link href='#' onClick={setToRegister}> Sign up </Link>
              </DialogContentText>
            </>
          }
        </DialogActions>
        
      </DialogContent>
    </Dialog> </>
  );
}