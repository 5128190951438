import React from 'react';
import {
  BrowserRouter as Router, 
  Link as RouterLink,
  Route,
  Switch,
} from 'react-router-dom';
import {
  createStyles, Theme, 
  WithStyles, withStyles, Container,
  Typography,
  IconButton, CssBaseline, Grid, Hidden, Box
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { parseCookie, removeCookie } from './Helpers';
import Config from './Config/Global';

// Components
import MyAccountDialog from './Components/Profile/MyAccountDialog';
import RenderProfileSection from './Components/Profile/RenderProfileSection';
import DrawerNav from './Components/Navigation/DrawerNav';
import LoginRegisterDialog from './Components/Profile/LoginRegisterDialog';

// Routes
import Home from './Routes/Home';
import ClosedAuctions from './Routes/ClosedAuctions';
import PurchaseHistory from './Routes/PurchaseHistory';
import Payment from './Routes/Payment';
import Page404 from './Routes/Page404';
import FAQ from './Routes/FAQ';
import PassReset from '././Routes/PasswordReset';
import VerifyEmail from '././Routes/VerifyEmail';

// Feathers!
import io from 'socket.io-client';
import socketio from '@feathersjs/socketio-client';
import feathers, { ServiceMethods } from '@feathersjs/feathers';

// Auctions
import { addAuction, updateAuction, removeAuction, queryAllAuctions, removeItem, addItem, updateItem, 
  addBid, queryItemBids, queryBidHistory, queryAuctionReport, updateDeliveryData,
  queryItemBidders, setSlideshowItems, queryItemsNextPage, addItemToStore, retrieveItem,
} from './Store/Actions';

// Interfaces & Global Styles
import 'fontsource-roboto';
import { IRootStore, RootContext } from './Store/RootStore';
import { IAuction } from './Interfaces/Database/Auction';
import { IItem } from './Interfaces/Database/Item';
import { colors } from './Config/Global';
import { IBid } from './Interfaces/Database/Bid';
import { ICategory } from './Interfaces/Database/Category';
import { IPurchase } from './Interfaces/Database/Purchase';
import { addPurchase } from './Store/Actions/Purchases';
import {
  AuctionStore, BidStore,
  auctionReducer,
} from './Store/Reducers/AuctionReducer';

// Images
import logoImage from './images/logo-transparent.png';
import { updatePurchase, updatePurchaseData } from './Store/Actions/Report';
import { ReportAction } from './Store/Reducers/ReportReducer';
import { termsOfService, biddersTermsOfService, privacySecurity } from './Components/Profile/PolicyText';
import Charity from './Routes/Charity';
import { QueryResult } from './Interfaces/Service';
import UserTabs from './Routes/Users';
import ViewAuction from './Components/Auction/ViewAuction';
import CreateAuctionStepper from './Components/Auction/CreateAuctionStepper';
import ReviewTermsAndConditions from './Components/Profile/ReviewTermsAndConditions';
import FinishSetup from './Routes/Setup';


const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
    },
    header: {
      backgroundColor: colors.narBarSecondaryNearWhite,
    },
    footer: {
      minHeight: '20vh',
      backgroundColor: colors.narBarSecondaryNearWhite,
      marginTop: '100px',
    },
    centerGrid: {
      textAlign: 'center',
      objectFit: 'contain',
    },
    footerBox: {
      textAlign: 'center',
      objectFit: 'contain',
      marginTop: '30px',
    },
    home: {
      backgroundColor: '#ffe6e6',
      width: '200px',
    },
    appBar: {
      backgroundColor: colors.navBarMain, 
    },
    menuButton: {
      marginLeft: '20px',
    },
    hide: {
      display: 'none',
    },
    content: {
      width: '100%',
      position: 'relative',
      minHeight: '100%',
    },
    subSection:{
      paddingLeft: '10px',
    },
    profile: {
      alignItems: 'center',
      justify: 'center',
    },
    menuSection: {
      marginRight: 'auto',
      display: 'flex',
      flexDirection: 'row',
      gap: '1em',
      // Nested Selector: All Children
      '& *': {
        color: colors.lightFont,
        fontWeight: 'bold',  
        textDecoration: 'none',
      },
    },
    homeLogo: {
      maxHeight: '175px',
      maxWidth: '90%',
      margin: '10px',
    },
    remHyper:{
      color: '#000',
      textDecoration: 'none',
    },
    text: {
      textAlign: 'center',
      color: colors.darkBlueFont,
    },
  });


// App's Interfaces
export interface ICategoryMap{
  [key: string]: string,
}

interface IProps extends WithStyles<typeof styles> {
} 
interface IState extends IRootStore {
  profileOpen: boolean,
  loginOpen: boolean,
  drawerOpen: boolean,
  createAuctionOpen: boolean,
  auctions: IAuction[],
  announceAuctions: IAuction[],
  categoryMap: ICategoryMap,
  square: any,
}


class App extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const setState = this.setState.bind(this);

    // Initial State
    this.state = {
      //IRootStore
      user:               null,
      client:             null,
      socket:             null,
      authToken:          null,
      auctionStore:       {},
      bidHistoryStore:    {},
      itemStore:          {},
      auctionReportStore: {},
      nextBidderStore:    {},
      slideshowItems:     [],
      square:             undefined,

      // IRootStore Methods
      actions: {
        setUser:          user => this.setState({ user }),
        setAuthToken:     authToken => this.setState( { authToken }),
        setSlideshowItems: () => setSlideshowItems(this.state, setState),

        // Auction
        queryAllAuctions: () => queryAllAuctions(this.state, setState) as any,
        addAuction:       (auction: Partial<IAuction>) => addAuction(auction, this.state, setState),
        updateAuction:    (id: string, auction: Partial<IAuction>) => updateAuction(id, auction, this.state, setState),
        remAuction:       (auctionID: string) => removeAuction(auctionID, this.state, setState),
        queryItemsNextPage:(auctionID: string, currentPage: number, itemsPerPage: number, totalItems: number) => queryItemsNextPage(auctionID, currentPage, itemsPerPage, totalItems, this.state, setState),

        // Item
        addItem:          (auctionID: string, item: IItem) => addItem(auctionID, item, this.state, setState),
        addItemToStore:   (auctionID: string, item: IItem) => addItemToStore(auctionID, item, this.state, setState),
        updateItem:       (auctionID: string, itemID: string, item: Partial<IItem>) => updateItem(auctionID, itemID, item, this.state, setState),
        remItem:          (auctionID: string, itemID: string) => removeItem(auctionID, itemID, this.state, setState),
        queryItemBids:    (itemID: string) => queryItemBids(itemID, this.state, setState) as any,
        retrieveItem:    (itemID: string) => retrieveItem(itemID, this.state, setState) as any,

        // Bid
        addBid:           (bid: IBid) => addBid(bid, this.state, setState),
        queryBidHistory:  (itemID: string, first: boolean) => queryBidHistory(itemID, first, this.state, setState),

        //Purchase
        addPurchase:      (purchase: Partial<IPurchase>) => addPurchase(purchase, this.state, setState),

        //Report
        queryAuctionReport: (auctionID: string) => queryAuctionReport(auctionID, this.state, setState),
        updateDeliveryData: (data: ReportAction) => updateDeliveryData(data, this.state, setState),
        queryItemBidders:   (itemID: string) => queryItemBidders(itemID, this.state, setState),
        updatePurchase:     (data: updatePurchaseData) => updatePurchase(data, this.state, setState),
      },

      //States Defined in App
      profileOpen:      false,
      loginOpen:        false,
      drawerOpen:       false,
      createAuctionOpen: false,
      auctions:         [],
      announceAuctions: [],
      categoryMap:      {},
    };
  }

  // Configure the Feathers Client
  componentDidMount() {
    // Read in Access Token
    const cookies: any = parseCookie(document.cookie);

    // Configure Feathers Application
    const client = feathers();
    const socket = io(Config.SERVER_URL);
    client.configure(socketio(socket, {timeout: 10000}));

    // Appending Square script
    const sqScript = document.createElement('script');
    sqScript.src = Config.SQUARE_SCRIPT;
    sqScript.type = 'text/javascript';
    sqScript.async = false;
    sqScript.onload = () => {
      this.setState({ square: (window as any).Square });
    };
    document.getElementsByTagName('head')[0].appendChild(sqScript);
  
    // Init Sockets
    this.initSockets(socket, cookies);

    // Upon state change, retrieve data
    this.setState({ client, socket });
  }

  initSockets = (socket: SocketIOClient.Socket, cookies: any) => {
    const { user, actions } = this.state;
    
    socket.on('connect', () => {
      console.log('Socket Connected');

      // Login with Access Token
      if (cookies['accessToken'] && cookies['userId']) {
        socket
          .emit('create', 'authentication', {
            strategy: 'jwt',
            accessToken: cookies['accessToken'],
          }, (err: any, newAuthResult: any) => {
            // Auth Token Authentication failed
            if (err) {
              console.log('Invalid Cookie Data:', err);
              return removeCookie('accessToken');
            }

            if(!newAuthResult.user.isStaff){
              // Store Response
              this.setState({
                user: newAuthResult.user,
                authToken: newAuthResult.accessToken,
              }, () => {
                //Once user data loads, query auctions
                actions.setSlideshowItems();
                this.queryAuctions();
                this.queryCategories();
              });
            }
          })
          .on('error', (err: any) => console.log('error', err));
      } else {
        actions.setSlideshowItems();
        this.queryAuctions();
        this.queryCategories();
      }

      // Socket Listeners
      socket
        //TODO: socket does not work when user not signed in
        .on('bids created', (bid: IBid) => {                    // Created Bid
          const { itemStore } = this.state;
          // If Item already exists in Global State
          if(itemStore[bid.itemID]){
            let addHighestBid = false;
            if(itemStore[bid.itemID].highestBid){
              const previousBid = (itemStore[bid.itemID].highestBid as BidStore).bid;
              if(previousBid.price < bid.price){
                addHighestBid = true;
              } else if(previousBid.price === bid.price){
                if(new Date(bid.initalTimeSet).getTime() < new Date(previousBid.initalTimeSet).getTime()){
                  addHighestBid = true;
                }
              }
            } else {// starting bid
              addHighestBid = true;
            }

            if(addHighestBid) {
              this.setState(auctionReducer(this.state, {
                type: 'ADD_BID_ITEM',
                payload: {
                  bid,
                  number: (bid as any).bidderNumber || -1,
                } as BidStore,
              }));
            } else {
              this.setState(auctionReducer(this.state, {
                type: 'ADD_BID',
                payload: {
                  bid,
                  number: (bid as any).bidderNumber || -1,
                } as BidStore,
              }));
            }
          }
        })
        .on('auctions created', (auction: IAuction) => {        // Created Auction
          // Add to state if not already there
          if(!this.state.auctionStore[auction._id]){
            this.setState(prevState => auctionReducer(prevState, {
              type: 'ADD_AUCTION',
              payload: {
                [auction._id]: {
                  auction,
                  items: null,
                },
              } as AuctionStore,
            }), () => this.queryAuctions());
          }
        })
        .on('auctions patched', (auction: IAuction) => {        // Patch Auction
          this.setState(prevState => auctionReducer(prevState, {
            type: 'ADD_AUCTION',
            payload: {
              [auction._id]: {
                auction,
                items: prevState.auctionStore[auction._id] && prevState.auctionStore[auction._id].items || null,
              },
            } as AuctionStore,
          }), () => this.queryAuctions());
        })
        .on('auctions removed', (auction: IAuction) => {        // Removed Auction
          this.setState(prevState => auctionReducer(prevState, {
            type: 'REM_AUCTION',
            payload: auction._id,
          }), () => this.queryAuctions());

        })
        .on('purchases created', (purchase: IPurchase) => {     // Created Purchase
          // Update state if not user and the item exists in the itemStore
          if(purchase.userID !== user?._id && this.state.itemStore[purchase.itemID]){
            this.setState(prevState => auctionReducer(prevState, {
              type: 'ADD_PURCHASE',
              payload: purchase.itemID as string,
            }));
          }
        });
    });
  };

  isSuperAdmin = () => {
    const { user } = this.state;
    if(user?.isStaff){
      return false;
    }
    return user?.permissions?.includes('admin');
  }

  queryAuctions = () => {
    const { actions, user } = this.state;

    // Get the Number of Auctions
    actions.queryAllAuctions()
      .then((data: IAuction[]) => {
        // If User is not Admin, Filter Out Recently Closed Auction - Only For Announcement 
        if(this.isSuperAdmin()){
          this.setState({ auctions: data });
        } else {
          this.setState({ auctions: data.filter(data => data.state !== 3 || data.charityName !== undefined) });
        }
        this.setState({announceAuctions: data.filter(data => data.state > 0 && data.state < 4 ) });


      })
      .catch(err => console.log('Auction Error', err));
  };

  // Retrieve Category Data and Create Map
  queryCategories = () => {
    if(!this.state.client) return;
    const categoryService: ServiceMethods<ICategory> = this.state.client?.service('categories');

    categoryService.find({
      headers: { Authorization: this.state.authToken },
      user: this.state.user || undefined,
      query: { $limit: 0 },
    })
      .then((res: any) => (
        categoryService.find({
          headers: { Authorization: this.state.authToken },
          user: this.state.user || undefined,
          query: { $limit: (res as QueryResult<IItem>).total },
        })
      ))
      .then((res: any) => res.data)
      .then((data: ICategory[]) => {
        const categoryMap = data.reduce((acc, elt) => ({
          ...acc, 
          [elt._id]: elt.name,
        }), {});
        this.setState({ categoryMap });
      })
      .catch(err => console.log('Category Error', err));
  };

  addAuction = (id: string | null, auction: Partial<IAuction>, history: History): Promise<any> => {
    const { actions } = this.state;

    return new Promise((resolve, reject) => { 
      actions.addAuction(auction) 
      .then((res: any) => {

        // Add To the Auction List
        const newAuctionList = this.state.auctions
          ? [res, ...this.state.auctions]
          : [ res ];

        // Add Public Auction to Annoucements List
        const annoucements = res.state === 1 
          ? this.state.announceAuctions
            ? [res, ...this.state.announceAuctions]
            : [ res ]
          : [ ...this.state.announceAuctions ];


        this.setState({ 
          auctions: newAuctionList,
          announceAuctions: annoucements,
          createAuctionOpen: false,
        }, () => {
          (history as any).push(`/auctions/${res._id}`);
        });
       
        resolve(res);
      })
      .catch(err => {
        console.log('Creation Error: ', err);
        reject(err);
      });
    });
  };

  // Updates a Modified Auction
  updateAuction = (id: string | null, auction: Partial<IAuction>): Promise<any> => {
    const { actions } = this.state;
    if(!id) return Promise.reject(null);

    return new Promise((resolve, reject) => {
      actions.updateAuction(id, auction)
        .then((moddedAuction: any) => {
          
          //Update Auction State
          this.setState(prevState => ({
            auctions: prevState.auctions
            ? prevState.auctions.map(elt => elt._id === id ? moddedAuction : elt)
            : [ moddedAuction ],
            announceAuctions: prevState.announceAuctions
            ? prevState.announceAuctions.map(elt => elt._id === id ? moddedAuction : elt)
            : [ moddedAuction ],
          }));

          resolve(moddedAuction);
        })
        .catch(err => {
          console.log('Update Error: ', err);
          reject(err);
        });
    });
  };

  deleteAuction = (id: string, history: History): void => {

    // Update Global State
    this.state.actions.remAuction(id)
      .then((res: IAuction) => {

        // Remove the deleted auction from the list
        const auctions = this.state.auctions
          ? this.state.auctions.filter(elt => elt._id !== res._id)
          : [];

        const announceAuctions = this.state.auctions
          ? this.state.announceAuctions.filter(elt => elt._id !== res._id)
          : [];

        this.setState({
          auctions,
          announceAuctions,
        });

        (history as any).push('/'); 
      })
      .catch(err => {console.log('Update Error: ', err);});
  };

  

  render() {
    const { classes } = this.props;
    const { square, drawerOpen, createAuctionOpen, auctions, announceAuctions, profileOpen, loginOpen, categoryMap } = this.state;

    return (
      <RootContext.Provider value={this.state}>
        <div className={classes.root}>

          {/* Page Routes */}
          <Router>

            <CreateAuctionStepper
              auction={null}
              isOpen={createAuctionOpen}
              onClose={() => this.setState({ createAuctionOpen: false })}
              onUpdateOrAdd={this.addAuction}
            />

            <LoginRegisterDialog
              dialogOpen={loginOpen}
              onClose={() => this.setState({loginOpen: false}) }
            />

             <MyAccountDialog
              isOpen={profileOpen}
              onClose={() => this.setState({ profileOpen: false })}
            />

            {/* Navigation */}
            <CssBaseline />

            {/* Drawer Navigation Listing all Auctions */}
            <DrawerNav 
              auctions={auctions.filter(auction => auction.state === 1 || auction.state === 2)} // Public Future, Open
              auctionsAdmin={auctions.filter(auction => auction.state === 0 || auction.state > 2)} // Private Future, Closed, Past
              isOpen={drawerOpen}
              onClose={() => this.setState({drawerOpen: false})}
              onCreateAuction={() => this.setState({ createAuctionOpen: true, drawerOpen: false })}
            />

            <main className={classes.content}>

              {/* --------------------------------------------  Header ---------------------------------------------*/}
              <Grid container className={classes.header}>
              

                 {/* Logo */}
                 <Grid item xs={12} className={classes.centerGrid}>
                  <img src={logoImage} className={classes.homeLogo} />
                </Grid>

                {/* Open Side Nav Bar */}
                <Grid item xs={6}>
                  {/* Only Display Icon for Small Screens */}
                  <Hidden lgUp>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={() => this.setState({ drawerOpen: true }) }
                      edge="start"
                      className={classes.menuButton}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Hidden>
                </Grid>


                 {/* Profile Menu */}
                <Grid item xs={6}>
                  <RenderProfileSection
                    openAccount={() => this.setState({profileOpen: true}) }
                    onLogin={() => this.setState({loginOpen: true})}
                  />
                </Grid> 

              </Grid>

              {/* -------------------------------------------- Main Content ---------------------------------------------*/}
              <Container maxWidth='lg' > 

                <Switch>

                  {/* Home Page */}
                  <Route exact path='/'>
                    <Home auctions={announceAuctions}/>
                  </Route>

                  {auctions &&
                    auctions.map((el, index) => {
                      return (
                        <Route key={index} path={`/auctions/${el._id}`}>

                          {el.state >= 0 && el.state <= 2 &&
                            <ViewAuction 
                              onAuctionUpdate={this.updateAuction} 
                              onAuctionDelete={this.deleteAuction}
                              auction={el} 
                              categories={categoryMap}
                              onLogin={() => this.setState({loginOpen: true})}
                            /> 
                          }
                          {this.isSuperAdmin() && el.state >= 3 &&
                            <ClosedAuctions 
                              auction={el} 
                              categories={categoryMap}
                            />
                          }
                        </Route>
                      );
                    })

                  }

                  {this.isSuperAdmin() && 
                    <Route path='/users'>
                      <UserTabs />
                    </Route>
                  }

                  {/* Payments Page */}
                  <Route path='/profile/purchases'>
                    <PurchaseHistory />
                  </Route>
                  

                  {/* TEST: Payments Page */}
                  <Route path='/payment'>
                    <Payment square={square} />
                  </Route>

                  {/* Charity Auctions */}
                  <Route path='/charity'>
                    <Charity auctions={auctions.filter(auction => auction.charityName)} />
                  </Route>

                  {/* Frequently Asked Questions */}
                  <Route path='/faq'>
                    <FAQ />
                  </Route>

                  {/* TODO: Help Center instead of FAQ */}
                  {/* <Route path='/help-center'>
                    <HelpCenter />
                  </Route> */}

                  {/* Terms of Service */}
                  <Route path='/terms-of-service'>
                    <Box>
                      <Typography className={classes.text} style={{padding: 10}}variant="h5">Terms of Service</Typography>
                      {termsOfService(true)}
                    </Box>
                  </Route>

                  {/* Bidders Terms of Service */}
                  <Route path='/bidders-terms-of-service'>
                    <Box>
                      <Typography className={classes.text} style={{padding: 10}}variant="h5">Bidders Terms of Service</Typography>
                      {biddersTermsOfService()}
                    </Box>
                  </Route>

                  <Route path='/privacy-security'>
                    <Box>
                      <Typography className={classes.text} style={{padding: 10}}variant="h5">Privacy and Security Policy</Typography>
                      {privacySecurity()}
                    </Box>
                  </Route>

                  <Route path='/verify' render={({ location }) => <>
                    <Home auctions={auctions}/>
                    <VerifyEmail locationSearch={location.search} />
                  </>} />
                  
                  <Route path='/setup' render={({ location }) => <>
                    <Home auctions={auctions}/>
                    <FinishSetup locationSearch={location.search} />
                  </>} />

                  <Route path='/reset' render={({ location }) => <>
                    <Home auctions={auctions}/>
                    <PassReset locationSearch={location.search} />
                  </>} />

                  {/* Page Not Found */}
                  <Route component={Page404} /> 


                </Switch>
              </Container>

              {/* -------------------------------------------- Footer ---------------------------------------------*/}
              {/* <div className={classes.footer}> */}
                <Grid container className={classes.footer}>

                  <Grid item xs={12} md={4} className={classes.footerBox}>
                    <Typography variant='h5' style={{paddingBottom: '10px'}}>Location</Typography>
                    <Typography>Royale Treasures Auction</Typography>
                    <Typography>6103 M 80</Typography>
                    <Typography>Kinross, MI 49752</Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.footerBox}>
                    <Typography variant='h5' style={{paddingBottom: '10px'}}>Contact</Typography>
                    <Typography>906-440-4104</Typography>
                    <Typography>royaletreasuresauction@gmail.com</Typography>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.footerBox} style={{marginBottom: 30}}>
                    <Typography variant='h5' style={{paddingBottom: '10px'}}>Policies</Typography>
                    <RouterLink className={classes.remHyper} to={'/terms-of-service'}>
                      <Typography>Terms of Service</Typography> 
                    </RouterLink>
                    <RouterLink className={classes.remHyper} to={'/bidders-terms-of-service'}>
                      <Typography>Bidders Terms of Service</Typography> 
                    </RouterLink>
                    <RouterLink className={classes.remHyper} to={'/privacy-security'}>
                      <Typography>Privacy and Security Policy</Typography> 
                    </RouterLink>
                  </Grid>

                </Grid>
              {/* </div> */}
            </main>

          </Router>
          
        </div>
        
      </RootContext.Provider>
    );
  }
}

// Export with Local Styles
export default withStyles(styles)(App);
