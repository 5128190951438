import React from 'react';
import ReactPaginate from 'react-paginate';
import '../Styles/react-paginate.css';

type screenSizes = 'phone' | 'tablet' | 'desktop';
    
interface Props {
  totalElements: number,
  elementsPerPage: number, 
  currentPage: number,
  onPageChange: (data: any) => void, 
}

export default function Paginate ({ totalElements, elementsPerPage, currentPage, onPageChange }: Props): JSX.Element {

  const [screenType, setScreenType] = React.useState<screenSizes>('phone');

  const handleWindowSizeChange = () => {
    const newWidth = window.innerWidth;
    const newScreen: screenSizes = newWidth > 500
      ?  newWidth > 1000
        ? 'desktop'
        : 'tablet'
      : 'phone';
    if(newScreen !== screenType){
      setScreenType(newScreen);
    }
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
  }, []);


  return (
    <ReactPaginate
      previousLabel={'previous'}
      nextLabel={'next'}
      breakLabel={'...'}
      breakClassName={'break-me'}
      pageCount={Math.ceil(totalElements / elementsPerPage)}
      marginPagesDisplayed={1}
      pageRangeDisplayed={
        screenType === 'phone'
        ? 1
        : screenType === 'tablet'
          ? 5
          : 10
      }
      onPageChange={onPageChange}
      forcePage={currentPage}
      containerClassName={'pagination'}
      previousLinkClassName={'pagination__link'}
      nextLinkClassName={'pagination__link'}
      disabledClassName={'pagination__link--disabled'}
      activeClassName={'pagination__link--active'}
    />
  );
}