import React, { useLayoutEffect, useState }  from 'react';
import { 
  Theme, makeStyles, createStyles,
  Grid, Button, Box,
} from '@material-ui/core';
//@ts-ignore
import Compress from 'client-compress';
import { colors } from '../../Config/Global';


const useStyles = makeStyles((theme: Theme) => 
  createStyles({
    MainGrid:{
      width: '100%',
      border: '1px black solid',
      margin: 'auto',
      backgroundColor: colors.navBarSecondary6up,
    },
    MainGridNoImage:{
      width: '100%',
      height: '0',
      paddingBottom: '90%',
      backgroundColor: colors.navBarSecondary6up,
      margin: 'auto',
      border: '1px #C02519 solid',
    },
    ThumbnailsContainer: {
      margin: '5px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    ThumbnailGrid: {
      margin: '3px',
      border: '1px black solid',
      backgroundColor: colors.navBarSecondary6up,
    },
    MainThumbnailGrid:{
      margin: '3px',
      border: '3px #000 solid',
      backgroundColor: colors.navBarSecondary6up,
    },
    MainImage:{
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
    ThumbnailImage:{ //TODO: do same square with thumbnails
      width: '50px',
      height: '50px',
      objectFit: 'contain',
    },
    button: {
      width: '80px',
      height: '50px',
      display: 'inline-grid', 
      textAlign: 'center',
      border: '1px solid black',
      borderRadius: '2px',
      padding: '5px',
      margin: '0px 10px 10px',
      backgroundColor: colors.navBarSecondary5up,
    },
    imageChangeBar: {
      width: '100%',
      borderRadius: '5px',
      paddingTop: '10px',
      marginBottom: '10px',
      backgroundColor: colors.navBarSecondary5up,
    },
  }),
);

interface IProps {
  itemImages: string[] | undefined,
  altName: string | undefined,
  isUpdate: boolean,
  onChange: () => void,
  sendImages: (images: string[]) => void,
}

interface Main {
  image: string,
  index: number,
}

export default function Images({itemImages, altName, isUpdate, onChange, sendImages}: IProps){ 
  const classes = useStyles();

  const [imageView, setImageView] = React.useState<string[] | undefined>(itemImages);
  const [mainImage, setMainImage] = React.useState<Main>(); 
  const [isMainSet, setIsMainSet] = React.useState<boolean>(false);
  const [hover, setHover] = React.useState<boolean>(false);

  React.useEffect(() => {
    if(!isMainSet && imageView && imageView.length > 0){
      setMainImage({
        image: imageView[0],
        index: 0,
      });
      setIsMainSet(true);
    }
  });

  // for parent component to have the current item images when user clicks save
  React.useEffect(() => {
    //@ts-ignore
    sendImages(imageView);
  }, [ imageView ]);

  const handleImageChange = (e: any) => {
    e.preventDefault();
    onChange();

    const imageTotal = imageView ? e.target.files.length + imageView.length : e.target.files.length;

    if(imageTotal > 5){
      alert('An item can only have a maximum of 5 images');
    }
    else{
      const newImages: string[] = [];
      const promises: any[] = [];

      const compressOptions = {
        targetSize: 0.2,
        quality: 0.75,
        maxWidth: 800,
        maxHeight: 600,
      };

      const compress = new Compress(compressOptions);
      compress.compress([ ...e.target.files ])
        .then((conversions: any) => {

          for(let i = 0; i < conversions.length; i++){
            const promise = new Promise((res) => {

              Compress.blobToBase64(conversions[i].photo.data)
                .then((base64: string) => {
                  newImages.push(base64.split('base64,')[1]);
                  res(true);
                });
            });
            promises.push(promise);
          }
          Promise.all(promises)
          .then(res => {
            if(imageView) setImageView([...imageView, ...newImages]);
            else setImageView(newImages);
          });
        })
        .catch((err: any) => {
          console.log('err: ', err);
        });
    }
  };

  const removeImage = () => {
    if(mainImage){
      onChange();
      //@ts-ignore
      setImageView(imageView.filter(el => el !== mainImage.image));
      setIsMainSet(false);
    }
  };

  const labelStyle = (): React.CSSProperties => {
    if (hover) {
      return {backgroundColor: colors.navBarSecondary5up, cursor: 'default'};
    } else {
      return {backgroundColor: colors.navBarSecondary5up, cursor: 'pointer'};
    }
  };

  const mainRef = React.createRef<HTMLDivElement>();
  const [dimensions, setDimensions] = useState({ height: 0 });



  //TODO: I have it set to updating whenever the offsetWidth changes - Why does it never update?
  //      also, the height does not equal the width and idk why
  React.useEffect(() => {
    if (mainRef.current) {
      setDimensions({
        height: mainRef.current.offsetWidth,
      });
    }
  }, [ mainRef.current?.offsetWidth ]); //mainRef.current?.offsetWidth



  return (
    <>
      {/* Upload / Delete Item Images */}
      {isUpdate && 
        <Box className={classes.imageChangeBar}>

          <label className={classes.button} style={labelStyle()} onMouseEnter={() => setHover(!hover)} onMouseLeave={() => setHover(!hover)}>
            Add Images
            <input type="file"
              accept="image/png, image/jpeg"
              onChange={handleImageChange}
              multiple
              style={{visibility: 'hidden'}}
            />
          </label>
          
          <label className={classes.button} style={labelStyle()} onClick={() => removeImage()}>Remove Image</label>
        </Box>
      }

     {/* Image View */}
      <Box> 

        <div ref={mainRef} className={imageView && imageView.length > 0 ? classes.MainGrid : classes.MainGridNoImage} style={{height: dimensions.height }}>
          {isMainSet && mainImage &&
            <img className={classes.MainImage} src={`data:image/png;base64,${mainImage.image}`} alt={altName}/>
          }
        </div>

        <Grid container className={classes.ThumbnailsContainer}>
          {imageView && imageView.length > 1 &&
            imageView.map((el, index) => {
              return(
                <Grid
                  key={index}
                  className={mainImage?.index == index ? classes.MainThumbnailGrid : classes.ThumbnailGrid}
                  onClick={() => {
                    setMainImage({ image: el, index: index });

                  }}
                >
                  <img className={classes.ThumbnailImage} src={`data:image/png;base64,${el}`} alt={altName}/> 
                </Grid>
              );
            })
          }
        </Grid>
      </Box>
    </>
  );
}
