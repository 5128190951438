import React, { useContext } from 'react';
import { Typography,
  List, ListItem, makeStyles, 
  ListItemText, ListSubheader, CircularProgress
} from '@material-ui/core';
import { IItem } from '../../Interfaces/Database/Item';
import { IBid } from '../../Interfaces/Database/Bid';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { RootContext } from '../../Store/RootStore';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: '#F6F6F6',
    paddingTop: '5px',
    '&.MuiListSubheader-gutters':{
      paddingLeft: 0
    }
  },  
}));
interface IProps {
  itemID: string | undefined,
  currentItem: IItem | null
}

interface IBidMap {
  [bidID: string]: IBid
}

export default function BidsList({ itemID, currentItem }: IProps) {
  const styles = useStyles();
  const store = useContext(RootContext);
  const { client, user, authToken, socket } = store;

  const [bids, setBids] = React.useState<IBidMap>({});
  const [skippedBids, setSkippedBids] = React.useState<number>(0);
  const [getMoreBidsLoading, setGetMoreBidsLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    scrollToBottom();
  }, [ bids ]);

  React.useEffect(() => {
    getBids();
    if(itemID && socket){
      getBids();
      socket
      .on('bids created', (bid: IBid) => {
        if(bid.itemID === itemID){
          setBids(prevState => ({...prevState, [bid._id]: bid}));
        }
      })
    }
  },[itemID]);

  const getBids = async () => {
    if(itemID && user){
      client?.service('bids').find({
        headers: { Authorization: authToken },
        user: user,
        query: {
          itemID,
          $limit: 0
        },
      })
      .then((res: any) => {
        let skip = 0;
        if(res.total > 25){
          skip = res.total - 25;
          setSkippedBids(skip);
        }
        return client?.service('bids').find({
          headers: { Authorization: authToken },
          user: user,
          query: {
            itemID,
            $limit: res.total,
            $skip: skip,
            $sort: { createdAt: 1 }
          },
        })
      })
      .then((res: any) => res.data)
      .then((bids: IBid[]) => {
        setBids(bids.reduce((acc, bid) => (
          { ...acc, [bid._id]: bid }
        ), {}));
      })
    }
  }

  const getMoreBids = () => {
    setGetMoreBidsLoading(true);
    const pageSize = 10;
    let skip = 0;
    let limit = skippedBids;
    if(skippedBids > pageSize){
      skip = skippedBids - pageSize;
      limit = pageSize;
    }
    client?.service('bids').find({
      headers: { Authorization: authToken },
      user: user,
      query: {
        itemID,
        $skip: skip,
        $limit: limit,
        $sort: { createdAt: 1 }
      },
    })
      .then((res: any) => res.data)
      .then((bids: IBid[]) => {
        setBids(prevState => ({
          ...bids.reduce((acc, bid) => (
            { ...acc, [bid._id]: bid }
          ), {}),
          ...prevState
        }) );
        setSkippedBids(skip);
      })
      .finally(() => setGetMoreBidsLoading(false));
  }

 const bidScrollRef = React.createRef<HTMLInputElement>();

 const scrollToBottom = () => {
   bidScrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
 }

  return (
    <div ref={bidScrollRef} style={{ height: '80%', width: '100%', overflow: 'scroll', display: 'flex', flexDirection: 'column-reverse'  }}>
      <List dense>
        <ListSubheader style={{ backgroundColor: '#F6F6F6' }} className={styles.header}>
          <ListItem>
            <ListItemText 
             style={{ minWidth: '40px', marginRight: '20px' }}
              key={`bidder-number-header`} 
              primary={'Bidder'}
            />
            <ListItemText
              style={{ minWidth: '40px', marginRight: '20px' }}
              key={`bid-price-header`} 
              primary={`Price`} 
            />
            <ListItemText
              // style={{ width: '150px', display: 'inline' }}
              key={`date-time-header`} 
              primary={`Timestamp`} 
            />
          </ListItem>
        </ListSubheader>
        {skippedBids > 0 &&
          <ListItem
            key={'load-all-bids'}
            style={{ backgroundColor: '#FDFFD9' }}
            onClick={getMoreBids}
            disabled={getMoreBidsLoading}
          >
            <ListItemText 
              style={{ textAlign: 'center' }}
              key={'load-all-bids-text'} 
              primary={
                getMoreBidsLoading
                  ? <CircularProgress size={'1.5rem'}/>
                  : <>
                      <Typography display='inline' style={{verticalAlign: 'middle', marginRight: '10px'}}>Load more</Typography> 
                      <ArrowUpwardIcon display='inline' style={{verticalAlign: 'middle'}}/>
                    </>
              }
            />
          </ListItem>
        }
        {Object.values(bids)
          .sort((a, b) => new Date((a as any).createdAt).getTime() - new Date((b as any).createdAt).getTime())
          .map((bid: IBid) => (
          <ListItem
            key={bid._id}
            style={currentItem?.highestBidID === bid._id ? { backgroundColor: '#FDFF7A' } : {}}
          >
            <ListItemText 
              style={{ minWidth: '40px', marginRight: '20px' }}
              key={`bidder-number-${bid._id}`} 
              primary={`# ${bid.bidderNumber}`}
            />
            <ListItemText
              style={{ minWidth: '40px', marginRight: '20px' }}
              key={`bid-price-${bid._id}`} 
              primary={`$ ${bid.price}`} 
            />
            <ListItemText
              style={bid.isCreatedLive ? { color: 'black',  } : { color: 'red'}}
              key={`date-time-${bid._id}`} 
              primary={`${new Date(bid.initalTimeSet).toLocaleString()} ${!bid.isCreatedLive ? '(online)' : ''}`} 
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
