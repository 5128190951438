import React, { createContext } from 'react';
import { IUser } from '../Interfaces/Database/User';
import { Application } from '@feathersjs/feathers';
import { AuctionStore, BidStore, ItemStore, BidHistoryStore, ISlideShowData, IPageData } from './Reducers/AuctionReducer';
import { IAuction } from '../Interfaces/Database/Auction';
import { IItem } from '../Interfaces/Database/Item';
import { IBid } from '../Interfaces/Database/Bid';
import { IPurchase } from '../Interfaces/Database/Purchase';
import { updatePurchaseData } from './Actions/Report';
import { AuctionReportStore, NextBidderStore, ReportAction } from './Reducers/ReportReducer';


/**
 * Root Store
 *  Shared State on the Root Element to
 *  all Children
 */
export interface IRootStore {
  user: IUser | null,

  // Feathers Config
  client: Application | null,
  socket: SocketIOClient.Socket | null,
  authToken: string | null,

  // Stores
  auctionStore: AuctionStore,
  bidHistoryStore: BidHistoryStore,
  itemStore: ItemStore,
  auctionReportStore: AuctionReportStore,
  nextBidderStore: NextBidderStore,
  slideshowItems: ISlideShowData[],
  
  // Actions
  actions: {
    setUser: (user: IUser | null) => void,
    setAuthToken: (token: string) => void,
    setSlideshowItems: () => void,

    // Auction
    queryAllAuctions: () => Promise<IAuction[]>,
    addAuction: (auction: Partial<IAuction>) => Promise<IAuction>,
    updateAuction: (id: string, auction: Partial<IAuction>) => Promise<IAuction>,
    remAuction: (auctionID: string) => Promise<IAuction>,
    queryItemsNextPage: (auctionID: string, currentPage: number, itemsPerPage: number, totalItems: number) => Promise<IPageData>,
    
    // Item
    remItem: (auctionID: string, itemID: string) => Promise<IItem>,
    addItem: (auctionID: string, item: IItem) => Promise<IItem>,
    updateItem: (auctionID: string, itemID: string, item: Partial<IItem>) => Promise<IItem>,
    queryItemBids: (itemID: string) => Promise<BidStore[]>,
    addItemToStore:  (auctionID: string, item: IItem) => void,
    retrieveItem:  (itemID: string) => Promise<IItem>,

    // Bid
    addBid: (bid: IBid) => Promise<IBid>,
    queryBidHistory: (itemID: string, first: boolean) => Promise<BidStore[]>, //IBidWithUser

    //Purchase
    addPurchase: (purchase: Partial<IPurchase>) => Promise<any>,

    //Report
    queryAuctionReport: (auctionID: string) => Promise<any>,
    updateDeliveryData: (data: ReportAction) => Promise<any>,
    queryItemBidders: (itemID: string) => Promise<any>,
    updatePurchase: (data: updatePurchaseData) => Promise<any>,
  }
}


// Initial State
export const RootContext = createContext<IRootStore>({
  // User Data
  user: null,

  // Feathers Connection
  client: null,
  socket: null,
  authToken: null,

  // Stores
  auctionStore: {},
  bidHistoryStore: {},
  itemStore: {},
  auctionReportStore: {},
  nextBidderStore: {},
  slideshowItems: [],

  // Actions
  actions: {
    setUser: () => null,
    setAuthToken: () => null,
    setSlideshowItems: () => null,

    // Auction
    queryAllAuctions: (() => null) as any,
    addAuction: (() => null) as any,
    updateAuction: (() => null) as any,
    remAuction: (() => null) as any,

    // Item
    queryItemsNextPage: (() => null) as any,
    remItem: (() => null) as any,
    addItem: (() => null) as any,
    updateItem: (() => null) as any,
    addItemToStore: (() => null) as any,
    retrieveItem: (() => null) as any,

    // Bid
    queryItemBids: (() => null) as any,
    addBid: (() => null) as any,
    queryBidHistory: (() => null) as any,

    //Purchase
    addPurchase: (() => null) as any,

    //Report
    queryAuctionReport: (() => null) as any,
    updateDeliveryData: (() => null) as any,
    queryItemBidders: (() => null) as any,
    updatePurchase: (() => null) as any,
  },
});


// HOC: Wraps Context around a Component
export function withAppContext<T>(AppContext: React.Context<T>, Component: React.ComponentType<any>) {
  return function context(props: any) {
    return React.createElement(
      AppContext.Consumer, null,
      (state: T) => React.createElement(Component, { ...props, context: state }),
    );
  };
}
