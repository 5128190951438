import React from 'react';
import { RootContext } from '../../Store/RootStore';
import {
  CircularProgress, Paper,
  Table, TableContainer, TableRow,
  TableHead, TableCell, TableBody,
  makeStyles,
  Dialog, DialogTitle,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { BidStore } from '../../Store/Reducers/AuctionReducer';


// Scoped Styling
const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    minWidth: 350,
    minHeight: 80,
    maxHeight: '50vh',
  },
  table: {
  },
  tableTitle: {
    padding: '15px 20px',
  },
});


interface Props {
  itemID: string,
  isOpen: boolean,
  onClose: () => void,
}

/**
 * Handles Displaying Full Item Details
 *  to the User
 */
export default function BidDetail({ itemID, onClose, isOpen }: Props): JSX.Element {
  // Hooks
  const styles = useStyles();
  const store = React.useContext(RootContext);

  // States
  const [bids, setBids] = React.useState<BidStore[] | null>(null);
  const [number, setNumber] = React.useState<number>(0);
  const [firstCall, setFirstCall] = React.useState<boolean>(true);

  
  // Obtain Item Details
  React.useEffect(() => {    
    if ( !store.client )
      return;

    store.actions.queryBidHistory(itemID, firstCall)
      .then(res => {
        setFirstCall(false);
        setNumber(res.length);
        setBids(res.sort((a, b) => new Date((a.bid as any).createdAt).getTime() - new Date((b.bid as any).createdAt).getTime()));
      })
      .catch(err => console.log(err));
  }, [itemID, store.bidHistoryStore, store.client]);

  
  return (
    <Dialog onClose={onClose} open={isOpen}>
       <DialogTitle id="form-dialog-login" style={{textAlign: 'center'}}>
        <CloseIcon style={{float: 'right'}} onClick={onClose} />
        Bid Details &nbsp;&nbsp; Total: {number}
      </DialogTitle>


      <div className={styles.root}>
        {bids === null ? <CircularProgress /> : (
          <TableContainer component={Paper}>
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Bidder #</TableCell>
                  <TableCell>Bid Price</TableCell>
                  <TableCell align="right">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bids
                  .map(( bid, index ) => (
                    <TableRow key={bid.bid._id}>
                      <TableCell component="th" scope="row" align='center'>
                        {bid.number}
                      </TableCell>
                      <TableCell component="th" scope="row" align='center'>
                        ${bid.bid.price}
                      </TableCell>
                      <TableCell align="right">
                        {new Date(bid.bid.initalTimeSet).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </Dialog>
  );
};
