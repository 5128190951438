import React from 'react';
import {
  Table, TableBody, TableCell, 
  TableHead, TableRow,
  Typography, Box, Collapse,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@material-ui/icons';
import { Bundle } from '../Interfaces/Database/PurchaseReport';

const useStyles = makeStyles({
  totals: {
    // width: '300px',
    // float: 'right'
  },
  container: {
    // maxHeight: 440,
  },
  small: {
    width: '10%',
  },
  large: {
    width: '40%',
  },
});


interface Props {
  data: Bundle,
  children?: JSX.Element[],
}

export default function PaymentReportDetails ({ data, children }: Props): JSX.Element {
  const classes = useStyles();

  // States
  const [expandOpen, setExpandOpen] = React.useState<boolean>(true);

  return (<>
    <TableRow hover style={{backgroundColor: '#f2f2f2'}}>
      <TableCell>
        <IconButton aria-label="expand row" size="small" onClick={() => setExpandOpen(!expandOpen)}>
          {expandOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      </TableCell>
      {children?.map(elt => elt)}
    </TableRow>

    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={expandOpen} timeout="auto" unmountOnExit>
          <Table size="small" aria-label="purchases">
            <TableHead>
              <TableRow>
                <TableCell className={classes.small}><strong>Delivery Type</strong></TableCell>
                <TableCell className={classes.large}><strong>Shipping Address</strong></TableCell>
                <TableCell className={classes.large}><strong>Items</strong><strong style={{float: 'right'}}>Item Costs</strong></TableCell>
                <TableCell className={classes.small}><strong>Shipping Cost</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.purchaseBundles.map((elt, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.small}>{elt.type}</TableCell>
                  <TableCell className={classes.large}>{elt.address ? elt.address : '-'}</TableCell>
                  {/* Item Details */}
                  <TableCell className={classes.large}>
                    {elt.items.map(item => (
                      <TableRow key={item.item._id} style={{width: '100%'}}>
                        <TableCell style={{width: '100%'}}>#{item.item.itemNumber} {item.item.title}</TableCell>
                        <TableCell style={{width: '20%'}}>${item.purchase.itemCost}</TableCell>
                      </TableRow>
                    ))}
                  </TableCell>
                  <TableCell className={classes.small}>{elt.shippingCost ? '$' + elt.shippingCost : '-'}</TableCell>
                </TableRow>
              ))}
              <TableRow style={{height: 40}}></TableRow>
              <TableRow className={classes.totals}>
                <TableCell style={{borderBottom: 'none'}} colSpan={2}/>
                <TableCell><strong>Subtotal</strong></TableCell>
                <TableCell>${data.totalCost.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow className={classes.totals}>
                <TableCell style={{borderBottom: 'none'}} colSpan={2}/>
                <TableCell><strong>Service Charge</strong> (3%)</TableCell>
                <TableCell>{data.serviceCost ? '$' + data.serviceCost.toFixed(2) : '-'}</TableCell>
              </TableRow>
              <TableRow className={classes.totals}>
                <TableCell style={{borderBottom: 'none'}} colSpan={2}/>
                <TableCell><strong>Total</strong></TableCell>
                <TableCell><strong>${data.serviceCost ? (data.totalCost + data.serviceCost).toFixed(2) : data.totalCost.toFixed(2)}</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  </>);
}