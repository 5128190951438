export const SHIPPING_TYPE = ['No Shipping', 'Standard', 'Overnight'];
export type ShippingType = 'No Shipping' | 'Standard' | 'Overnight';
export type SoldType = 'buyNow' | 'bid';

export interface IItem {
  _id:            string,

  auctionID:      string,

  categoryID:     string,
  category:       string,

  highestBidID:   string | null,
  highestBidPrice?: number,
  highestBidBidder?: number,

  previous:       string | null,
  next:           string | null,

  title:          string,
  description:    string,
  pictures:       Array<string>,
  itemNumber:     number,
  startingBid:    number,
  isBuyNow:       boolean,
  buyNowPrice?:   number,
  bidIncrement:   number,

  isSold:         boolean,
  soldType?:      SoldType,
  purchasePrice?: number,
  purchaseBidder?:number,
  
  shippingType:   ShippingType, 
  shippingCost:   number,   // -1 if shipping type is 'No Shipping'
  
  isClosed:       boolean,  // If Shipped or Picked Up
}